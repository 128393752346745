import { Row } from "react-bootstrap";
import { IDailySubscriptionsData } from "../../../../models";
import { getDailyStartedSubscriptions } from "../../../../services";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const DailyStartedDataTable = () => {
  const [dailyList, setDailyList] = useState<IDailySubscriptionsData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(true);

  useEffect(() => {
    try {
      getDailyStartedSubscriptions().then((response) => {
        console.log(
          "getDailyStartedSubscriptions.response.data:",
          response.data
        );
        if (response && response.data) {
          setDailyList(response.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, [refreshList]);

  const triggerRefresh = () => {
    setRefreshList(!refreshList);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Started Subscriptions</span>
      <Button icon="pi pi-refresh" onClick={triggerRefresh} />
    </div>
  );
  const footer = `In total there are ${
    dailyList ? dailyList.length : 0
  } record.`;

  return (
    <>
      <Row>
        <DataTable
          value={dailyList}
          tableStyle={{ width: "100%" }}
          header={header}
          footer={footer}
        >
          <Column
            field="subId"
            header="Id"
            body={(rowData) => <>{rowData?.subId}</>}
            sortable
          />
          <Column
            field="cstName"
            header="Name"
            body={(rowData) => <>{rowData?.cstName}</>}
            sortable
          />
          <Column
            field="type"
            header="Cst Type"
            body={(rowData) => <>{rowData?.type}</>}
            sortable
          />
          <Column
            field="formattedDate"
            header="Date"
            body={(rowData) => <>{rowData?.formattedDate}</>}
            sortable
          />
          <Column
            field="newStatus"
            header="New Status"
            body={(rowData) => <>{rowData?.newStatus}</>}
            sortable
          />
          <Column
            field="proName"
            header="Product"
            body={(rowData) => <>{rowData?.proName}</>}
            sortable
          />
          <Column
            field="subPrice"
            header="Price"
            body={(rowData) => <>{rowData?.subPrice}</>}
            sortable
          />
        </DataTable>
      </Row>
    </>
  );
};

export default DailyStartedDataTable;
