import { QueryFunctionContext } from "react-query";
import { axiosInstance } from "../../api/axios.instance";
import {
  IContentGroupOp,
  IContentGroup,
  IProductGroup,
  IVideoContent,
  IContentGroupOc,
} from "../../models/index";

export const getContentGroups = async (): Promise<IContentGroup[]> =>
  await axiosInstance
    .get(`/contman/contentgroup/getall`)
    .then((res) => res.data.data);

export const getProductGroups = async ({
  queryKey,
}: QueryFunctionContext<[string, string | undefined]>): Promise<
  IProductGroup[]
> => {
  const [_, id] = queryKey;
  return await axiosInstance
    .get(`/crm-product/product/getproductgroups/${id}`)
    .then((res) => res.data.data);
};

export const addContentGroup = async (contentGroup: IContentGroupOp) =>
  await axiosInstance.post(
    `/crm-product/product/addcontentgtoproduct`,
    contentGroup
  );

export const deleteContentGroup = async (contentGroup: IContentGroupOp) =>
  await axiosInstance.post(
    `/crm-product/product/removecontentgfromproduct`,
    contentGroup
  );
export const addContentGroupToContent = async (
  contentData: IContentGroupOc
) => {
  await axiosInstance.post(
    `/contman/content/addcontentgrouptocontent`,
    contentData
  );
};

export const removeContentGroupFromContent = async (
  contentData: IVideoContent
) =>
  await axiosInstance.post(
    `/contman/content/removecontentgroupfromcontent`,
    contentData
  );
