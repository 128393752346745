import { ILabelDefinition } from "../../../../models";
import { getLabels, labelPriorityUpdate } from "../../../../services/index";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import {
  DataTableCrud,
  DataTableSearchHeader,
  DataTableStatusTemplate,
} from "../../common";
import { initialLabel, labelFilters } from "../../../../constants";
import { Column } from "primereact/column";
import {
  AddRemoveConentsInLabelDialog,
  EditLabelDialog,
  LabelDialog,
  LabelsConentListDialog,
} from "../../../Dialog/label";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { toastError, toastSuccess } from "../../../../utils";

const dragHandleContainerStyle = {
  display: "inline-block",
  padding: "5px 10px",
  marginRight: "10px",
  cursor: "move",
};

const dragHandleLineStyle = {
  width: "20px",
  height: "3px",
  backgroundColor: "#000",
  margin: "2px 0",
};

const LabelDataTable = () => {
  const toast = useRef<Toast>(null);
  const [triggerRefresh, setTriggerRefresh] = useState(true);
  const [labelsLoading, setLabelsLoading] = useState(true);
  const [showAddLabelDialog, setShowAddLabelDialog] = useState(false);
  const [showEditLabelDialog, setShowEditLabelDialog] = useState(false);
  const [showLabelsContentDialog, setShowLabelsContentDialog] = useState(false);

  const [labels, setLabels] = useState<ILabelDefinition[]>([]);

  const [selectedLabel, setSelectedLabel] =
    useState<ILabelDefinition>(initialLabel);

  const [filters, setFilters] = useState(labelFilters);

  const [showAddRemoveContentDialog, setShowAddRemoveContentDialog] =
    useState(false);

  useEffect(() => {
    try {
      getLabels().then((response) => {
        //console.log("getLabels.response:", response);
        if (response && response.length > 0) {
          setLabels(response);
          setLabelsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, [triggerRefresh, showEditLabelDialog, showAddLabelDialog]);

  const onDragStart = (event: React.DragEvent, labelItem: ILabelDefinition) => {
    event.dataTransfer.setData("text/plain", labelItem.id!.toString());
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = async (
    event: React.DragEvent,
    dropTargetItem: ILabelDefinition
  ) => {
    event.preventDefault(); // Önceki koddan eksikti, önemli bir düzeltme
    const draggedItemId = event.dataTransfer.getData("text/plain");
    const draggedItem = labels.find(
      (item) => item.id!.toString() === draggedItemId
    );

    if (draggedItem && dropTargetItem) {
      const dropTargetIndex = labels.findIndex(
        (item) => item.id === dropTargetItem.id
      );
      const draggedItemIndex = labels.findIndex(
        (item) => item.id === draggedItem.id
      );
      if (dropTargetIndex !== draggedItemIndex) {
        const newLabels = [...labels];
        newLabels.splice(draggedItemIndex, 1);
        newLabels.splice(dropTargetIndex, 0, draggedItem);
        setLabels(newLabels);

        // Call the update service with reordered list
        //await updateMainBoardItem(newMainboardItems);
        try {
          labelPriorityUpdate(newLabels).then((response) => {
            console.log("labelPriorityUpdate.response:", response);
            if (response) {
            }
          });
        } catch (error) {
          console.log("Error:", error);
        }
      }
    }
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const openAddLabelDialog = () => {
    setShowAddLabelDialog(true);
  };

  const hideAddLabelDialog = () => {
    setShowAddLabelDialog(false);
  };

  const openEditLabelDialog = (rowData?: ILabelDefinition) => {
    rowData && setSelectedLabel(rowData);
    console.log("rowData:", rowData);
    setShowEditLabelDialog(true);

    /*
    deleteMainBoardItem(rowData?.id).then((res) => {
      //console.log("delete res", res);
      if (res.status === 200) {
        if (res.data.code === 200) {
          toastSuccess(toast, "MBI Removed." + rowData?.title);
          refetchMbiList();
          setUpdateTrigger(!updateTrigger);
          //setUpdateTrigger((prev) => !prev);
        } else {
          console.log("delete res.data.data", res.data.data);
          toastError(toast, "MBI Delete Failed." + res.data.message);
        }
      } else {
        toastError(toast, "MBI Delete Failed. Status:" + res.data.status);
      }
    });
    */
    //setSelectedLabel(initialLabel);
  };

  const openLabelsContentlDialog = (rowData?: ILabelDefinition) => {
    rowData && setSelectedLabel(rowData);
    setShowLabelsContentDialog(true);
  };

  const openAddRemoveContentlDialog = (rowData?: ILabelDefinition) => {
    rowData && setSelectedLabel(rowData);
    setShowAddRemoveContentDialog(true);
  };

  const hideEditLabelDialog = () => {
    setShowEditLabelDialog(false);
  };

  const hideLabelsContentDialog = () => {
    setShowLabelsContentDialog(false);
  };

  const hideAddRemoveContentDialog = () => {
    setShowAddRemoveContentDialog(false);
  };

  const updateRefreshTrigger = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  const showFail = () => {
    toastError(toast, "Operatin failed.");
  };

  return (
    <>
      <Toast ref={toast} />
      <Row style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button
          label={"New"}
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openAddLabelDialog}
        />
      </Row>
      <Row>
        <DataTableCrud
          value={labels}
          selection={selectedLabel}
          onSelectionChange={(e) => setSelectedLabel(e.value)}
          header={
            <DataTableSearchHeader
              title="Label List"
              filters={filters}
              onChange={(e: any) => setFilters(e)}
            />
          }
          filters={filters}
          loading={labelsLoading}
          title="labels"
        >
          <Column
            header="Order"
            body={(rowData: ILabelDefinition) => (
              <div
                draggable
                onDragStart={(e) => onDragStart(e, rowData)}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, rowData)}
              >
                <div style={dragHandleContainerStyle}>
                  <div style={dragHandleLineStyle}></div>
                  <div style={dragHandleLineStyle}></div>
                  <div style={dragHandleLineStyle}></div>
                </div>
              </div>
            )}
          />

          <Column
            field="name"
            header="Name"
            body={(rowData) => <>{rowData.name}</>}
            sortable
          />
          <Column
            field="type"
            header="Type"
            body={(rowData) => <>{rowData.type}</>}
            sortable
          />
          <Column
            field="viewType"
            header="ViewType"
            body={(rowData) => <>{rowData.viewType}</>}
            sortable
          />
          <Column
            field="homeScreenVisibility"
            header="HomeScreen"
            body={(rowData) => (
              <>
                {rowData.homeScreenVisibility ? (
                  <Badge value="OK" severity="success" />
                ) : (
                  <Badge value="NOK" severity="danger" />
                )}
              </>
            )}
            sortable
          />

          <Column
            header="Action"
            body={(rowData: ILabelDefinition) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-secondary m-1"
                  onClick={() => openEditLabelDialog(rowData)}
                ></Button>
                <Button
                  icon="pi pi-list"
                  className="p-button-rounded p-button-info m-1"
                  onClick={() => openLabelsContentlDialog(rowData)}
                ></Button>
                {rowData.type === "DYNAMIC" ? (
                  <Button
                    icon="pi pi-sliders-v"
                    className="p-button-rounded p-button-info m-1"
                    style={{ backgroundColor: "#ae86b8" }}
                    onClick={() => openAddRemoveContentlDialog(rowData)}
                  ></Button>
                ) : (
                  ""
                )}
              </>
            )}
          />
        </DataTableCrud>
      </Row>
      <LabelDialog
        visible={showAddLabelDialog}
        onHide={hideAddLabelDialog}
        refreshTable={updateRefreshTrigger}
      />
      <EditLabelDialog
        editLabel={selectedLabel}
        visible={showEditLabelDialog}
        onHide={hideEditLabelDialog}
        refreshTable={updateRefreshTrigger}
        onSuccess={showSuccess}
        onFail={showFail}
      />
      <LabelsConentListDialog
        selectedLabel={selectedLabel}
        visible={showLabelsContentDialog}
        onHide={hideLabelsContentDialog}
        refreshTable={updateRefreshTrigger}
        onSuccess={showSuccess}
        onFail={showFail}
      />
      <AddRemoveConentsInLabelDialog
        selectedLabel={selectedLabel}
        visible={showAddRemoveContentDialog}
        onHide={hideAddRemoveContentDialog}
        onSuccess={showSuccess}
        onFail={showFail}
      />
    </>
  );
};

export default LabelDataTable;
