import { useState } from "react";
import { IComponentProps } from "../models/index";
import ThemeContext from "../contexts/ThemeContext";
import { CONFIG_VARS } from "../constants/config";

interface ThemeProviderProps extends IComponentProps {}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
 const [layoutColorMode, setLayoutColorMode] = useState<string>(CONFIG_VARS.LIGHT);
 return (
  <ThemeContext.Provider value={{ layoutColorMode, setLayoutColorMode }}>
   {children}
  </ThemeContext.Provider>
 );
};
