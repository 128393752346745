import { getSubscriptionInfo } from "../../../services";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Badge } from "primereact/badge";
import ISubscription from "src/models/Entities/customer/ISubscription";

interface Props {
  subscriptionId: number | null | undefined;
  merchantOid: string | undefined;
  visible: boolean;
  onHide: () => void;
  // Bileşenin özelliklerini burada tanımlayabilirsiniz
}

const SubscriptionInfoDialog: React.FC<Props> = ({
  subscriptionId,
  merchantOid,
  visible,
  onHide,
}) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<ISubscription>();
  //const [displayPosition, setDisplayPosition] = useState(false);
  //const [position, setPosition] = useState<any>("center");

  useEffect(() => {
    //console.log("subscriptionId:", subscriptionId);
    if (subscriptionId !== undefined) {
      getSubscriptionInfo(subscriptionId)
        .then((response) => {
          console.log("getSubscriptionInfo:", response);
          setSubscriptionInfo(response);
        })
        .catch((err) => {
          console.log("Error Text:", err);
        });
    } else {
      console.log("No subscription id provided.");
    }
  }, [subscriptionId, visible]);

  const hidingDialog = () => {
    setSubscriptionInfo(undefined);
    onHide();
  };

  return (
    <>
      <Dialog
        header={"Subscription Details - " + merchantOid}
        visible={visible}
        position={"center"}
        modal
        style={{ width: "40vw" }}
        onHide={hidingDialog}
        draggable={false}
        resizable={false}
      >
        <div className="card" style={{ margin: "10px", padding: "0px" }}>
          <div className="">
            {subscriptionInfo ? (
              <Table
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "6px",
                  width: "100%",
                }}
              >
                <tr>
                  <th
                    colSpan={2}
                    style={{
                      textAlign: "start",
                      padding: "6px",
                      backgroundColor: "#dddee0",
                    }}
                  >
                    Customer Info
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>Name</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.customer.name}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>Email</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.customer.email}
                  </td>
                </tr>

                <tr>
                  <th
                    colSpan={2}
                    style={{
                      textAlign: "start",
                      padding: "6px",
                      backgroundColor: "#dddee0",
                    }}
                  >
                    Subscription Info
                  </th>
                </tr>
                <tr>
                  <th
                    style={{ width: "30%", textAlign: "start", padding: "6px" }}
                  >
                    Subscription ID
                  </th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.id}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>Price</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.price}
                  </td>
                </tr>
                {subscriptionInfo?.startDate && (
                  <tr>
                    <th style={{ textAlign: "start", padding: "6px" }}>
                      Start Date
                    </th>
                    <td style={{ textAlign: "start", padding: "6px" }}>
                      {moment(subscriptionInfo?.startDate).format(
                        "DD/MM/YYYY, h:mm a"
                      )}
                    </td>
                  </tr>
                )}

                {subscriptionInfo?.endDate && (
                  <tr>
                    <th style={{ textAlign: "start", padding: "6px" }}>
                      End Date
                    </th>
                    <td style={{ textAlign: "start", padding: "6px" }}>
                      {moment(subscriptionInfo?.endDate).format(
                        "DD/MM/YYYY, h:mm a"
                      )}
                    </td>
                  </tr>
                )}
                {subscriptionInfo?.renewalDate && (
                  <tr>
                    <th style={{ textAlign: "start", padding: "6px" }}>
                      Renewal Date
                    </th>
                    <td style={{ textAlign: "start", padding: "6px" }}>
                      {moment(subscriptionInfo?.renewalDate).format(
                        "DD/MM/YYYY, h:mm a"
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>Status</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.status == "ACTIVE" ? (
                      <Badge value="ACTIVE" severity="success" />
                    ) : (
                      <Badge
                        value={subscriptionInfo?.status}
                        severity="warning"
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <th
                    colSpan={2}
                    style={{
                      textAlign: "start",
                      padding: "6px",
                      backgroundColor: "#dddee0",
                    }}
                  >
                    Product Info
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>ID</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.productP.id}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>Name</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.productP.name}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start", padding: "6px" }}>Price</th>
                  <td style={{ textAlign: "start", padding: "6px" }}>
                    {subscriptionInfo?.productP.price}
                  </td>
                </tr>
              </Table>
            ) : (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default SubscriptionInfoDialog;
