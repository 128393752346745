import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { DataTableDialogFooter } from "../../common";
import { Toast } from "primereact/toast";
import { ICategory } from "@/models/Entities/category";
import { IAllMainboardItems, IMainboardItems } from "@/models";
import { deleteMainBoardItem, getMbisOfCategory } from "../../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Row } from "react-bootstrap";
import { AddMBIDialog } from "../../mainboarditem";
import { toastError, toastSuccess } from "../../../../utils";

interface MbiDialogProps {
  visible: boolean;
  onHide: () => void;
  selectedCategory: ICategory;
}

const MbiDialog: React.FC<MbiDialogProps> = ({
  visible,
  onHide,
  selectedCategory,
}) => {
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMainboardItem, setSelectedMainboardItem] =
    useState<IAllMainboardItems | null>(null);

  const [mainboardItems, setMainboardItems] = useState<
    IAllMainboardItems[] | undefined
  >([]);
  const [showAddMbiDialog, setShowAddMbiDialog] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(true);

  useEffect(() => {
    getMbisOfCategory(selectedCategory?.id).then((response) => {
      //console.log("getMbisOfCategory.response:", response);
      if (response && response.length > 0) {
        setMainboardItems(response);
        //setlsLoading(false);
      }
    });
  }, [selectedCategory, triggerRefresh, visible]);

  function handleSave() {}

  function hideDialog() {
    setMainboardItems(undefined);
    onHide();
  }

  const openAddNewDialog = () => {
    setShowAddMbiDialog(true);
  };

  const hideAddMbiDialog = () => {
    setShowAddMbiDialog(false);
  };

  const refreshTable = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const openDeleteMainboardItemDialog = (rowData?: IAllMainboardItems) => {
    rowData && setSelectedMainboardItem(rowData);
    //setDeleteMainboardItemDialog(true);

    deleteMainBoardItem(rowData?.id).then((res) => {
      //console.log("delete res", res);
      if (res.status === 200) {
        if (res.data.code === 200) {
          toastSuccess(toast, "MBI Removed. " + rowData?.title);
          refreshTable();
          //setUpdateTrigger((prev) => !prev);
        } else {
          console.log("delete res.data.data", res.data.data);
          toastError(toast, "MBI Delete Failed." + res.data.message);
        }
      } else {
        toastError(toast, "MBI Delete Failed. Status:" + res.data.status);
      }
    });
    setSelectedMainboardItem(null);
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "40%", minHeight: "200px" }}
        header={"MBI Management for " + selectedCategory?.name || ""}
        modal
        className="p-fluid"
        footer={
          <>
            <Button
              label={"Close"}
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideDialog}
            />
          </>
        }
        onHide={hideDialog}
      >
        <Toast ref={toast} />
        <Row style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button
            label={"Add Mbi"}
            icon="pi pi-plus"
            className="p-button-success mr-2"
            style={{ width: "150px" }}
            onClick={openAddNewDialog}
          />
        </Row>
        <DataTable
          value={mainboardItems}
          selection={selectedMainboardItem}
          onSelectionChange={(e) => setSelectedMainboardItem(e.value)}
          title="mainboardItems"
        >
          <Column
            field="ID"
            header="id"
            body={(rowData) => <>{rowData.id}</>}
            sortable
          />
          <Column
            field="title"
            header="title"
            body={(rowData) => <>{rowData.title}</>}
            sortable
          />
          <Column
            header=""
            style={{ textAlign: "right" }}
            body={(rowData: IAllMainboardItems) => (
              <>
                <Button
                  icon="pi pi-times p-button-rounded"
                  className="p-button-outlined p-button-rounded p-button-danger m-1"
                  onClick={() => openDeleteMainboardItemDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
              </>
            )}
          />
        </DataTable>
      </Dialog>
      <AddMBIDialog
        visible={showAddMbiDialog}
        onHide={hideAddMbiDialog}
        refreshTable={refreshTable}
        categoryId={selectedCategory?.id}
      />
    </>
  );
};

export default MbiDialog;
