import { DashboardCard } from "../../components/index";
import CustomerAnalytics from "../../components/Dashboard/Analytics/CustomerAnalytics";
import SubscriptionAnalytics from "../../components/Dashboard/Analytics/SubscriptionAnalytics";
import FailedPaymentsTable from "../../components/Dashboard/Analytics/FailedPaymentAnalytics";
import IdleInvoicesTable from "../../components/Dashboard/Analytics/IdleInvoicesTable";
import FailedInvoicesTable from "../../components/Dashboard/Analytics/FailedInvoicesTable";
import InvoiceHistogram from "../../components/Dashboard/Analytics/InvoiceAnalytics";
import IncomeAnalytics from "../../components/Dashboard/Analytics/IncomeAnalytics";
import CustomerCountComponent from "../../components/Dashboard/Analytics/CustomerCount";
import DailyPayments from "../../components/Dashboard/Analytics/DailyPayments";

const Dashboard = () => {
  return (
    <div className="grid">
      <div
        className="col-12 xl:col-12"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <CustomerCountComponent
          status="ACTIVE"
          title="Active Customers Count"
          color="green"
        />
        <CustomerCountComponent
          status="INACTIVE"
          title="InActive Customer Count"
          color="blue"
        />
      </div>

      {/* <DashboardCard
        title="Orders"
        count="152"
        icon="pi-shopping-cart"
        iconTextColor="text-blue-500"
        iconBgColor="bg-blue-100"
        footerTitle="24 new "
        footerSubTitle="since last visit"
      /> */}
      {/* <DashboardCard
        title="Customers"
        count="28441"
        icon="pi-inbox"
        iconTextColor="text-cyan-500"
        iconBgColor="bg-blue-100"
        footerTitle="520 "
        footerSubTitle="newly registered"
      /> */}
      {/* <DashboardCard
        title="Comments"
        count="152 Unread"
        icon="pi-comment"
        iconTextColor="text-purple-500"
        iconBgColor="bg-purple-100"
        footerTitle="85 "
        footerSubTitle="responded"
      />  

      <div className="col-7 xl:col-6">
        <div className="card">
          <div className="card-grid">
            <CustomerAnalytics />
          </div>
        </div>
      </div>
      <div className="col-7 xl:col-6 ">
        <div className="card">
          <div className="card-grid">
            <SubscriptionAnalytics />
          </div>
        </div>
      </div>
      */}
      <div className="col-7 xl:col-6">
        <div className="card">
          <div className="card-grid">
            <IdleInvoicesTable />
          </div>
        </div>
      </div>

      <div className="col-7 xl:col-6">
        <div className="card">
          <div className="card-grid">
            <FailedInvoicesTable />
          </div>
        </div>
      </div>

      <div className="col-7 xl:col-6">
        <div className="card">
          <div className="card-grid">
            <FailedPaymentsTable />
          </div>
        </div>
      </div>

      <div className="col-7 xl:col-6">
        <div className="card">
          <div className="card-grid">
            <DailyPayments></DailyPayments>
          </div>
        </div>
      </div>

      <div className="lg:col-4">
        <div className="card">
          <div className="card-grid">
            <InvoiceHistogram />
          </div>
        </div>
      </div>

      <div className=" lg:col-8">
        <div className="card">
          <div className="card-grid">
            <IncomeAnalytics />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
