import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getFailedPayments, paymentExtendedInfo } from "../../../services";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import PaymentInfo from "../../../components/Dialog/payment/PaymentInfo";
import IPayment from "@/models/Entities/payments/IPayment";
import moment from "moment";

const FailedPaymentsTable = () => {
  const [failedPayments, setFailedPayments] = useState([]);
  const [paymentInfoShow, setPaymentInfoShow] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<IPayment>();

  interface FailedPayment {
    id: number;
    merchantOid: string;
    status: string;
    paymentDate: Date;
  }

  useEffect(() => {
    fetchFailedPayments();
  }, []);

  const fetchFailedPayments = async () => {
    try {
      const response = await getFailedPayments();
      setFailedPayments(response);
    } catch (error) {
      console.error("Failed to fetch failed payments:", error);
    }
  };

  const renderStatusColumn = (rowData: FailedPayment) => {
    return <Badge value={rowData.status} severity="danger" />;
  };

  const handleDetailsClick = (rowData: any) => {
    setPaymentInfoShow(true);
    setSelectedPayment(rowData);
    /* paymentExtendedInfo(16).then((res) => {
        console.log("payments:", res)
        
      })
      .catch((err) => console.log(err)); */
  };

  return (
    <div>
      <h4>Failed Payments</h4>
      <DataTable
        value={failedPayments}
        scrollable
        scrollHeight="400px"
        tableStyle={{ minWidth: "100%" }}
      >
        <Column field="id" header="ID" style={{ maxWidth: "3rem" }} />
        <Column
          field="merchantOid"
          header="Merchant OID"
          style={{ maxWidth: "14rem" }}
        />
        <Column
          header="Date"
          body={(rowData) =>
            rowData.paymentDate && (
              <>{moment(rowData.paymentDate).format("DD/MM/YYYY, h:mm a")}</>
            )
          }
        />
        <Column header="Status" body={renderStatusColumn} />
        <Column
          style={{ textAlign: "right" }}
          body={(rowData) => (
            <Button
              label="Details"
              className="p-button-secondary"
              onClick={() => handleDetailsClick(rowData)}
            />
          )}
        ></Column>
      </DataTable>
      {paymentInfoShow && (
        <PaymentInfo
          visible={paymentInfoShow}
          onHide={() => setPaymentInfoShow(false)}
          data={selectedPayment}
        />
      )}
    </div>
  );
};

export default FailedPaymentsTable;
