import { getProviders } from "../../services/index";
import { useQuery } from "react-query";

export const useGetProvidersData = () => {
  const { data: providers, isLoading: getProvidersIsLoading } = useQuery(
    "providers",
    getProviders
  );
  return { providers, getProvidersIsLoading };
};
