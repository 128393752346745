import { axiosInstance } from "../../api/axios.instance";
import { ILoginResponse, IUser } from "../../models/index";
import { getBaseUrl } from "../../utils/index";

const loginUser = (
  username: string,
  password: string
): Promise<ILoginResponse> =>
  axiosInstance
    .post(`/a2srv-operation/login`, { username, password })
    .then((res) => res.data);

const forceLoginUser = (
  username: string,
  password: string
): Promise<ILoginResponse> =>
  axiosInstance
    .post(`/a2srv-operation/forceLogin`, { username, password })
    .then((res) => res.data.data);

const logOutUser = (): Promise<any> =>
  axiosInstance.post(`/a2srv-operation/logout`).then((res) => res.data.data);

// const registerUser = (email: string, password: string): Promise<IUser> =>
//   axios
//     .post(`${getBaseUrl}`, {
//       email,
//       password,
//     })
//     .then((res) => res.data)
//     .catch((err) => console.log(err));

export { loginUser, forceLoginUser, logOutUser };
