import { deleteVideoContent } from "../../services/index";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../utils/index";

export const useDeleteVideoContentData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: deleteVideoContentIsLoading } = useMutation(
    async (videoContentId: number) => await deleteVideoContent(videoContentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("videoContents");
        toastSuccess(toast, "VideoContent Deleted");
      },
      onError: () => {
        toastError(toast, "VideoContent not Deleted");
      },
    }
  );
  return {
    mutate,
    deleteVideoContentIsLoading,
  };
};
