import { IProduct } from "../../models/index";
import { addProduct } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddProductData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addProductIsLoading } = useMutation(
    async (product: IProduct) => await addProduct(product),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
        toastSuccess(toast, "Product Added");
      },
      onError: () => {
        toastError(toast, "Product not Added");
      },
    }
  );
  return { mutate, addProductIsLoading };
};
