import { IFieldProps } from "@/models";
import { Calendar, CalendarProps } from "primereact/calendar";
import React from "react";
import { useTranslation } from "react-i18next";

interface CalendarFieldProps extends CalendarProps, IFieldProps {}

const CalendarField: React.FC<CalendarFieldProps> = ({
  getFormErrorMessage,
  labelName,
  labelText,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className="field">
      <label htmlFor={labelName}>{t(labelText)}</label>
      <Calendar {...rest} />
    </div>
  );
};

export default CalendarField;
