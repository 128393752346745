import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";

interface Invoice {
  id: number;
  customerName: string;
  email: string;
  price: number;
  status: string;
}

interface InvoiceTableProps {
  invoices: Invoice[];
  onDetailsClick: () => void;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices,
  onDetailsClick,
}) => {
  const renderStatusColumn = (rowData: Invoice) => {
    return <Badge value={rowData.status} severity="danger" />;
  };

  const renderDetailsButton = () => {
    return (
      <Button
        label="Details"
        className="p-button-secondary details-button"
        onClick={onDetailsClick}
      />
    );
  };

  return (
    <DataTable value={invoices} scrollable scrollHeight="400px">
      <Column field="id" header="ID" style={{ maxWidth: "6rem" }} />
      <Column field="email" header="Email" style={{ minWidth: "16rem" }} />
      <Column field="price" header="Price" style={{ maxWidth: "6rem" }} />
      <Column header="Status" body={renderStatusColumn} />
      <Column body={renderDetailsButton} style={{ textAlign: "right" }} />
    </DataTable>
  );
};

export default InvoiceTable;
