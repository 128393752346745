import { IProduct } from "../../models/index";
import { updateProduct } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateProductData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: updateProductIsLoading } = useMutation(
    async (product: IProduct) => await updateProduct(product),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
        toastSuccess(toast, "Product Updated");
      },
      onError: () => {
        toastError(toast, "Product not Updated");
      },
    }
  );
  return { mutate, updateProductIsLoading };
};
