import React, { useEffect, useState } from 'react';
import { getIdleInvoices } from "../../../services";
import InvoiceTable from './InvoiceTable';
import {Invoice} from './types/Invoice'; 


const IdleInvoicesTable = () => {
  const [idleInvoices, setIdleInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    fetchIdleInvoices();
  }, []);

  const fetchIdleInvoices = async () => {
    try {
      const response = await getIdleInvoices(); // Assuming you have an API method for fetching the idle invoices
      setIdleInvoices(response);
    } catch (error) {
      console.error('Failed to fetch idle invoices:', error);
    }
  };

  const handleDetailsClick = () => {
    console.log('Details button clicked');
  };

  return (
    <div>
      <h4>Idle Invoices</h4>
      <InvoiceTable invoices={idleInvoices} onDetailsClick={handleDetailsClick} />
    </div>
  );
};

export default IdleInvoicesTable;
