import { getLicensesOfContent } from "../../services/index";
import { useQuery } from "react-query";

export const useGetLicensesOfContentData = (
  videoContentId: undefined | number
) => {
  const {
    data: licensesOfContentData,
    isLoading: getLicensesOfContentIsLoading,
    refetch: refetchGetLicensesOfContent,
  } = useQuery(["licensesOfContent", videoContentId], getLicensesOfContent);
  return {
    licensesOfContentData,
    getLicensesOfContentIsLoading,
    refetchGetLicensesOfContent,
  };
};
