import { AppMenu, AppTopbar } from "../../components/index";
import { IComponentProps } from "../../models/index";
import React from "react";
import { MainContainer, MainLayout } from "../../layouts/index";

interface DashboardWrapperProps extends IComponentProps {}

const DashboardWrapper: React.FC<DashboardWrapperProps> = ({ children }) => {
 return (
  <div>
   <AppTopbar />
   <AppMenu />
   <MainContainer>
    <MainLayout>{children}</MainLayout>
   </MainContainer>
  </div>
 );
};

export default DashboardWrapper;
