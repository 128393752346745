import { getContracts } from "../../services/index";
import { useQuery } from "react-query";

export const useGetContractsData = () => {
  const { data: contracts, isLoading: getContractsIsLoading } = useQuery(
    "contracts",
    getContracts
  );
  return { contracts, getContractsIsLoading };
};
