import { getCGsOfContent } from "../../services/index";
import { useQuery } from "react-query";

export const useGetCGroupsOfContentData = (
  videoContentId: undefined | number
) => {
  const {
    data: cGroupsOfContentData,
    isLoading: getCgroupsOfContentIsLoading,
    refetch: refetchGetcGroupsOfContent,
  } = useQuery(["cGroupsOfContent", videoContentId], getCGsOfContent);
  return {
    cGroupsOfContentData,
    getCgroupsOfContentIsLoading,
    refetchGetcGroupsOfContent,
  };
};
