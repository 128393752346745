import { IFieldProps } from "@/models";
import { InputText, InputTextProps } from "primereact/inputtext";
import React from "react";
import { useTranslation } from "react-i18next";

interface InputFieldProps extends InputTextProps, IFieldProps {}

const InputField: React.FC<InputFieldProps> = ({
  getFormErrorMessage,
  labelName,
  labelText,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className="field">
      <label htmlFor={labelName}>{t(labelText)}</label>
      <InputText {...rest} />
      {getFormErrorMessage != null && getFormErrorMessage()}
    </div>
  );
};

export default InputField;
