import { MessagesDataTable } from "../../components/DataTable/messages";

const Messages = () => {
  return (
    <div className="datatable-crud">
      <div className="card">
        <MessagesDataTable />
      </div>
    </div>
  );
};

export default Messages;
