import { Dialog } from "primereact/dialog";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DataTableDialogFooter, ImageUploadField } from "../../../index";
import { IPosterDefinition } from "../../../../models/index";
import { Toast } from "primereact/toast";

interface Props {
  visible: boolean;
  onHide: () => void;
  addImage: () => void;
  fileList: File[];
  setFileList: (fileList: File[]) => void;
  multiple?: boolean;
  posterDefinition: IPosterDefinition;
}

const ImageDialog: React.FC<Props> = ({
  visible,
  onHide,
  addImage,
  fileList,
  setFileList,
  multiple,
  posterDefinition,
}) => {
  const { t } = useTranslation();

  const closeDialog = () => {
    onHide();
    setFileList([]);
  };

  const handleSubmit = () => {
    addImage();
    onHide();
    setFileList([]);
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "60%" }}
      header={t("Image Upload")}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          hideDialog={closeDialog}
          handleSubmit={handleSubmit}
        />
      }
      onHide={closeDialog}
    >
      <form onSubmit={handleSubmit}>
        <ImageUploadField
          setFileList={setFileList}
          fileList={fileList}
          multiple={multiple}
          posterDefinition={posterDefinition}
        />
      </form>
    </Dialog>
  );
};

export default ImageDialog;
