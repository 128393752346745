import { useState } from "react";
import VideoPublishDataTable from "../../components/DataTable/videoContent/publishing/VideoPublishDataTable";

import { TabView, TabPanel } from "primereact/tabview";
import VideoUNPublishDataTable from "../../components/DataTable/videoContent/publishing/VideoUNPublishDataTable";

const VideoContentsForPublish = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="datatable-crud">
      <div className="">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Publish Content">
            <VideoPublishDataTable />
          </TabPanel>
          <TabPanel header="UNPublish Content">
            <VideoUNPublishDataTable />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default VideoContentsForPublish;
