import { GENDER_TYPES } from "../constants/index";
import * as Yup from "yup";

export const customerSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Name field is required"),
  surname: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Surname field is required"),
  email: Yup.string().email().required("email is required"),
  gsmno: Yup.string()
    .nullable()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Gsmno field is required"),
  city: Yup.string()
    .nullable()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("City field is required"),
  birthDate: Yup.date().nullable().required("birth date field is required"),
  gender: Yup.string()
    .required()
    .nullable()
    .oneOf(
      [GENDER_TYPES.MALE, GENDER_TYPES.FEMALE],
      "Gender field is required"
    ),
  address: Yup.string()
    .nullable()
    .min(2, "Please enter at least 10 characters!")
    .max(50, "You can enter up to 250 characters!")
    .required("Address field is required"),
});
