import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useVdocipher from "../../../../hooks/player/use-vdocipher";
import VideoLayer from "../layer/VideoLayer";

export default function VideoPlayer({ setCloseButton, otp, playbackInfo }) {
  const { loadVideo, isAPIReady } = useVdocipher();
  const container = useRef();
  const [videoRef, setVideoRef] = useState(null);
  const [player, setPlayer] = useState(null);
  const [hover, setHover] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAPIReady) return;
    if (!container.current) return;
    if (videoRef) return;
    container.current.innerHTML = "";
    const player = loadVideo({
      otp: otp,
      playbackInfo: playbackInfo,
      configuration: {
        player: "u31audcQjv1Z8w6D",
        // controls: "off",
      },
      container: container.current,
    });
    setVideoRef(player);
  }, [isAPIReady, loadVideo, videoRef]);

  const handleMouseEnter = () => {
    setHover(true);
    setCloseButton(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setHover(false);
      setCloseButton(false);
    }, 4100);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="vdo-container"
          ref={container}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />

        <VideoLayer
          videoRef={videoRef}
          isAPIReady={isAPIReady}
          isVisible={hover}
          setVisible={setHover}
          style={styles.layer}
        />
      </div>
    </>
  );
}

const styles = {
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "90vh",
    //backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
};
