import { axiosInstance } from "../../api/axios.instance";
import { IUser } from "@/models";

export const getUsers = async (): Promise<IUser[]> =>
  await axiosInstance
    .get(`/a2srv-operation/operational/user/all`)
    .then((res) => res.data.data);

export const addUser = async (user: IUser) =>
  await axiosInstance.post(`/a2srv-operation/operational/user`, {
    opUserP: user,
  });

export const deleteUser = async (userId: bigint) =>
  await axiosInstance.delete(`/user/${userId}`);
