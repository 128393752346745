import { IProvider } from "../../models/index";
import { updateProvider } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateProviderData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: updateProviderIsLoading } = useMutation(
    async (provider: IProvider) => await updateProvider(provider),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("providers");
        toastSuccess(toast, "Provider Updated");
      },
      onError: () => {
        toastError(toast, "Provider not Updated");
      },
    }
  );
  return { mutate, updateProviderIsLoading };
};
