import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { SyntheticEvent, useRef, useState } from "react";
import {
  DataTableActionTemplate,
  DataTableDeleteDialog,
  DataTableStatusTemplate,
  VideoContentEditDialog,
  VideoContentAddDialog,
  DataTableToolbar,
  MetadataDialog,
  PostersDialog,
  AddContentGroupDialog,
  AddLicenseToContentDialog,
  AddRemoveLicenseToContentDialog,
  PlayDialog,
} from "../../../index";
import { IContentP, IVideoContent } from "../../../../models/index";
import {
  videoContentFilters,
  initialVideoContent,
  initialContentP,
  statusOptions,
} from "../../../../constants/index";
import {
  useGetVideoContentsData,
  useAddVideoContentData,
  useDeleteVideoContentData,
  useUpdateVideoContentData,
  useUpdateMetadataData,
  useGetContentMetadataData,
  useGetSeasonsData,
  useGetAllDynamicData,
  useGetPosterDefinitionsData,
  useGetContentPostersData,
  useGetContentGroups,
  useGetLicensesDataForList,
} from "../../../../hooks/index";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import NoImage from "../../../../assets/images/no-image.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";

const VideoContentDataTable = () => {
  const [selectedVideoContent, setSelectedVideoContent] =
    useState<IVideoContent | null>(null);
  const [videoContent, setVideoContent] =
    useState<IVideoContent>(initialVideoContent);
  const [videoContentId, setVideoContentId] = useState<number>(-1);
  const [filters, setFilters] = useState(videoContentFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [deleteVideoContentDialog, setDeleteVideoContentDialog] =
    useState(false);
  const [videoContentEditDialog, setVideoContentEditDialog] = useState(false);
  const [videoContentAddDialog, setVideoContentAddDialog] = useState(false);
  const [metadataDialog, setMetadataDialog] = useState(false);
  const [postersDialog, setPostersDialog] = useState(false);
  const [cgroupDialog, setCgroupDialog] = useState(false);
  const [licenseDialog, setLicenseDialog] = useState(false);
  const [playDialog, setPlayDialog] = useState(false);

  // const [videoContentDetailsDialog, setVideoContentDetailsDialog] =
  //   useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const { videoContents, getVideoContentsIsLoading } =
    useGetVideoContentsData();
  const {
    posterDefinitions,
    getPosterDefinitionsIsLoading,
    refetchGetPosterDefinitions,
  } = useGetPosterDefinitionsData();
  const {
    contentPosters,
    getContentPostersIsLoading,
    refetchGetContentPostersData,
  } = useGetContentPostersData(videoContentId);
  const {
    contentMetadata,
    getContentMetadataIsLoading,
    refetchGetContentMetadataData,
  } = useGetContentMetadataData(videoContentId);
  const { refetchGetSeasons } = useGetSeasonsData();
  const { refetchGetAllDynamic } = useGetAllDynamicData();
  // const { videoContentd, getVideoContentdIsLoading, refetchGetVideoContentd } =
  //   useGetVideoContentDetails(videoContent.id);

  const toast = useRef<Toast>(null);

  const { mutate: addVideoContentMutate, addVideoContentIsLoading } =
    useAddVideoContentData(toast);
  const { mutate: updateVideoContentMutate } = useUpdateVideoContentData(toast);
  const { mutate: updateMetadataMutate } = useUpdateMetadataData(toast);
  const { mutate: deleteVideoContentMutate } = useDeleteVideoContentData(toast);

  const { contentGroups, getContentGroupsIsLoading } = useGetContentGroups();

  const { licenses, getLicensesIsLoading } = useGetLicensesDataForList();

  const openDeleteVideoContentDialog = (videoContent?: IVideoContent) => {
    videoContent && setVideoContent(videoContent);
    setDeleteVideoContentDialog(true);
  };
  const openAddVideoContentDialog = () => {
    setVideoContent(initialVideoContent);
    setIsAddItem(true);
    setVideoContentAddDialog(true);
  };
  const openVideoContentEditDialog = (rowData: IVideoContent) => {
    setVideoContent({ ...rowData });
    setIsAddItem(false);
    setVideoContentEditDialog(true);
  };

  const openEditMetadataDialog = async (rowData: IVideoContent) => {
    await setVideoContentId(rowData.id);
    await refetchGetSeasons();
    await refetchGetAllDynamic();
    await await refetchGetContentMetadataData();
    await setIsAddItem(false);
    setMetadataDialog(true);
  };

  const openPostersDialog = async (rowData: IVideoContent) => {
    await setVideoContentId(rowData.id);
    refetchGetPosterDefinitions();
    refetchGetContentPostersData();
    setPostersDialog(true);
  };
  const openAddToContentPage = async (rowData: IVideoContent) => {
    await setVideoContentId(rowData.id);
    //navigate(`/addToContent/${rowData.id}`);
    setVideoContent({ ...rowData });
    setCgroupDialog(true);
  };

  const openLicenseOfContentDialog = async (rowData: IVideoContent) => {
    await setVideoContentId(rowData.id);
    //navigate(`/addToContent/${rowData.id}`);
    setVideoContent({ ...rowData });
    setLicenseDialog(true);
  };

  const openPlayDialog = async (rowData: IVideoContent) => {
    await setVideoContentId(rowData.id);
    //refetchGetPosterDefinitions();
    //refetchGetContentPostersData();
    setVideoContent({ ...rowData });
    setPlayDialog(true);
  };

  // const openVideoContentDetailsDialog = async (rowData: IVideoContent) => {
  //   await setVideoContent({ ...rowData });
  //   await refetchGetVideoContentd();
  //   setVideoContentDetailsDialog(true);
  // };

  const hideVideoContentEditDialog = () => {
    setVideoContentEditDialog(false);
  };

  const hideVideoContentAddDialog = () => {
    setVideoContentAddDialog(false);
  };

  const hideMetadataDialog = () => {
    setMetadataDialog(false);
  };

  const hidePostersDialog = () => {
    setPostersDialog(false);
  };

  const hideCGroupsDialog = () => {
    setCgroupDialog(false);
  };

  const hideLicenseDialog = () => {
    setLicenseDialog(false);
  };

  const hidePlayDialog = () => {
    setPlayDialog(false);
  };

  // const hideVideoContentDetailsDialog = () => {
  //   setVideoContentDetailsDialog(false);
  // };

  const hideDeleteVideoContentDialog = () => {
    setDeleteVideoContentDialog(false);
  };

  const saveVideoContent = (newVideoContent: IVideoContent) => {
    isAddItem
      ? addVideoContentMutate(newVideoContent)
      : updateVideoContentMutate(newVideoContent);

    //!isAddItem && hideVideoContentDialog();
    isAddItem && hideVideoContentAddDialog();
  };

  const deleteVideoContent = () => {
    videoContent.id && deleteVideoContentMutate(videoContent.id);
    hideDeleteVideoContentDialog();
  };

  const saveMetadata = (newMetadata: IContentP) => {
    updateMetadataMutate(newMetadata);
    !isAddItem && hideMetadataDialog();
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const navigate = useNavigate();

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 className="mx-0 my-1">{"Manage Video Contents"}</h5>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const statusItemTemplate = (option: any) => {
    return (
      <span className={`badge status-${option}`}>
        {option?.replaceAll("_", " ")}
      </span>
    );
  };

  const statusValueTemplate = (option: any) => {
    return !option ? (
      <span>Select a Status</span>
    ) : (
      <span className={`badge status-${option}`}>
        {option?.replaceAll("_", " ")}
      </span>
    );
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statusOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        valueTemplate={statusValueTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
      />
    );
  };

  const imageBodyTemplate = (rowData: IVideoContent) => {
    return (
      <Image
        src={`${rowData.catalogPosterUrl}`}
        alt="videoContent"
        height="80"
        width="80"
        preview
        className="videoContent-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = NoImage;
        }}
      />
    );
  };

  const header = renderHeader();

  const items = [
    {
      label: "Option 1",
      icon: "pi pi-check",
      command: () => {
        console.log("Option 1 selected");
      },
    },
    {
      label: "Option 2",
      icon: "pi pi-refresh",
      command: () => {
        console.log("Option 2 selected");
      },
    },
    {
      label: "Option 3",
      icon: "pi pi-times",
      command: () => {
        console.log("Option 3 selected");
      },
    },
  ];

  return (
    <>
      <Toast ref={toast} />
      <DataTableToolbar
        openDeleteDialog={openDeleteVideoContentDialog}
        openAddDialog={openAddVideoContentDialog}
        disableDeleteButton={!selectedVideoContent}
        showDeleteButton={false}
      />
      <DataTable
        value={videoContents}
        paginator
        rows={50}
        rowsPerPageOptions={[10, 50, 100]}
        dataKey="id"
        selection={selectedVideoContent}
        onSelectionChange={(e: any) => setSelectedVideoContent(e.value)}
        filters={filters}
        filterDisplay="row"
        loading={getVideoContentsIsLoading}
        responsiveLayout="scroll"
        globalFilterFields={["name", "city.name", "status"]}
        header={header}
        emptyMessage="No video contents found."
      >
        <Column
          field="id"
          header={"ID"}
          sortable
          style={{ maxWidth: "10rem", minWidth: "1.5rem" }}
        />
        <Column
          field="catalogPosterUrl"
          header={"Poster"}
          body={imageBodyTemplate}
        />
        <Column
          field="name"
          header={"Name"}
          filter
          filterPlaceholder="Search by name"
          sortable
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
        />
        <Column
          field="status"
          header={"Status"}
          filter
          filterElement={statusRowFilterTemplate}
          showFilterMenu={false}
          sortable
          style={{ maxWidth: "18rem", minWidth: "14.5rem" }}
          body={(rowData: IVideoContent) => (
            <DataTableStatusTemplate status={rowData.status} />
          )}
        />
        {/* 
        <Column
          field="licenseEndDate"
          header={"License End Date"}
          filter
          filterPlaceholder="Search by license end date"
          sortable
          style={{ maxWidth: "18rem", minWidth: "12.5rem" }}
          body={(rowData) => (
            <>{moment(rowData.licenseEndDate).format("DD/MM/YYYY, h:mm a")}</>
          )}
        />
*/}
        <Column
          field="type"
          header={"Type"}
          filter
          filterPlaceholder="Search by type"
          sortable
          style={{ maxWidth: "18rem", minWidth: "12.5rem" }}
        />

        <Column
          body={(rowData: IVideoContent) => (
            <>
              <DataTableActionTemplate
                openEditDialog={() => openVideoContentEditDialog(rowData)}
                openMetadataDialog={() => openEditMetadataDialog(rowData)}
                openPostersDialog={() => openPostersDialog(rowData)}
                openAddContentGroup={() => openAddToContentPage(rowData)}
                openLicenseOfContentDialog={() =>
                  openLicenseOfContentDialog(rowData)
                }
                openDeleteDialog={() => openDeleteVideoContentDialog(rowData)}
                openPlayDialog={() => openPlayDialog(rowData)}

                // openDetails={() => openVideoContentDetailsDialog(rowData)}
              />
            </>
          )}
          exportable={false}
          style={{ maxWidth: "18rem", minWidth: "14.5rem" }}
        />
      </DataTable>
      <DataTableDeleteDialog
        visible={deleteVideoContentDialog}
        data={selectedVideoContent ?? videoContent}
        onHide={hideDeleteVideoContentDialog}
        onDelete={deleteVideoContent}
      />
      <VideoContentEditDialog
        visible={videoContentEditDialog}
        videoContent={videoContent}
        isLoading={addVideoContentIsLoading}
        onHide={hideVideoContentEditDialog}
        editVideoContent={saveVideoContent}
      />
      <VideoContentAddDialog
        visible={videoContentAddDialog}
        videoContent={videoContent}
        isLoading={addVideoContentIsLoading}
        onHide={hideVideoContentAddDialog}
        addVideoContent={saveVideoContent}
      />
      <MetadataDialog
        visible={metadataDialog}
        metadata={contentMetadata ?? initialContentP}
        isLoading={getContentMetadataIsLoading}
        onHide={hideMetadataDialog}
        saveMetadata={saveMetadata}
      />
      <PostersDialog
        visible={postersDialog}
        onHide={hidePostersDialog}
        isLoading={getPosterDefinitionsIsLoading && getContentPostersIsLoading}
        posterDefinitions={posterDefinitions}
        contentPosters={contentPosters}
        videoContentId={videoContentId}
      />

      <AddContentGroupDialog
        contentName={videoContent.name}
        visible={cgroupDialog}
        onHide={hideCGroupsDialog}
        isLoading={getContentGroupsIsLoading}
        videoContentId={videoContentId}
        contentGroups={contentGroups}
      />

      <AddRemoveLicenseToContentDialog
        visible={licenseDialog}
        content={videoContent}
        onHide={hideLicenseDialog}
        isLoading={getLicensesIsLoading}
        licenses={licenses}
      />

      <PlayDialog
        visible={playDialog}
        videoContentId={videoContentId}
        onHide={hidePlayDialog}
        content={videoContent}
      />

      {/* <VideoContentDetailsDialog
        visible={videoContentDetailsDialog}
        videoContentd={videoContentd}
        isLoading={getVideoContentdIsLoading}
        onHide={hideVideoContentDetailsDialog}
      /> */}
    </>
  );
};

export default VideoContentDataTable;
