import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PickList } from "primereact/picklist";
import { Toast } from "primereact/toast";
import { ILicense, ILicensesAddOc, ILicensesDeleteOc } from "@/models";
import {
  initialLicensesAddOc,
  initialLicensesDeleteOc,
} from "../../constants/index"; // Adjust the initialization constants
import {
  useGetLicensesData,
  useGetLicensesDataForList,
  useGetVideoContents,
  useAddLicenseToContent,
  useRemoveLicenseFromContent,
  useGetVideoContentDetails,
  useGetLicensesOfContentData,
} from "../../hooks/index";
import { isConstructorDeclaration } from "typescript";

const AddLicenseToContent = () => {
  const [license, setLicense] = useState<ILicensesAddOc>(initialLicensesAddOc);
  const [deleteLicenseObject, setDeleteLicenseObject] =
    useState<ILicensesDeleteOc>(initialLicensesDeleteOc);

  let { id } = useParams();
  let numberId: number = id ? parseInt(id, 10) : 0;

  const toast = useRef<Toast>(null);
  //const { videoContents } = useGetVideoContents();

  const { licenses, getLicensesIsLoading } = useGetLicensesDataForList();

  const { mutate: addLicenseToContent } = useAddLicenseToContent(toast);
  const { mutate: removeLicenseFromContent } =
    useRemoveLicenseFromContent(toast);

  const { licensesOfContentData, getLicensesOfContentIsLoading } =
    useGetLicensesOfContentData(numberId);

  const [source, setSource] = useState<ILicense[]>([]);
  const [target, setTarget] = useState<ILicense[]>([]);

  useEffect(() => {
    id && setLicense({ ...license, cId: +id });
    const newSource: ILicense[] = [];
    const newTarget: ILicense[] = [];

    licenses?.map((licenseItem) => {
      !licensesOfContentData?.some((video) => video.id === licenseItem.id)
        ? newSource.push(licenseItem)
        : newTarget.push(licenseItem);
    });

    setSource([...newSource]);
    setTarget([...newTarget]);
  }, [id, getLicensesOfContentIsLoading, getLicensesIsLoading]);

  const onChange = (event: any) => {
    console.log("onChange");
    setSource(event.source);
    setTarget(event.target);
  };

  const onMove = (
    event: any,
    mutate: Function,
    operation: "add" | "remove"
  ) => {
    console.log("onMove");
    const newArray = event.value.map((item: any) => {
      return item.id;
    });

    if (id) {
      let contentGroupObject: ILicensesAddOc | ILicensesDeleteOc;

      if (operation === "add") {
        contentGroupObject = {
          ...license,
          cId: +id,
          lcIds: [...newArray],
          username: "admin",
        };
      } else {
        contentGroupObject = {
          ...deleteLicenseObject,
          cId: +id,
          lcId: newArray[0],
          username: "admin",
        };
      }
      mutate(contentGroupObject);
    }
  };

  const onMoveToSource = (event: any) => {
    console.log("onMoveToSource");
    onMove(event, removeLicenseFromContent, "remove");
  };

  const onMoveToTarget = (event: any) => {
    console.log("onMoveToTarget");
    onMove(event, addLicenseToContent, "add");
  };

  const onMoveAllToSource = (event: any) => {
    onMove(event, removeLicenseFromContent, "remove");
  };

  const onMoveAllToTarget = (event: any) => {
    onMove(event, addLicenseToContent, "add");
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.name}</h5>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <PickList
          source={source ?? null}
          target={target ?? null}
          itemTemplate={itemTemplate}
          sourceHeader="Available"
          targetHeader="Selected"
          sourceStyle={{ height: "342px" }}
          targetStyle={{ height: "342px" }}
          onChange={onChange}
          onMoveToSource={onMoveToSource}
          onMoveToTarget={onMoveToTarget}
          onMoveAllToSource={onMoveAllToSource}
          onMoveAllToTarget={onMoveAllToTarget}
          filterBy="name"
          sourceFilterPlaceholder="Search by name"
          targetFilterPlaceholder="Search by name"
        />
      </div>
    </div>
  );
};

export default AddLicenseToContent;
