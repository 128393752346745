import { Row } from "react-bootstrap";
import { getAllSubscriptions } from "../../../../services";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";

import ISubscription from "../../../../models/Entities/customer/ISubscription";
import moment from "moment";
import { DataTableStatusTemplate } from "../../common";
import SubscriptionInfoDialog from "../../../../components/Dialog/payment/SubscriptionInfoDialog";
import PaymentList from "../../../../components/Dialog/payment/PaymentList";
import { STATUS_TYPES, subscriptionFilters } from "../../../../constants";
import { Dropdown } from "primereact/dropdown";

const AllSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(true);

  const [subscription, setSubscription] = useState<ISubscription>();
  const [subscriptionId, setSubscriptionId] = useState(0);

  const [subDetailDialog, setSubDetailDialog] = useState(false);
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);

  const [filters, setFilters] = useState(subscriptionFilters);

  const [selectedStatus, setSelectedStatus] = useState("ACTIVE");

  const statusOptions = [
    STATUS_TYPES.ACTIVE,
    STATUS_TYPES.INACTIVE,
    "WAITING_PAYMENT",
    "ENDED",
    "DELETED",
  ];

  const channelOptions = [
    "CREDIT_CARD_WEB",
    "CREDIT_CARD_ANDROID",
    "APPLE",
    "CLIENT",
  ];

  const statusItemTemplate = (option: any) => {
    return <span className={`badge status-${option}`}>{option}</span>;
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statusOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
      />
    );
  };

  const channelRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={channelOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select a Channel"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
      />
    );
  };

  useEffect(() => {
    try {
      getAllSubscriptions(null).then((response) => {
        //console.log("getAllSubscriptions.response.data:", response.data);
        if (response && response.data) {
          setSubscriptions(response.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  const triggerRefresh = () => {
    setRefreshList(!refreshList);
  };

  const openSubsDetailsDialog = (subscription?: ISubscription) => {
    subscription && setSubscription(subscription);
    setSubDetailDialog(true);
  };

  const openPaymentsDialog = (subscription?: ISubscription) => {
    subscription && setSubscription(subscription);
    console.log("subscription", subscription);
    setShowPaymentsDialog(true);
  };

  const hideSubsDetailsDialog = () => {
    setSubDetailDialog(false);
  };

  const hidePaymentListDialog = () => {
    setShowPaymentsDialog(false);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">All Subscriptions</span>

      <Button icon="pi pi-refresh" onClick={triggerRefresh} />
    </div>
  );
  const footer = `In total there are ${
    subscriptions ? subscriptions.length : 0
  } record.`;

  return (
    <div className="">
      <Row>
        <DataTable
          className="p-datatable-customers"
          value={subscriptions}
          tableStyle={{ width: "100%" }}
          header={header}
          footer={footer}
          filters={filters}
          paginator
          showGridlines
          rows={25}
          dataKey="subId"
          responsiveLayout="scroll"
          globalFilterFields={["status", "channel"]}
          emptyMessage="No Subscriptions found."
          filterDisplay="row"
          selection={subscription}
          onSelectionChange={(e: any) => setSubscription(e.value)}
        >
          <Column
            field="subId"
            header="ID"
            body={(rowData) => <>{rowData?.id}</>}
            sortable
            style={{ width: "5%" }}
          />
          <Column
            field="status"
            header="Status"
            filter
            filterElement={statusRowFilterTemplate}
            showFilterMenu={false}
            body={(rowData) => (
              <DataTableStatusTemplate status={rowData?.status} />
            )}
            sortable
            style={{ width: "10%" }}
          />
          <Column
            field="subEndDate"
            header="Sub EndDate"
            body={(rowData) => (
              <>
                {rowData.endDate &&
                  moment(rowData.endDate).format("DD/MM/YYYY")}
              </>
            )}
            sortable
            style={{ width: "12%" }}
          />
          <Column
            field="subRDate"
            header="Sub RenewalDate"
            body={(rowData) => (
              <>
                {rowData.renewalDate &&
                  moment(rowData.renewalDate).format("DD/MM/YYYY")}
              </>
            )}
            sortable
            style={{ width: "12%" }}
          />

          <Column
            field="custEmail"
            header="Email"
            body={(rowData) => <>{rowData?.customer.email}</>}
            sortable
            style={{ width: "20%" }}
          />
          <Column
            field="productName"
            header="Product"
            body={(rowData) => <>{rowData?.productP.name}</>}
            sortable
          />
          <Column
            field="channel"
            header="Channel"
            body={(rowData) => <>{rowData?.channel}</>}
            sortable
            style={{ width: "20%" }}
            filter
            filterElement={channelRowFilterTemplate}
          />

          <Column
            body={(rowData: ISubscription) => (
              <>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-outlined m-1"
                  onClick={() => openSubsDetailsDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
                <Button
                  icon="pi pi-paypal"
                  className="p-button-secondary p-button-rounded p-button-outlined m-1"
                  onClick={() => openPaymentsDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
              </>
            )}
            exportable={false}
            style={{ width: "10%" }}
          />
        </DataTable>
        <SubscriptionInfoDialog
          subscriptionId={subscription?.id}
          merchantOid={"" + subscription?.id}
          visible={subDetailDialog}
          onHide={hideSubsDetailsDialog}
        />
        <PaymentList
          onHide={hidePaymentListDialog}
          subscriptionId={subscription?.id}
          visible={showPaymentsDialog}
        />
      </Row>
    </div>
  );
};

export default AllSubscriptions;
