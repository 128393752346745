import { IVideoContent } from "@/models";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { videoContentSchema } from "../../../../validations/index";

import {
  InputField,
  ErrorField,
  DataTableDialogFooter,
  AddVideoFileDailog,
} from "../../../index";
import {
  isFormFieldValid,
  capitalizeFirstLetter,
} from "../../../../utils/index";
import { Button } from "primereact/button";

interface VideoContentEditDialogProps {
  visible: boolean;
  videoContent: IVideoContent;
  isLoading: boolean;
  onHide: () => void;
  editVideoContent: (videoContent: IVideoContent) => void;
}

const VideoContentEditDialog: React.FC<VideoContentEditDialogProps> = ({
  visible,
  videoContent,
  isLoading,
  onHide,
  editVideoContent,
}) => {
  const formik = useFormik({
    initialValues: videoContent,
    enableReinitialize: true,
    validationSchema: videoContentSchema,
    onSubmit: (data) => {
      editVideoContent(data);
      formik.resetForm();
      hideDialog();
    },
  });

  const [isAddVideoDialogVisible, setIsAddVideoDialogVisible] =
    useState<boolean>(false);

  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);

  const hideDialog = () => {
    onHide();
    formik.resetForm();
  };

  function openAddVideoDialog(): void {
    setIsAddVideoDialogVisible(true);
  }

  function hideAddVideoDialog(): void {
    setIsAddVideoDialogVisible(false);
  }

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "450px" }}
        header={"Edit Content"}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={hideDialog}
            handleSubmit={formik.handleSubmit}
          />
        }
        onHide={hideDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <InputField
            id="name"
            value={formik.values.name ?? ""}
            type="text"
            required
            autoFocus={true}
            onChange={formik.handleChange}
            labelName="Content Name"
            labelText={capitalizeFirstLetter("Content Name")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="Content Name" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "Content Name"),
            })}
          />
          {/* 
          <InputField
            id="vdoId"
            type="text"
            value={formik.values.vdoId ?? ""}
            disabled={!formik.values.external}
            onChange={formik.handleChange}
            labelName="External Id"
            labelText={capitalizeFirstLetter("External Id")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="External Id" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "External Id"),
            })}
          />
          <InputField
            id="vdoTrailerId"
            type="text"
            value={formik.values.vdoTrailerId ?? ""}
            disabled={!formik.values.external}
            onChange={formik.handleChange}
            labelName="Trailer Id"
            labelText={capitalizeFirstLetter("Trailer Id")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="Trailer Id" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "Trailer Id"),
            })}
          />

*/}
        </form>

        {/*
        <div>
          <Button
            label="Add Trailer File"
            className="p-button-secondary details-button"
            onClick={() => openAddVideoDialog()}
          />
        </div>
        <div>
          <Button
            label="Add Video File"
            className="p-button-primary details-button"
            onClick={() => openAddVideoDialog()}
          />
        </div>

        */}
      </Dialog>

      <AddVideoFileDailog
        visible={isAddVideoDialogVisible}
        content={videoContent}
        onHide={hideAddVideoDialog}
        isTrailer={false}
      />
    </>
  );
};

export default VideoContentEditDialog;
