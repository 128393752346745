import { Button } from "primereact/button";
import React from "react";

interface DataTableActionTemplateProps {
  openDeleteDialog?: () => void;
  openEditDialog?: () => void;
  openDetails?: () => void;
  openAddContentGroup?: () => void;
  openMetadataDialog?: () => void;
  openPostersDialog?: () => void;
  openAddToContent?: () => void;
  openLicenseOfContentDialog?: () => void;
  openPlayDialog?: () => void;
}

const DataTableActionTemplate: React.FC<DataTableActionTemplateProps> = ({
  openDeleteDialog,
  openEditDialog,
  openDetails,
  openAddContentGroup,
  openMetadataDialog,
  openPostersDialog,
  openAddToContent,
  openLicenseOfContentDialog,
  openPlayDialog,
}) => {
  return (
    <>
      {openEditDialog && (
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success m-1"
          onClick={() => openEditDialog()}
        />
      )}
      {openDetails && (
        <Button
          icon="pi pi-user"
          className="p-button-rounded p-button-info m-1"
          aria-label="User"
          onClick={() => openDetails()}
        />
      )}
      {openAddToContent && (
        <Button
          icon="pi pi-search"
          className="p-button-rounded p-button-info m-1"
          aria-label="Search"
          onClick={() => openAddToContent()}
        />
      )}
      {openMetadataDialog && (
        <Button
          icon="pi pi-info-circle"
          className="p-button-rounded p-button-secondary m-1"
          onClick={() => openMetadataDialog()}
        />
      )}
      {openPostersDialog !== undefined && (
        <Button
          icon="pi pi-images"
          className="p-button-rounded m-1"
          onClick={() => openPostersDialog()}
        />
      )}
      {openAddContentGroup && (
        <Button
          icon="pi pi-arrows-h"
          className="p-button-rounded p-button-info m-1"
          aria-label="Search"
          onClick={() => openAddContentGroup()}
        />
      )}
      {openLicenseOfContentDialog && (
        <Button
          icon="pi pi-tablet"
          className="p-button-rounded p-button-secondary m-1"
          style={{
            backgroundColor: "#a13cb28c",
            border: "1px solid #a13cb28c",
          }}
          onClick={() => openLicenseOfContentDialog()}
        />
      )}
      {openDeleteDialog !== undefined && (
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning m-1"
          onClick={() => openDeleteDialog()}
        />
      )}
      {openPlayDialog !== undefined && (
        <Button
          icon="pi pi-play"
          className="p-button-rounded m-1"
          onClick={() => openPlayDialog()}
          style={{
            backgroundColor: "#c010f7",
            border: "1px solid #c010f7",
          }}
        />
      )}
    </>
  );
};

export default DataTableActionTemplate;
