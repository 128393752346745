import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILabelDefinition } from "../../../../models";
import { DataTableDialogFooter } from "../../../index";
import { getContentsOfLabel, reOrderList } from "../../../../services";
import { Toast } from "primereact/toast";
import { Row } from "react-bootstrap";
import { IContentP } from "../../../../models";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toastError, toastSuccess } from "../../../../utils";

const dragHandleContainerStyle = {
  display: "inline-block",
  padding: "5px 10px",
  marginRight: "10px",
  cursor: "move",
};

const dragHandleLineStyle = {
  width: "20px",
  height: "3px",
  backgroundColor: "#000",
  margin: "2px 0",
};

interface LabelsConentListDialogProps {
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
  selectedLabel: ILabelDefinition;
  onSuccess: () => void;
  onFail: () => void;
}

const LabelsConentListDialog: React.FC<LabelsConentListDialogProps> = ({
  visible,
  onHide,
  refreshTable,
  selectedLabel,
  onSuccess,
  onFail,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [contentList, setContentList] = useState<IContentP[]>([]);
  const [isEnabled, setIsEnabled] = useState(true);

  const handleSubmit = () => {
    //console.log("contentList:", contentList);
    const contentIdList: number[] = [
      ...contentList.map((content) => content.id),
    ];

    reOrderList(selectedLabel.id, contentIdList).then((response) => {
      //console.log("getContentsOfLabel.response:", response);
      if (response.status === 200) {
        //onHide();?
        onSuccess();
        setIsEnabled(false);
      } else {
        //toastError(toastFor, "Content reordered succesfully.");
        setIsEnabled(true);
        onFail();
      }
    });
  };

  useEffect(() => {
    setContentList([]);
    setIsLoading(true);
    //console.log("selectedLabel:", selectedLabel);
    selectedLabel.id &&
      getContentsOfLabel(selectedLabel.id).then((response) => {
        //console.log("getContentsOfLabel.response:", response);
        if (response && response.length > 0) {
          setContentList(response);
        }

        if (response && response.length === 0) {
          //console.log("disabled Save button");
        } else {
          setIsLoading(false);
        }
      });
  }, [selectedLabel, visible]);

  const onDragStart = (event: React.DragEvent, contentP: IContentP) => {
    event.dataTransfer.setData("text/plain", contentP.id!.toString());
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = async (event: React.DragEvent, dropTargetItem: IContentP) => {
    event.preventDefault(); // Önceki koddan eksikti, önemli bir düzeltme
    const draggedItemId = event.dataTransfer.getData("text/plain");
    const draggedItem = contentList.find(
      (item) => item.id!.toString() === draggedItemId
    );

    if (draggedItem && dropTargetItem) {
      const dropTargetIndex = contentList.findIndex(
        (item) => item.id === dropTargetItem.id
      );
      const draggedItemIndex = contentList.findIndex(
        (item) => item.id === draggedItem.id
      );
      if (dropTargetIndex !== draggedItemIndex) {
        const newContentList = [...contentList];
        newContentList.splice(draggedItemIndex, 1);
        newContentList.splice(dropTargetIndex, 0, draggedItem);
        setContentList(newContentList);

        //console.log("newContentList:", contentList);
        // Call the update service with reordered list
        //await updateMainBoardItem(newContentList);
      }
    }
    setIsEnabled(true);
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "650px" }}
        header={t("Ordering Contents of '" + selectedLabel.name + "'")}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={handleSubmit}
          />
        }
        onHide={onHide}
      >
        <Row>
          <DataTable value={contentList} tableStyle={{ width: "100%" }}>
            <Column
              header="Order"
              body={(rowData: IContentP) => (
                <div
                  draggable
                  onDragStart={(e) => onDragStart(e, rowData)}
                  onDragOver={onDragOver}
                  onDrop={(e) => onDrop(e, rowData)}
                >
                  <div style={dragHandleContainerStyle}>
                    <div style={dragHandleLineStyle}></div>
                    <div style={dragHandleLineStyle}></div>
                    <div style={dragHandleLineStyle}></div>
                  </div>
                </div>
              )}
            />
            <Column
              field="status"
              header="Status"
              body={(rowData) => <>{rowData?.status}</>}
              sortable
            />
            <Column
              field="name"
              header="Name"
              body={(rowData) => <>{rowData?.name}</>}
              sortable
            />
          </DataTable>
        </Row>
      </Dialog>
    </>
  );
};

export default LabelsConentListDialog;
