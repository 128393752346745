import { deleteLicense } from "../../services/index";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../utils/index";

export const useDeleteLicenseData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: deleteLicenseIsLoading } = useMutation(
    async () => await deleteLicense(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("licenses");
        toastSuccess(toast, "License Deleted");
      },
      onError: () => {
        toastError(toast, "License not Deleted");
      },
    }
  );
  return {
    mutate,
    deleteLicenseIsLoading,
  };
};
