import { CategoryDataTable } from "../../components/index";

export default function CategoryManagement() {
  return (
    <div className="datatable-crud">
      <div className="card">
        <CategoryDataTable />
      </div>
    </div>
  );
}
