import { getSeries } from "../../services/index";
import { useQuery } from "react-query";

export const useGetSeriesData = (enabled?: boolean) => {
  const {
    data: series,
    isLoading: getSeriesIsLoading,
    refetch: refetchGetSeries,
  } = useQuery("series", getSeries, {
    enabled: false,
  });
  return { series, getSeriesIsLoading, refetchGetSeries };
};
