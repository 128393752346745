import React, { useEffect, useState } from "react";
import { getIdleInvoices } from "../../../services";
import InvoiceTable from "./InvoiceTable";
import { getFailedInvoices } from "../../../services";
import { Invoice } from "./types/Invoice";

const FailedInvoicesTable = () => {
  const [failedInvoices, setFailedInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    fetchFailedInvoices();
  }, []);

  const fetchFailedInvoices = async () => {
    try {
      const response = await getFailedInvoices(); // Assuming you have an API method for fetching the failed invoices
      setFailedInvoices(response);
    } catch (error) {
      console.error("Failed to fetch failed invoices:", error);
    }
  };

  const handleDetailsClick = () => {
    console.log("Details button clicked");
  };

  return (
    <div>
      <h4>Failed Invoices</h4>
      <InvoiceTable
        invoices={failedInvoices}
        onDetailsClick={handleDetailsClick}
      />
    </div>
  );
};

export default FailedInvoicesTable;
