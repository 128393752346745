import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { getWeeklyIncome } from "../../../services";
import { ProgressSpinner } from "primereact/progressspinner";

const IncomeAnalytics = () => {
  const [data, setData] = useState<Array<[string, number]> | null>(null);

  useEffect(() => {
    const fetchInvoicesCount = async () => {
      const response = await getWeeklyIncome();
      setData(response);
    };
    fetchInvoicesCount();
  }, []);

  const chartData = {
    labels: data?.map((d) => d[0]) || [],
    datasets: [
      {
        label: "Weekly Income in TL",
        backgroundColor: "#42A5F5",
        data: data?.map((d) => d[1]) || [],
      },
    ],
  };

  return (
    <div>
      {data ? (
        <Chart type="line" data={chartData} style={{ display: "initial" }} />
      ) : (
        <div
          //className="flex align-items-center"
          style={{ height: 100, width: "100%" }}
        >
          <ProgressSpinner style={{ width: "50px", height: "50px" }} />
        </div>
      )}
    </div>
  );
};

export default IncomeAnalytics;
