import React from "react";
import { useMenuToggle } from "../../hooks";
import classNames from "classnames";
import { IComponentProps } from "../../models";

interface AppWrapperProps extends IComponentProps {}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  const { menuIsActive } = useMenuToggle();

  const wrapperStyle = classNames("layout-wrapper layout-static", {
    "layout-static-sidebar-inactive": !menuIsActive,
  });

  return <div className={wrapperStyle}>{children}</div>;
};
export default AppWrapper;
