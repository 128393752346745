import { ILicense } from "../../models/index";
import { addLicense } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddLicenseData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addLicenseIsLoading } = useMutation(
    async (license: ILicense) => await addLicense(license),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("license");
        toastSuccess(toast, "License Added");
      },
      onError: () => {
        toastError(toast, "License not Added");
      },
    }
  );
  return { mutate, addLicenseIsLoading };
};
