import { getLicenses, getLicensesForList } from "../../services/index";
import { useQuery } from "react-query";

export const useGetLicensesDataForList = () => {
  const { data: licenses, isLoading: getLicensesIsLoading } = useQuery(
    "licenses",
    getLicensesForList
  );
  return { licenses, getLicensesIsLoading };
};
