import { getCustomerDetails } from "../../services/index";
import { useQuery } from "react-query";

export const useGetCustomerDetails = (id: number | null, enabled?: boolean) => {
  const {
    data: customerd,
    isLoading: getCustomerdIsLoading,
    refetch: refetchGetCustomerd,
  } = useQuery(["customerd", id], getCustomerDetails, {
    enabled,
  });
  return { customerd, getCustomerdIsLoading, refetchGetCustomerd };
};
