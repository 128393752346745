interface DashboardCardProps {
  title: string;
  count: string;
  iconBgColor: string;
  iconTextColor: string;
  icon: string;
  footerTitle: string;
  footerSubTitle: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  count,
  icon,
  iconBgColor,
  iconTextColor,
  footerTitle,
  footerSubTitle,
}) => {
  return (
    <div className="col-12 lg:col-6 xl:col-3">
      <div className="card mb-0">
        <div className="flex justify-content-between mb-3">
          <div>
            <span className="block text-500 font-medium mb-3">{title}</span>
            <div className="text-900 font-medium text-xl">{count}</div>
          </div>
          <div
            className={`flex align-items-center justify-content-center border-round ${iconBgColor}`}
            style={{ width: "2.5rem", height: "2.5rem" }}
          >
            <i className={`pi text-xl ${icon} ${iconTextColor}`} />
          </div>
        </div>
        <span className="text-green-500 font-medium">{footerTitle}</span>
        <span className="text-500">{footerSubTitle}</span>
      </div>
    </div>
  );
};

export default DashboardCard;
