import ProviderDataTable from "../../components/DataTable/license/ProviderDataTable/ProviderDataTable";
import { LicenseDataTable,ContractDataTable  } from "../../components";
import { TabMenu } from "primereact/tabmenu";
import { useState } from "react";

  const items = [
    {
      label: "License Management",
      icon: "pi pi-fw pi-home",
      value: <LicenseDataTable />,
    },
    {
      label: "Contract Management",
      icon: "pi pi-fw pi-calendar",
      value: <ContractDataTable/>,
    },
    {
      label: "Provider Management",
      icon: "pi pi-fw pi-pencil",
      value: <ProviderDataTable/>,
    },
];

const Licenses = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="datatable-crud">
      <div className="card">
        <TabMenu
          model={items}
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        />
      </div>
      <div className="card">
        {items[activeIndex].value}
      </div>
    </div>
  );
};

export default Licenses;
