import { getContentPosters } from "../../services/index";
import { useQuery } from "react-query";

export const useGetContentPostersData = (
  id: number | null,
  enabled?: boolean
) => {
  const {
    data: contentPosters,
    isLoading: getContentPostersIsLoading,
    refetch: refetchGetContentPostersData,
  } = useQuery(["contentPosters", id], getContentPosters, { enabled: false });
  return {
    contentPosters,
    getContentPostersIsLoading,
    refetchGetContentPostersData,
  };
};
