import { videoContentFilters } from "../../../../constants";
import { IVideoContent } from "@/models";
import { getContentsForPublish } from "../../../../services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { PublishDialog } from "../../../../components/Dialog";
import IVideoContentMinimize from "@/models/Entities/videoContent/IVideoContentMinimize";

const VideoPublishDataTable = () => {
  const toast = useRef<Toast>(null);
  const [selectedVideoContent, setSelectedVideoContent] =
    useState<IVideoContentMinimize | null>(null);
  const [filters, setFilters] = useState(videoContentFilters);

  const [videoContents, setVideoContents] = useState<IVideoContentMinimize[]>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    getContentsForPublish("READY_FOR_PUBLISH")
      .then((res) => {
        //console.log("getContentsForPublish.res:", res);
        setVideoContents(res);
      })
      .catch((err) => {
        //console.log("Error Text:", err);
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
  }, [refreshTable]);

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 className="mx-0 my-1">{"Ready For Publish Contents"}</h5>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const refreshContenList = () => {
    setRefreshTable(!refreshTable);
  };

  const header = renderHeader();

  const openPublishNowDialog = (content?: IVideoContentMinimize) => {
    content && setSelectedVideoContent(content);
    content && setShowPublishDialog(true);
  };

  const hidePublishNowDialog = () => {
    setShowPublishDialog(false);
  };

  const openScheduleDialog = (content?: IVideoContentMinimize) => {
    content && setSelectedVideoContent(content);
    setScheduleDialog(true);
  };

  return (
    <>
      <Toast ref={toast} />

      <DataTable
        value={videoContents}
        paginator
        rows={50}
        rowsPerPageOptions={[10, 50, 100]}
        dataKey="id"
        selection={selectedVideoContent}
        onSelectionChange={(e: any) => setSelectedVideoContent(e.value)}
        filters={filters}
        filterDisplay="row"
        responsiveLayout="scroll"
        globalFilterFields={["name"]}
        emptyMessage="No video contents found."
      >
        <Column
          field="id"
          header={"ID"}
          sortable
          style={{ maxWidth: "10rem", minWidth: "1.5rem" }}
        />
        <Column
          field="name"
          header={"Name"}
          filter
          filterPlaceholder="Search by name"
          sortable
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
        />
        <Column
          body={(rowData: IVideoContentMinimize) => (
            <>
              <Button
                icon="pi pi-sign-in"
                className="p-button-rounded p-button-outlined m-1"
                onClick={() => openPublishNowDialog(rowData)}
                style={{ width: "2rem", height: "2rem" }}
              />
            </>
          )}
          exportable={false}
          style={{ width: "10%" }}
        />
      </DataTable>
      <PublishDialog
        visible={showPublishDialog}
        content={selectedVideoContent}
        onHide={hidePublishNowDialog}
        refreshTable={refreshContenList}
      />
    </>
  );
};

export default VideoPublishDataTable;
