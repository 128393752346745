import { Dialog } from "primereact/dialog";
import React, { useState, useEffect, useRef } from "react";
import { ILicense, ILicensesAddOc, IVideoContent } from "@/models";

import { DropdownField } from "../../../index";

import {
  capitalizeFirstLetter,
  toastError,
  toastSuccess,
} from "../../../../utils/index";
import { useGetLicensesOfContentData } from "../../../../hooks/index";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Button } from "primereact/button";
import { addLicenseToContent } from "../../../../services";

interface AddRemoveLicenseToContentDialogProps {
  visible: boolean;
  content: IVideoContent;
  isLoading: boolean;
  licenses: ILicense[] | undefined;
  onHide: () => void;
}

const AddRemoveLicenseToContentDialog: React.FC<
  AddRemoveLicenseToContentDialogProps
> = ({ visible, content, isLoading, licenses, onHide }) => {
  //console.log(licenses);

  //console.log("content:", content);

  const toast = useRef<Toast>(null);

  //const { mutate: addLicenseToContent } = useAddLicenseToContent(toast);
  //const { mutate: removeLicenseFromContent } = useRemoveLicenseFromContent(toast);

  //console.log("content?.id:", content?.id);

  const { licensesOfContentData, getLicensesOfContentIsLoading } =
    useGetLicensesOfContentData(content?.id);

  //console.log("licensesOfContentData:", licensesOfContentData);

  const [selectedLicense, setSelectedLicense] = useState(0);

  const [addLicenseContent, setAddLicenseContent] = useState<ILicensesAddOc>({
    cId: 0,
    lcIds: [0],
    username: "manager",
    userID: 0,
  });

  const [isAddLoading, setIsAddLoading] = useState(false);

  useEffect(() => {
    //console.log("licensesOfContentData:", licensesOfContentData);
  }, [getLicensesOfContentIsLoading]);

  const hideDialog = () => {
    setSelectedLicense(-1);
    onHide();
  };

  const openDeleteDialog = (rowData: any) => {};

  const addLicense = (selectedLicense: number, contentId: number) => {
    //console.log("selectedLicense:", selectedLicense);
    //console.log("addLicenseContent:", addLicenseContent);
    try {
      // Servis çağrısını gerçekleştirin
      setIsAddLoading(true);
      addLicenseToContent(addLicenseContent).then((response) => {
        console.log("response:", response);
        if (response && response.status == 200) {
          console.log("data.code:", response.data.code);
          if (response.data.code === 200) {
            setIsAddLoading(false);
            toastSuccess(toast, "License Added");

            //setTimeout(() => {
            //  hideDialog();
            //}, 2000);
          } else {
            console.log("data.code-500:", response.data.code);
            toastError(toast, "Yetki eklenemiyor!");
            //toast?.current?.show({
            //  severity: "error",
            //  summary: "Hata!",
            //  detail: "Yetki eklenemiyor!",
            //});
            setIsAddLoading(false);
          }
        } else {
          toastError(toast, "Bir Sorun Oluştu.");
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsAddLoading(false);
    }
  };

  useEffect(() => {
    //selected alttaki listede varsa set etme.

    setAddLicenseContent({
      cId: content?.id,
      lcIds: [selectedLicense],
      username: "manager",
      userID: 0,
    });
  }, [selectedLicense]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "60%" }}
        header={"License Relations of " + content?.name}
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <div className="col-12" style={{ display: "flex" }}>
          <div className="col-6 xl:col-6">
            <DropdownField
              id="licenses"
              name="name"
              value={selectedLicense}
              onChange={(e) => setSelectedLicense(e.target.value)}
              options={licenses?.map((license) => ({
                value: license.id,
                label: license.name,
              }))}
              labelName={"Licenses"}
              labelText={capitalizeFirstLetter("Licenses")}
            />
            <Button
              label="Add"
              onClick={() => addLicense(selectedLicense, content?.id)}
              className="p-button-warning"
              disabled={selectedLicense == -1 || isAddLoading ? true : false}
            />
          </div>
        </div>

        <div className="col-12 xl:col-12">
          <div className="card">
            <h5>Licenses Of Content</h5>
            <DataTable
              value={licensesOfContentData}
              rows={5}
              paginator
              responsiveLayout="scroll"
            >
              <Column
                field="name"
                header="License Name"
                sortable
                style={{ width: "25%" }}
              />
              <Column
                field="startDate"
                header="Start Date"
                sortable
                style={{ width: "30%" }}
                body={(rowData) =>
                  rowData.startDate && (
                    <>{moment(rowData.startDate).format("DD/MM/YYYY")}</>
                  )
                }
              />
              <Column
                field="endDate"
                header="End Date"
                sortable
                style={{ width: "30%" }}
                body={(rowData) =>
                  rowData.startDate && (
                    <>{moment(rowData.startDate).format("DD/MM/YYYY")}</>
                  )
                }
              />
            </DataTable>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default AddRemoveLicenseToContentDialog;
