import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "../App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppProvider } from "../providers/AppProvider";

const queryClient = new QueryClient();

const AppRouter = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppProvider>
          <App />
        </AppProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export default AppRouter;
