import { getChilds } from "../../services/index";
import { useQuery } from "react-query";

export const useGetChildsData = (id: number | null, enabled?: boolean) => {
  const {
    data: childs,
    isLoading: getChildsIsLoading,
    refetch: refetchGetChilds,
  } = useQuery(["childs", id], getChilds, {
    enabled: false,
  });
  return { childs, getChildsIsLoading, refetchGetChilds };
};
