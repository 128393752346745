import { Button } from "primereact/button";
import React from "react";

interface DataTableDialogFooterProps {
  loading?: boolean;
  hideDialog: () => void;
  handleSubmit: () => void;
}

const DataTableDialogFooter: React.FC<DataTableDialogFooterProps> = ({
  loading,
  hideDialog,
  handleSubmit,
}) => {
  return (
    <>
      <Button
        label={"Close"}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label={"Save"}
        type="submit"
        icon="pi pi-check"
        className="p-button-text"
        loading={loading}
        onClick={handleSubmit}
      />
    </>
  );
};

export default DataTableDialogFooter;
