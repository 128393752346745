import { getCustomerCountByStatus } from "../../../services";
import { useState, useEffect } from "react";
import { ProgressBar } from "primereact/progressbar";
import { DashboardCard } from "../../index";

interface CustomerCountProp {
  status: string;
  title: string;
  color: string;
}

const CustomerCountComponent: React.FC<CustomerCountProp> = ({
  status,
  title,
  color,
}) => {
  const [customerCount, setCustomerCount] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCustomerCount = async () => {
      try {
        const response = await getCustomerCountByStatus(status);
        if (response.code == "200") {
          //console.log("responseCustomer.data: ", response.data);
          setCustomerCount(response.data);
        }
        //status, code, message, link, developerMessage, data

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching total income:", error);
        setIsLoading(false);
      }
    };
    fetchCustomerCount();
  }, []);

  return (
    <>
      {isLoading ? (
        <ProgressBar mode="indeterminate" />
      ) : (
        <>
          <DashboardCard
            title={title}
            count={customerCount}
            icon="pi-users"
            iconTextColor="text-blue-500"
            iconBgColor={"bg-" + color + "-100"}
            footerTitle=""
            footerSubTitle=""
          />
        </>
      )}
    </>
  );
};

export default CustomerCountComponent;
