import { useAppSelector } from "../store/hooks";
import {
  selectIsLoading,
  selectIsSuccess,
  selectUser,
} from "../store/slices/auth/authSlice";
import { useEffect, useMemo } from "react";

export const useAuth = () => {
  const user = useAppSelector(selectUser);
  const isSuccess = useAppSelector(selectIsSuccess);
  const isLoading = useAppSelector(selectIsLoading);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("isSuccess", JSON.stringify(isSuccess));
  }, [user, isSuccess]);

  return {
    loggedUser: useMemo(
      () => ({ user, isSuccess, isLoading }),
      [user, isSuccess, isLoading]
    ),
  };
};
