import React, { useState } from "react";
import ProviderDataTable from "../../components/DataTable/license/ProviderDataTable/ProviderDataTable";
import { LicenseDataTable } from "../../components";
import { TabMenu } from "primereact/tabmenu";
import AddVideoContentGroup from "./AddVideoContentGroup";
import { AddLicenseToContent } from "..";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

const AddToContent: React.FC = () => {
  const items = [
    {
      label: "License Management",
      icon: "pi pi-fw pi-home",
      value: <AddLicenseToContent />,
    },
    {
      label: "Content Groups",
      icon: "pi pi-fw pi-folder",
      value: <AddVideoContentGroup />,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const onHide = () => {
    navigate(-1);  // Go back to the previous page
  };

  return (
    <Dialog
      visible={true} // Always true because if this component is rendered, it's active
      style={{ width: "80%" }}
      header={"Add To Content"}
      modal
      className="p-fluid"
      onHide={onHide}
    >
      <div className="datatable-crud">
        <div className="card">
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          />
        </div>
        <div className="card">
          {items[activeIndex].value}
        </div>
      </div>
    </Dialog>
  );
};

export default AddToContent;
