import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface ConfirmationDialogProps {
  visible: boolean;
  data: any;
  onHide: () => void;
  onDelete: (id: number) => void;
  message: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  visible,
  data,
  onHide,
  onDelete,
  message,
}) => {
  const deleteDialogFooter = (
    <>
      <Button
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
        style={{
          width: "40px",
          backgroundColor: "transparent",
          border: "solid 1px #d4d4e0",
          color: "#d4d4e0",
        }}
      />
      <Button
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => onDelete(data.id)}
        style={{
          width: "40px",
          backgroundColor: "transparent",
          border: "solid 1px #2aad33",
          color: "#b5b5ba",
        }}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header="Confirm"
      modal
      footer={deleteDialogFooter}
      onHide={onHide}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {message && <span>{message}</span>}
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
