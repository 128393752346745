import { ErrorField, InputField, DataTableDialogFooter } from "../../../index";
import { IContract } from "../../../../models/index";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
} from "../../../../utils/index";
// import { productSchema } from "../../../../validations/index";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";

interface ContractDialogProps {
  visible: boolean;
  contract: IContract;
  isLoading: boolean;
  onHide: () => void;
  addContract: (contract: IContract) => void;
}

const ContractDialog: React.FC<ContractDialogProps> = ({
  visible,
  contract,
  isLoading,
  onHide,
  addContract,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: contract,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      addContract(data);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={t("Contract Details")}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={onHide}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Name"
          labelText={capitalizeFirstLetter("Name")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Name" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Name"),
          })}
        />
        <InputField
          id="startDate"
          value={formik.values.startDate?.toString() ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="startDate"
          labelText={capitalizeFirstLetter("startDate")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="startDate" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "startDate"),
          })}
        />
        <InputField
          id="endDate"
          value={formik.values.endDate?.toString() ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="End Date"
          labelText={capitalizeFirstLetter("End Date")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="End Date" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "End Date"),
          })}
        />
        <InputField
          id="signDate"
          value={formik.values.signDate?.toString() ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Sign Date"
          labelText={capitalizeFirstLetter("Sign Date")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Sign Date" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Sign Date"),
          })}
        />
        <InputField
          id="type"
          value={formik.values.type ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Type"
          labelText={capitalizeFirstLetter("Type")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Type" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Type"),
          })}
        />
        <InputField
          id="providerName"
          value={formik.values.providerName ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Provider Name"
          labelText={capitalizeFirstLetter("Provider Name")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Provider Name" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Provider Name"),
          })}
        />
      </form>
    </Dialog>
  );
};

export default ContractDialog;
