import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILabelDefinition } from "../../../../models";
import { ConfirmationDialog, DataTableDialogFooter } from "../../../index";
import {
  getAllContentsOfLabel,
  removeContentFromLabel,
  removeContentFromLabelSingle,
} from "../../../../services";
import { Row } from "react-bootstrap";
import { IContentP } from "../../../../models";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AddContentToLabelDialog from "./AddContentToLabelDialog";

interface AddRemoveConentsInLabelDialogProps {
  visible: boolean;
  onHide: () => void;
  selectedLabel: ILabelDefinition;
  onSuccess: () => void;
  onFail: () => void;
}

const AddRemoveConentsInLabelDialog: React.FC<
  AddRemoveConentsInLabelDialogProps
> = ({ visible, onHide, selectedLabel, onSuccess, onFail }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [contentList, setContentList] = useState<IContentP[]>([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [showRemoveWarning, setShowRemoveWarning] = useState(false);
  const [selectedContent, setSelectedContent] = useState<IContentP>();
  const [refreshTable, setRefreshTable] = useState(false);
  const [showAddContentDialog, setShowAddContentDialog] = useState(false);

  const handleSubmit = () => {
    //console.log("contentList:", contentList);
  };

  useEffect(() => {
    //setContentList([]);
    setIsLoading(true);
    //console.log("selectedLabel:", selectedLabel);
    try {
      selectedLabel.id &&
        getAllContentsOfLabel(selectedLabel.id).then((response) => {
          //console.log("getAllContentsOfLabel.response:", response);
          if (response) {
            setContentList(response);
            setIsLoading(false);
          }
        });
    } catch (error) {}
  }, [selectedLabel, refreshTable, visible, showAddContentDialog]);

  //const openRemoveContentDialog = (e: any) => {
  //  setSelectedContent(e);
  //  console.log("e:", e);
  //};

  const openRemoveContentDialog = (rowData: any) => {
    rowData && setSelectedContent(rowData);
    setShowRemoveWarning(true);
  };

  const hideRemoveContentDialog = () => {
    setShowRemoveWarning(false);
  };

  const removeContent = (e: any) => {
    const contentIdList: number[] = [];
    selectedContent && contentIdList.push(selectedContent?.id);

    //console.log("selectedContent:", selectedContent);
    removeContentFromLabelSingle(selectedLabel.id, selectedContent?.id).then(
      (response) => {
        //console.log("removeContentFromLabel.response:", response);
        setRefreshTable(!refreshTable);
      }
    );
    //console.log("contentIdList:", contentIdList);
    setShowRemoveWarning(false);
  };

  const openAddContentDialog = () => {
    setShowAddContentDialog(true);
  };

  const hideAddContentDialog = () => {
    setShowAddContentDialog(false);
  };

  const refreshtContentTable = () => {
    setRefreshTable(!refreshTable);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Contents Of Label</span>
      <Button icon="pi pi-refresh" onClick={refreshtContentTable} />
    </div>
  );

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "550px" }}
        header={t("Add Remove Contents for '" + selectedLabel.name + "'")}
        modal
        className="p-fluid"
        footer={
          <Button
            label={"Close"}
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
          />
        }
        onHide={onHide}
      >
        <Row style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button
            label={"New"}
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openAddContentDialog}
            style={{ width: "25%" }}
          />
        </Row>
        <Row>
          <DataTable
            value={contentList}
            tableStyle={{ width: "100%" }}
            header={header}
          >
            <Column
              field="name"
              header="Name"
              body={(rowData) => <>{rowData?.name}</>}
              sortable
            />
            <Column
              field="status"
              header="Status"
              body={(rowData) => <>{rowData?.status}</>}
              sortable
            />

            <Column
              style={{ textAlign: "right" }}
              header=""
              body={(rowData: ILabelDefinition) => (
                <>
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-secondary m-1"
                    onClick={() => openRemoveContentDialog(rowData)}
                    style={{
                      backgroundColor: "#f44336b5",
                      width: "25px",
                      height: "25px",
                      borderColor: "#f44336b5",
                    }}
                  ></Button>
                </>
              )}
            />
          </DataTable>
        </Row>
      </Dialog>
      <ConfirmationDialog
        visible={showRemoveWarning}
        data={selectedContent}
        onHide={hideRemoveContentDialog}
        onDelete={removeContent}
        message="Are you sure to remove the content from label ?"
      />
      <AddContentToLabelDialog
        visible={showAddContentDialog}
        onHide={hideAddContentDialog}
        refreshTable={refreshtContentTable}
        seletedLabel={selectedLabel}
      />
    </>
  );
};

export default AddRemoveConentsInLabelDialog;
