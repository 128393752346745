import React from "react";

interface SidebarLayoutProps {
  children: React.ReactNode;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
  return <div className="layout-sidebar">{children}</div>;
};

export default SidebarLayout;
