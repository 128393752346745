import { ILicense, IReleaseDate, IVideoContent } from "@/models";
import { deleteSchdulesOfContent } from "../../../../services";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";

interface SchedulesOfContentProps {
  schdules: IReleaseDate[] | undefined;
  refreshSchedules: () => void;
}

const SchedulesOfContent: React.FC<SchedulesOfContentProps> = ({
  schdules,
  refreshSchedules,
}) => {
  function deleteSchedule(rowData: IVideoContent): void {
    deleteSchdulesOfContent(rowData.id).then((res) => {
      console.log("deleteSchdulesOfContent.res:", res);
      refreshSchedules();
    });
  }

  return (
    <>
      <DataTable
        value={schdules}
        dataKey="id"
        emptyMessage="No schdules found."
        header={"Schdules of Content"}
      >
        <Column
          field="id"
          header={"ID"}
          style={{ maxWidth: "10rem", minWidth: "1.5rem" }}
        />
        <Column
          field="releaseDate"
          header={"Release Date"}
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
          body={(rowData) =>
            rowData.releaseDate && (
              <>{moment(rowData.releaseDate).format("DD/MM/YYYY")}</>
            )
          }
        />
        <Column
          field="outReleaseDate"
          header={"Release End Date"}
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
          body={(rowData) =>
            rowData.outReleaseDate && (
              <>{moment(rowData.outReleaseDate).format("DD/MM/YYYY")}</>
            )
          }
        />
        <Column
          body={(rowData: IVideoContent) => (
            <>
              <Button
                icon="p-button-icon p-c pi pi-times"
                className="p-button p-component p-button-rounded p-button-danger p-button-outlined p-button-icon-only"
                onClick={() => deleteSchedule(rowData)}
                style={{ width: "2rem", height: "2rem" }}
              />
            </>
          )}
          exportable={false}
          style={{ width: "10%" }}
        />
      </DataTable>
    </>
  );
};

export default SchedulesOfContent;
