import { getLicenses } from "../../services/index";
import { useQuery } from "react-query";

export const useGetLicensesData = () => {
  const { data: licenses, isLoading: getLicensesIsLoading } = useQuery(
    "licenses",
    getLicenses
  );
  return { licenses, getLicensesIsLoading };
};
