import { IVideoContent } from "@/models";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { videoContentSchema } from "../../../../validations/index";

import { InputField, ErrorField, DataTableDialogFooter } from "../../../index";
import {
  isFormFieldValid,
  capitalizeFirstLetter,
  toastSuccess,
  toastError,
} from "../../../../utils/index";
import { ToggleButton } from "primereact/togglebutton";
import { initialCategory } from "../../../../constants";
import { ICategory } from "@/models/Entities/category";
import { getCategory, updateCategory } from "../../../../services";
import { Toast } from "primereact/toast";

interface EditCategoryDialogProps {
  visible: boolean;
  selectedCategory: ICategory;
  onHide: () => void;
  refreshTable: () => void;
}

const EditCategoryDialog: React.FC<EditCategoryDialogProps> = ({
  visible,
  selectedCategory,
  onHide,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);

  const formik = useFormik({
    initialValues: selectedCategory,
    enableReinitialize: true,
    onSubmit: (data) => {
      editCategory(data);
      formik.resetForm();
      refreshTable();
      hideDialog();
    },
  });

  const editCategory = (editCategoryData: ICategory) => {
    try {
      updateCategory(editCategoryData).then((response) => {
        //console.log("getCategories.response:", response);
        if (response) {
          if (response.status === 200) {
            toastSuccess(toast, "Category updated. " + editCategory.name);
          } else {
            toastError(toast, "Update Failed.");
          }
        }
      });
    } catch (error) {
      console.log("Error:", error);
      toastError(toast, "Update Failed." + error);
    }
  };

  const hideDialog = () => {
    onHide();
    formik.resetForm();
  };

  const handleMenuVisibilityChange = (e: any) => {
    formik.values.menuVisibility = !formik.values.menuVisibility;
    formik.handleChange(e);
    refreshTable();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={"Edit Category"}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={false}
          hideDialog={hideDialog}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={hideDialog}
    >
      <Toast ref={toast} />
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Category Name"
          labelText={capitalizeFirstLetter("Category Name")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Category Name" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Category Name"),
          })}
        />
        <ToggleButton
          id="screenVisibility"
          onLabel="Screen Visibility"
          offLabel="Screen Visibility"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={formik.values.menuVisibility}
          onChange={handleMenuVisibilityChange}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        />
      </form>
    </Dialog>
  );
};

export default EditCategoryDialog;
