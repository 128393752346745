import {
  ErrorField,
  InputField,
  DataTableDialogFooter,
  InputMaskField,
} from "../../../index";
import { IProvider } from "../../../../models/index";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
} from "../../../../utils/index";
// import { productSchema } from "../../../../validations/index";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";

interface ProviderDialogProps {
  visible: boolean;
  provider: IProvider;
  isLoading: boolean;
  onHide: () => void;
  addProvider: (provider: IProvider) => void;
}

const ProviderDialog: React.FC<ProviderDialogProps> = ({
  visible,
  provider,
  isLoading,
  onHide,
  addProvider,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: provider,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      addProvider(data);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={t("Provider Details")}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={onHide}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Name"
          labelText={capitalizeFirstLetter("Name")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Name" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Name"),
          })}
        />
        <InputField
          id="shortName"
          value={formik.values.shortName ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Short Name"
          labelText={capitalizeFirstLetter("Short Name")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Short Name" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Short Name"),
          })}
        />
        <InputField
          id="externalId"
          value={formik.values.externalId ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="External Id"
          labelText={capitalizeFirstLetter("External Id")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="External Id" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "External Id"),
          })}
        />
        <InputField
          id="country"
          value={formik.values.country ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Country"
          labelText={capitalizeFirstLetter("Country")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Country" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Country"),
          })}
        />
        <InputField
          id="contactPerson"
          value={formik.values.contactPerson ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Contact Person"
          labelText={capitalizeFirstLetter("Contact Person")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Contact Person" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Contact Person"),
          })}
        />
        <InputMaskField
          id="gsmno"
          value={formik.values.gsmno ?? ""}
          type="Gsm No"
          mask="(999) 999 99 99"
          required
          onChange={formik.handleChange}
          labelName="Gsm No"
          labelText={capitalizeFirstLetter("Gsm No")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Gsm No" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Gsm No"),
          })}
        />
      </form>
    </Dialog>
  );
};

export default ProviderDialog;
