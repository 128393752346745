import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuContainer, SidebarLayout } from "../../layouts";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import useAxiosInterceptor from "../../api/axios.instance";
import { menuItems } from "../../constants/menu/menuItems";
import "../../assets/layout/layout.scss";
import { useAuth } from "../../hooks";

type OpenTabs = {
  [key: string]: boolean;
};

const AppMenu = () => {
  useAxiosInterceptor();

  const [openTabs, setOpenTabs] = useState<OpenTabs>({});

  const toggleTab = (label: string) => {
    setOpenTabs((prevOpenTabs) => ({
      ...prevOpenTabs,
      [label]: !prevOpenTabs[label],
    }));
  };

  const {
    loggedUser: { user, isLoading },
  } = useAuth();

  const animationDuration = 200;

  return (
    <SidebarLayout>
      <MenuContainer>
        <ul className="layout-menu" role="menu">
          {menuItems.map(
            (menuSection) =>
              user?.role &&
              menuSection.roles?.includes(user?.role) && (
                <li
                  key={menuSection.label}
                  className={classNames("layout-menuitem", {
                    "submenu-open": openTabs[menuSection.label],
                  })}
                  role="none"
                >
                  <div
                    className="submenu-button"
                    onClick={() => toggleTab(menuSection.label)}
                  >
                    {menuSection.label}
                    <span className="submenu-icon">
                      <i
                        className={classNames("pi", {
                          "pi-chevron-right": !openTabs[menuSection.label],
                          "pi-chevron-down": openTabs[menuSection.label],
                        })}
                      ></i>
                    </span>
                  </div>
                  <CSSTransition
                    classNames="layout-submenu-wrapper"
                    timeout={animationDuration}
                    in={openTabs[menuSection.label]}
                    unmountOnExit
                  >
                    <ul className="submenu" role="menu">
                      {menuSection.items.map(
                        (item) =>
                          user?.role &&
                          item.roles?.includes(user?.role) && (
                            <li key={item.label} role="none">
                              <NavLink
                                aria-label={item.label}
                                role="menuitem"
                                to={item.to}
                              >
                                <i className={item.icon}></i> {item.label}
                              </NavLink>
                            </li>
                          )
                      )}
                    </ul>
                  </CSSTransition>
                </li>
              )
          )}
        </ul>
      </MenuContainer>
    </SidebarLayout>
  );
};

export default AppMenu;
