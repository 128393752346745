import { ICustomer } from "../../models/index";
import { addCustomer } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddCustomerData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addCustomerIsLoading } = useMutation(
    async (customer: ICustomer) => await addCustomer(customer),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
        toastSuccess(toast, "Customer Added");
      },
      onError: () => {
        toastError(toast, "Customer not Added");
      },
    }
  );
  return {
    mutate,
    addCustomerIsLoading,
  };
};
