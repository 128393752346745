import { IFieldProps } from "@/models";
import { Chips, ChipsProps } from "primereact/chips";
import React from "react";
import { useTranslation } from "react-i18next";

interface ChipsFieldProps extends ChipsProps, IFieldProps {}

const ChipsField: React.FC<ChipsFieldProps> = ({
  getFormErrorMessage,
  labelName,
  labelText,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className="field">
      <label htmlFor={labelName}>{t(labelText)}</label>
      <Chips {...rest} />
      {getFormErrorMessage != null && getFormErrorMessage()}
    </div>
  );
};

export default ChipsField;
