import { IVideoContent } from "../../../models";
import IVideoContentMinimize from "../../../models/Entities/videoContent/IVideoContentMinimize";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../../utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataTableStatusTemplate } from "../../../components/DataTable";
import { Button } from "primereact/button";
import {
  addMainBoardItem,
  getContentsForMainBoardsItems,
} from "../../..//services";
import { Image } from "primereact/image";
import NoImage from "../../../assets/images/no-image.png";

interface AddMBIDialogProps {
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
  categoryId: number | null;
}

const AddMBIDialog: React.FC<AddMBIDialogProps> = ({
  visible,
  onHide,
  refreshTable,
  categoryId,
}) => {
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContentForAdd, setSelectedContentForAdd] =
    useState<IVideoContentMinimize | null>(null);

  const [contentList, setContentList] = useState<
    IVideoContentMinimize[] | undefined
  >([]);
  const [listChanged, setListChanged] = useState(false);

  useEffect(() => {
    //console.log("seletedLabel:", seletedLabel);
    try {
      getContentsForMainBoardsItems().then((response) => {
        //console.log("getAllContentsNotOfLabel.response:", response);
        if (response && response.length > 0) {
          setContentList(response);
        }
      });
    } catch (error) {}
  }, [visible, listChanged]);

  useEffect(() => {
    //console.log("seletedLabel:", seletedLabel);
    if (selectedContentForAdd) {
      try {
        addMainBoardItem(selectedContentForAdd?.id, categoryId).then(
          (response) => {
            console.log("addMainBoardItem.response:", response);

            setSelectedContentForAdd(null);
            setListChanged(!listChanged);
            refreshTable();
            showSuccess();
          }
        );
      } catch (error) {
        showFail(null);
      }
    }
  }, [selectedContentForAdd]);

  const addToMbi = (rowData: IVideoContentMinimize) => {
    setSelectedContentForAdd(rowData);
  };

  const onHideLocal = () => {
    setSelectedContentForAdd(null);
    setContentList([]);
    refreshTable();
    onHide();
  };

  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  const showFail = (message: string | null) => {
    toastError(toast, message ? message : "Operation failed.");
  };

  const imageBodyTemplate = (rowData: IVideoContent) => {
    return (
      <Image
        src={`${rowData.catalogPosterUrl}`}
        alt="videoContent"
        height="80"
        width="80"
        preview
        className="videoContent-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = NoImage;
        }}
      />
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "50%" }}
        header={"Add Content To MBI List"}
        modal
        className="p-fluid"
        footer={
          <Button
            label={"Close"}
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHideLocal}
          />
        }
        onHide={onHide}
      >
        <div className="">
          <DataTable
            value={contentList}
            responsiveLayout="scroll"
            paginator
            rows={15}
            rowsPerPageOptions={[5, 10, 25, 50]}
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            filterDisplay="row"
          >
            <Column
              field="posterUrl"
              header={"Poster"}
              body={imageBodyTemplate}
            />
            <Column field="id" header="id"></Column>
            <Column
              field="name"
              header="Name"
              filter
              filterPlaceholder="Search by name"
            ></Column>

            <Column
              body={(rowData: IVideoContentMinimize) => (
                <>
                  <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-outlined m-1"
                    onClick={() => addToMbi(rowData)}
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </>
              )}
              exportable={false}
              style={{ width: "10%" }}
            />
          </DataTable>
        </div>
      </Dialog>
    </>
  );
};

export default AddMBIDialog;
