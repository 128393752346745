import { IComponentProps } from "../../models/index";
import React from "react";

interface MainContainerProps extends IComponentProps {}

const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
 return <div className="layout-main-container">{children}</div>;
};

export default MainContainer;
