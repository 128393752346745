import { TabMenu } from "primereact/tabmenu";
import {
  AllSubscriptions,
  DailyEndedDataTable,
  DailyStartedDataTable,
  DailyRenewSubscriptions,
  ExpiredRenewal,
  ExpiredEnded,
} from "../../components/DataTable/subscriptions/index";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";

export default function Subscriptions() {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      label: "Daily Data",
      icon: "pi pi-fw pi-home",
      value: (
        <>
          <div className="card">
            <DailyEndedDataTable />
          </div>
          <div className="card">
            <DailyStartedDataTable />
          </div>
          <div className="card">
            <ExpiredRenewal />
          </div>
          <div className="card">
            <ExpiredEnded />
          </div>
          <div className="card">
            <DailyRenewSubscriptions />
          </div>
        </>
      ),
    },
    {
      label: "All Subscriptions",
      icon: "pi pi-fw pi-calendar",
      value: (
        <>
          <div className="">
            <AllSubscriptions />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="datatable-crud">
        <div className="card">
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          />
          <div className="" style={{ paddingTop: "10px" }}>
            {items[activeIndex].value}
          </div>
        </div>
      </div>
    </>
  );
}
