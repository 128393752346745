import React, { useState, useEffect, useRef } from "react";
import "./style.css";
/**
 * Component consuming the VdocipherAPI
 */

const VideoLayer = ({
  videoRef,
  isAPIReady,
  isVisible,
  setVisible,
  style,
  content,
}) => {
  const [status, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) {
      // @todo detach from the API
      setPlayer(null);
      return;
    }
    const player = new window.VdoPlayer(videoRef);
    window.player = player;
    setPlayer(player);
    // player.video.play();
    window.player = player;
  }, [isAPIReady, videoRef]);

  const onPress = async () => {
    if (player.video.paused) {
      player.video.play();
    } else {
      player.video.pause();
      setVisible(false);
    }
  };

  const onSkipIntro = () => {
    player.video.currentTime = content.introDuration;
  };

  const handleForwards = () => {
    player.video.currentTime = player.video.currentTime + 10;
  };

  const handleBackwards = () => {
    player.video.currentTime = player.video.currentTime - 10;
  };

  /*
  const getContentBookmark = async () => {
    if (!watchingContent || !watchingContent.bookmarkTime)
      player.video.currentTime = 0;
    player.video.currentTime = watchingContent.bookmarkTime;
  };

  const handleBookmark = async () => {
    await addBookmark(
      watchingContent.id,
      player.video.currentTime,
      watchingContent.duration
    );
  };

  

  const isTitleVisible = player && player.video && isVisible;
  const isTVSeries = content.seasons && content.seasons.length;
  const isSkipButtonAvailable = !!content.introDuration;
  const isSkipButtonShown =
    status === "Playing" && content.introDuration > currentTime;
*/
  return <></>;
};

export default VideoLayer;

const styles = {
  title: {
    color: "white",
    marginLeft: "74px",
    marginTop: "44px",
  },
  info: {
    color: "white",
    marginLeft: "74px",
    marginTop: "8px",
  },
  nextEpisode: {
    borderRadius: "15px",
    backgroundColor: "white",
    color: "black",
    width: "160px",
    position: "absolute",
    bottom: "100px",
    right: "5%",
    zIndex: 20,
  },
};
