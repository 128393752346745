import { ILicensesAddOc, IVideoContent } from "../../models/index";
import {
  addLicenseToContent,
  removeContentFromLicense,
} from "../../services/index"; // Assuming the functions are in services/index
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddLicenseToContent = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addLicenseToContentIsLoading } = useMutation(
    async (licenseContent: ILicensesAddOc) =>
      await addLicenseToContent(licenseContent),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("licenses"); // Adjust to your query key if it's different
        queryClient.invalidateQueries("content");
        toastSuccess(toast, "License Added");
      },
      onError: () => {
        toastError(toast, "License not Added");
      },
    }
  );
  return { mutate, addLicenseToContent };
};
