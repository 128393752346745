import { Row } from "react-bootstrap";
import { IDailySubscriptionsData } from "../../../../models";
import {
  getAllSubscriptions,
  getDailyEndedSubscriptions,
  getDailyRenew,
} from "../../../../services";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import ISubscription from "../../../../models/Entities/customer/ISubscription";
import moment from "moment";
import { DataTableStatusTemplate } from "../../common";

const DailyRenewSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(true);

  useEffect(() => {
    try {
      getDailyRenew().then((response) => {
        //console.log("getAllSubscriptions.response.data:", response.data);
        if (response && response.data) {
          setSubscriptions(response.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  const triggerRefresh = () => {
    setRefreshList(!refreshList);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">
        Today Renewal Subscriptions
      </span>
      <Button icon="pi pi-refresh" onClick={triggerRefresh} />
    </div>
  );
  const footer = `In total there are ${
    subscriptions ? subscriptions.length : 0
  } record.`;

  return (
    <>
      <Row>
        <DataTable
          value={subscriptions}
          tableStyle={{ width: "100%" }}
          header={header}
          footer={footer}
        >
          <Column
            field="subId"
            header="ID"
            body={(rowData) => <>{rowData?.id}</>}
            sortable
            style={{ width: "5%" }}
          />
          <Column
            field="subRDate"
            header="Sub RenewalDate"
            body={(rowData) => (
              <>
                {rowData.renewalDate &&
                  moment(rowData.renewalDate).format("DD/MM/YYYY")}
              </>
            )}
            sortable
            style={{ width: "20%" }}
          />

          <Column
            field="custEmail"
            header="Email"
            body={(rowData) => <>{rowData?.customer.email}</>}
            sortable
            style={{ width: "30%" }}
          />
          <Column
            field="productName"
            header="Product"
            body={(rowData) => <>{rowData?.productP.name}</>}
            sortable
            style={{ width: "25%" }}
          />
          <Column
            field="channel"
            header="Channel"
            body={(rowData) => <>{rowData?.channel}</>}
            sortable
          />
        </DataTable>
      </Row>
    </>
  );
};

export default DailyRenewSubscriptions;
