import { IProvider } from "../../models/Entities/provider/index";
import { axiosInstance } from "../../api/axios.instance";

export const getProviders = async (): Promise<IProvider[]> =>
  await axiosInstance
    .get(`/contman/provider/getall`)
    .then((res) => res.data.data);

export const addProvider = async (provider: IProvider) =>
  await axiosInstance.post(`/contman/provider/create`, { providerP: provider });

export const updateProvider = async (provider: IProvider) =>
  await axiosInstance.put(`/contman/provider/edit`, { providerP: provider });

export const deleteProvider = async () =>
  await axiosInstance.delete(`/contman/provider/delete`);
