import { deleteCustomer } from "../../services/index";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../utils/index";

export const useDeleteCustomerData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: deleteCustomerIsLoading } = useMutation(
    async (customerId: number) => await deleteCustomer(customerId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
        toastSuccess(toast, "Customer Deleted");
      },
      onError: () => {
        toastError(toast, "Customer not Deleted");
      },
    }
  );
  return {
    mutate,
    deleteCustomerIsLoading,
  };
};
