export const genres = [
  ["Yemek"],
  ["Sağlık"],
  ["Magazin"],
  ["Gezi"],
  ["Moda"],
  ["Mini Program"],
  ["Mini Belgesel"],
  ["Makyaj"],
  ["Yaşam"],
  ["Tarih"],
  ["Belgesel"],
  ["Elektronik Müzik"],
  ["Hip-Hop"],
  ["Pop"],
  ["Caz"],
  ["Blues"],
  ["Rock"],
  ["Biyografi"],
  ["Radyo Tiyatrosu"],
  ["Trajedi&Dram"],
  ["Komedi"],
  ["Müzikal&Kabare"],
  ["Gösteri"],
];

export const definitions = [
  ["4K"],
  ["Waiting"],
  ["FULL HD 1080P"],
  ["HD 1080P"],
  ["HD 720P"],
  ["SD"],
];

export const advisories = [["VH"], ["SC"], ["IB"]];

export const ratings = [
  "G",
  "7+",
  "13+",
  "18+",
  "Sexuality",
  "Negative Behavior",
];

export const contentTypes = ["MOVIE", "SERIES", "SEASON", "EPISODE"];

export const externalStatuses = ["Ready", "Waiting"];

export const statusOptions = [
  "WAITING_FOR_MATERIALS",
  "RAW_CONTENT",
  "IN_PROGRESS",
  "READY_FOR_PUBLISH",
  "PUBLISHED",
  "EXPIRED",
];