import IVideoContentMinimize from "@/models/Entities/videoContent/IVideoContentMinimize";
import { axiosInstance } from "../../api/axios.instance";
import { IAllMainboardItems, IMainboardItems } from "../../models/index";

export const getAllMainboardItems = async (): Promise<IAllMainboardItems[]> =>
  await axiosInstance
    .get(`/contman/mainboarditem/getall`)
    .then((res) => res.data.data);

export const updateMainBoardItem = async (
  allMainBoardItems: IAllMainboardItems[]
) =>
  await axiosInstance.post(
    `contman/mainboarditem/bulkpriorityupdate`,
    allMainBoardItems
  );

export const getMainBoardsItems = async (): Promise<IMainboardItems[]> =>
  await axiosInstance
    .get("/contman/content/formbi")
    .then((res) => res.data.data);

export const getContentsForMainBoardsItems = async (): Promise<
  IVideoContentMinimize[]
> =>
  await axiosInstance
    .get("/contman/content/formbi")
    .then((res) => res.data.data);

export const addMainBoardItem = async (
  contentId: number | undefined,
  categoryId: number | null
) => {
  const userID = 0;
  const username = "operator";

  // Yeni bir nesne oluştur ve istediğin formata yerleştir
  const postData = {
    mainBoardItemP: {
      content: {
        id: contentId,
      },
    },
    categoryId: categoryId,
    contentId: contentId,
    userID: userID,
    username: username,
  };

  await axiosInstance
    .post(`/contman/mainboarditem/v2/create`, postData)
    .then((response) => {
      //console.log("response--:", response);
      return response.data;
    });
};

export const deleteMainBoardItem = async (mbiId: number | null | undefined) =>
  await axiosInstance.delete(`/contman/mainboarditem/${mbiId}`);
