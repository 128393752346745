import { log } from "console";
import { axiosInstance } from "../../api/axios.instance";

type StateAnalyticsData =
  | [string, [string, number], [string, number], [string, number]]
  | [string];

export const getCustomerAnalyticsByState = async (): Promise<
  StateAnalyticsData[]
> => {
  const response = await axiosInstance.get(
    "/crm-core/analytics/customerByState"
  );
  return response.data;
};

export const getSubscriptionAnalyticsByState = async (): Promise<
  StateAnalyticsData[]
> => {
  const response = await axiosInstance.get(
    "/crm-core/analytics/subscriptionByState"
  );
  return response.data;
};

export const getFailedPayments = async () => {
  const response = await axiosInstance.get("/crm-core/payment/failedPayments");
  return response.data;
};

export const getIdleInvoices = async () => {
  const response = await axiosInstance.get("/crm-core/payment/idleInvoices");
  return response.data;
};

export const getFailedInvoices = async () => {
  const response = await axiosInstance.get("/crm-core/payment/failedInvoices");
  return response.data;
};

export const getInvoicesCount = async () => {
  const response = await axiosInstance.get(
    "/crm-core/payment/failedInvoiceCount"
  );
  return response.data;
};

export const getWeeklyIncome = async () => {
  const response = await axiosInstance.get(
    "/crm-core/analytics/incomeLastWeek"
  );
  return response.data;
};

export const getTotalIncome = async () => {
  const response = await axiosInstance.get("/crm-core/analytics/totalIncome");
  return response.data;
};

export const getCustomerCountByStatus = async (status: string) => {
  const response = await axiosInstance.get(
    "/crm-core/customer/countbystatus/" + status
  );
  return response.data;
};
