import classNames from "classnames";
import { ErrorField, InputField } from "../../components/index";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
  toastError,
} from "../../utils/index";
import { loginSchema } from "../../validations/index";
import { initialLogin } from "../../constants/index";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { login } from "../../store/slices/auth/authSlice";
import { forceLogin } from "../../store/slices/auth/authSlice";
import { useEffect, useRef, useState } from "react";
import { selectIsActiveSession } from "./../../store/slices/auth/authSlice";
import { Toast } from "primereact/toast";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [type, setType] = useState("");

  const isActiveSession = useAppSelector(selectIsActiveSession);

  const toast = useRef<Toast>(null);

  const formik = useFormik({
    initialValues: initialLogin,
    validationSchema: loginSchema,

    onSubmit: ({ username, password }) => {
      type === "login"
        ? dispatch(login({ username, password }))
        : dispatch(forceLogin({ username, password }));

      navigate("/");
    },
  });

  const handleClick = (type: string) => {
    setType(type);
    formik.handleSubmit();
  };

  useEffect(() => {
    isActiveSession && toastError(toast, isActiveSession);
  }, []);

  useEffect(() => {
    isActiveSession && toastError(toast, isActiveSession);
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card">
          <form className="p-fluid">
            <InputField
              id="username"
              key="username"
              value={formik.values.username}
              type="text"
              autoFocus={true}
              onChange={formik.handleChange}
              labelName="Username"
              labelText={capitalizeFirstLetter("username")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"username"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "username"),
              })}
            />
            <InputField
              id="password"
              key="password"
              value={formik.values.password}
              type="password"
              autoFocus={false}
              onChange={formik.handleChange}
              labelName="Password"
              labelText={capitalizeFirstLetter("password")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"password"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "password"),
              })}
            />
            <Button
              type="submit"
              onClick={() => handleClick("login")}
              label="Login"
              className="mt-2"
            />
            <Button
              type="submit"
              onClick={() => handleClick("forceLogin")}
              label="Force Login"
              className="mt-2 p-button-warning p-button-outlined"
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
