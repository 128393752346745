import { ICustomer } from "../../models/index";
import { updateCustomer } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateCustomerData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: updateCustomerIsLoading } = useMutation(
    async (customer: ICustomer) => await updateCustomer(customer),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
        toastSuccess(toast, "Customer Updated");
      },
      onError: () => {
        toastError(toast, "Customer not Updated");
      },
    }
  );
  return {
    mutate,
    updateCustomerIsLoading,
  };
};
