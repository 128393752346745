import { ICustomer } from "@/models";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { customerSchema } from "../../../../validations/index";
import { customerRadioButtonFields, cities } from "../../../../constants/index";
import {
  InputField,
  InputTextAreaField,
  CalendarField,
  DropdownField,
  RadioButtonField,
  RadioButtonBoxField,
  ErrorField,
  DataTableDialogFooter,
  InputMaskField,
} from "../../../index";
import {
  isFormFieldValid,
  capitalizeFirstLetter,
} from "../../../../utils/index";

interface CustomerDialogProps {
  visible: boolean;
  customer: ICustomer;
  isLoading: boolean;
  onHide: () => void;
  addCustomer: (customer: ICustomer) => void;
}

const CustomerDialog: React.FC<CustomerDialogProps> = ({
  visible,
  customer,
  isLoading,
  onHide,
  addCustomer,
}) => {
  const [gender, setGender] = useState(null);

  const hideDialog = () => {
    setGender(null);
    onHide();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: customer,
    enableReinitialize: true,
    validationSchema: customerSchema,
    onSubmit: (data) => {
      addCustomer(data);
      formik.resetForm();
    },
  });

  const setCustomerGender = (value: any) => {
    setGender(value);
    formik.values.gender = value;
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={"Customer Details"}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={hideDialog}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={hideDialog}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Name"
          labelText={capitalizeFirstLetter("Name")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Name" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Name"),
          })}
        />
        <InputField
          id="surname"
          value={formik.values.surname ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Surname"
          labelText={capitalizeFirstLetter("Surname")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Surname" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Surname"),
          })}
        />
        <InputField
          id="email"
          value={formik.values.email ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="E-Mail"
          labelText={capitalizeFirstLetter("E-Mail")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="E-Mail" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "E-Mail"),
          })}
        />
        <InputMaskField
          id="gsmno"
          value={formik.values.gsmno ?? ""}
          type="Gsm No"
          mask="(999) 999 99 99"
          required
          onChange={formik.handleChange}
          labelName="Gsm No"
          labelText={capitalizeFirstLetter("Gsm No")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Gsm No" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Gsm No"),
          })}
        />
        <DropdownField
          id="city"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          options={cities}
          labelName={"city"}
          labelText={capitalizeFirstLetter("city")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"city"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "city"),
          })}
        />
        <CalendarField
          id="birthDate"
          name="birthDate"
          value={formik.values.birthDate}
          onChange={formik.handleChange}
          showIcon
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          labelName={"birthDate"}
          labelText={capitalizeFirstLetter("birthdate")}
        />
        <RadioButtonBoxField
          style={{ margin: "30px 0" }}
          className={classNames("field", {
            "p-invalid": isFormFieldValid(formik, "gender"),
          })}
        >
          {customerRadioButtonFields.map((item, index) => (
            <RadioButtonField
              inputId={`gender${index}`}
              key={index}
              name="gender"
              value={item}
              onChange={(e: any) => setCustomerGender(e.target.value)}
              checked={gender === item || formik.values.gender === item}
              labelName={`gender${index}`}
              labelText={item}
            />
          ))}
          {<ErrorField formik={formik} name={"gender"} />}
        </RadioButtonBoxField>
        <InputTextAreaField
          id={"address"}
          value={formik.values.address ?? ""}
          required
          autoResize
          onChange={formik.handleChange}
          labelName={"address"}
          labelText={capitalizeFirstLetter("address")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"address"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "address"),
          })}
        />
      </form>
    </Dialog>
  );
};

export default CustomerDialog;
