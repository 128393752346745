import { IVideoContent } from "../../models/index";
import { addLicenseToContent, removeContentFromLicense } from "../../services/index"; 
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useRemoveLicenseFromContent = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: removeLicenseFromContentIsLoading } = useMutation(
      async (content: IVideoContent) =>
        await removeContentFromLicense(content),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("licenses");
          queryClient.invalidateQueries("content");
          toastSuccess(toast, "License Removed");
        },
        onError: () => {
          toastError(toast, "License not Removed");
        },
      }
    );
    return {  mutate,removeContentFromLicense};
  };