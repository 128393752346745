import {
  getAllDynamic,
  getAllDynamicWithoutContent,
} from "../../services/index";
import { useQuery } from "react-query";

export const useGetAllDynamicData = (enabled?: boolean) => {
  const {
    data: allDynamic,
    isLoading: getAllDynamicIsLoading,
    refetch: refetchGetAllDynamic,
  } = useQuery("allDynamic", getAllDynamicWithoutContent, {
    // } = useQuery("allDynamic", getAllDynamic, {
    enabled: false,
  });
  return { allDynamic, getAllDynamicIsLoading, refetchGetAllDynamic };
};
