import { Navigate, Outlet } from "react-router-dom";
import { AuthWrapper } from "../layouts/index";
import { useAuth } from "../hooks/index";

export const SpecialRoute = () => {
  const {
    loggedUser: { user },
  } = useAuth();

  return !user ? (
    <AuthWrapper>
      <Outlet />
    </AuthWrapper>
  ) : (
    <Navigate to="/" />
  );
};
