import IVideoContentMinimize from "@/models/Entities/videoContent/IVideoContentMinimize";
import { axiosInstance } from "../../api/axios.instance";
import { IContentP, ILabelDefinition } from "@/models";

export const getLabels = async (): Promise<ILabelDefinition[]> =>
  await axiosInstance
    .get(`/contman/label/getall`) //  https://hoox.tv/api/contman/label/getall
    .then((res) => res.data.data);

export const addLabel = async (newLabel: ILabelDefinition) =>
  await axiosInstance.post(`/contman/label/create`, { labelP: newLabel });

export const updateLabel = async (editLabel: ILabelDefinition) =>
  await axiosInstance.put(`/contman/label`, { labelP: editLabel });

// get published for order
export const getContentsOfLabel = async (
  labelId: number | null
): Promise<IContentP[]> =>
  await axiosInstance
    .get(`/contman/cl/v2/contents/bylabelid/` + labelId)
    .then((res) => res.data.data);

//get all with any status
export const getAllContentsOfLabel = async (
  labelId: number | null
): Promise<IContentP[]> =>
  await axiosInstance
    .get(`/contman/cl/v2/contents/all/bylabelid/` + labelId)
    .then((res) => res.data.data);

export const reOrderList = async (
  labelId: number | null,
  contenIdList: number[]
) =>
  await axiosInstance.post(`/contman/cl/ordercontentsinlabel`, {
    labelId: labelId,
    contentIds: contenIdList,
  });

export const addContentToLabel = async (
  labelId: number | null,
  contenIdList: number[]
) =>
  await axiosInstance.post(`/contman/cl/content/add`, {
    labelId: labelId,
    contentIds: contenIdList,
  });

export const addContentToLabelSingle = async (
  labelId: number | null,
  contenId: number | undefined
) =>
  await axiosInstance.post(`/contman/cl/content/add/single`, {
    labelId: labelId,
    contentId: contenId,
  });

export const removeContentFromLabel = async (
  labelId: number | null,
  contenIdList: number[]
) =>
  await axiosInstance.post(`/contman/cl/content/remove`, {
    labelId: labelId,
    contentIds: contenIdList,
  });

export const removeContentFromLabelSingle = async (
  labelId: number | null,
  contentId: number | undefined
) =>
  await axiosInstance.post(`/contman/cl/content/remove/single`, {
    labelId: labelId,
    contentId: contentId,
  });

export const getAllContentsNotOfLabel = async (
  labelId: number | null,
  labelType: string | null
): Promise<IVideoContentMinimize[]> =>
  await axiosInstance
    .get(`/contman/cl/v2/contents/others/bylabelid/` + labelId, {
      params: {
        labelType: labelType,
      },
    })
    .then((res) => res.data.data);

export const labelPriorityUpdate = async (labels: ILabelDefinition[]) =>
  await axiosInstance.put(`/contman/label/bulkpriorityupdate`, {
    labels: labels,
  });
