import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState, useEffect } from "react";
import VideoPlayer from "./VideoPlayer";
import VideoPlayerTrailer from "./VideoPlayer";
import { IVideoContent } from "@/models";
import {
  deleteVideoContentOnVdo,
  getVdoContentKeys,
  getVdoContentTrailerKeys,
  getVideoContentSingle,
} from "../../../../services";
import AddVideoFileDailog from "../VideoFileListingDialog/AddVideoFileDailog";
import { Divider } from "primereact/divider";
import styled from "styled-components";
import { Chip } from "primereact/chip";
import { ConfirmOperationDialog } from "../../common";

interface PlayDialogProps {
  visible: boolean;
  videoContentId: number;
  onHide: () => void;
  content: IVideoContent;
}

const InfoTable = styled("table")`
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;

  td {
    padding: 10px;
    text-align: left; /* Sola hizalama */
    border: 1px solid #dee2e6;
    border-radius: 10px;
  }
  th {
    padding: 10px;
    text-align: left; /* Sola hizalama */
    border: 1px solid #dee2e6;
    border-radius: 10px;
    background: #f8f9fa;
  }
`;

const PlayDialog: React.FC<PlayDialogProps> = ({
  visible,
  videoContentId,
  onHide,
  content,
}) => {
  const [closeButton, setCloseButton] = useState(false);
  const [isConfirmOperationDialogVisible, setIsConfirmOperationDialogVisible] =
    useState(false);

  const [vdoOtpKey, setVdoOtpKey] = useState<string | null>(null);
  const [vdoPbInfo, setVdoPbInfo] = useState<string | null>(null);

  const [vdoTrailerOtpKey, setVdoTrailerOtpKey] = useState<string | null>(null);
  const [vdoTrailerPbInfo, setVdoTrailerPbInfo] = useState<string | null>(null);

  const [message, setMessage] = useState<string | null>(null);
  const [message2, setMessage2] = useState<string | null>(null);

  const [isAddVideoDialogVisible, setIsAddVideoDialogVisible] =
    useState<boolean>(false);

  const [isTrailer, setIsTrailer] = useState(false);

  const [vdoFileStatus, setVdoFileStatus] = useState<string | null>(null);
  const [vdoFileName, setVdoFileName] = useState<string | null>(null);

  const [vdoTrailerFileStatus, setVdoTrailerFileStatus] = useState<
    string | null
  >(null);
  const [vdoTrailerFileName, setVdoTrailerFileName] = useState<string | null>(
    null
  );

  useEffect(() => {
    //console.log("PlayDialog.videoContentId:", videoContentId);
    //console.log("PlayDialog.content:", content);
    if (videoContentId != -1 && visible) {
      getVdoContentKeys(videoContentId).then((response) => {
        //console.log("getVdoContentKeys", response);
        if (response) {
          //console.log("response.otp:", response.otp);
          setVdoOtpKey(response.otp);
          setVdoPbInfo(response.playbackInfo);
          setMessage(null);
        } else {
          setMessage("No Video Available");
        }
      });

      getVdoContentTrailerKeys(videoContentId).then((response) => {
        //console.log("getVdoContentKeys", response);
        if (response) {
          //console.log("response.otp:", response.otp);
          setVdoTrailerOtpKey(response.otp);
          setVdoTrailerPbInfo(response.playbackInfo);
          setMessage(null);
        } else {
          setMessage2("No Trailer Available");
        }
      });
    }
  }, [videoContentId, visible]);

  useEffect(() => {
    //console.log("getVideoContentSingle.useEffect");
    if (videoContentId) {
      getVideoContentSingle(videoContentId).then((response) => {
        //console.log("getVdoContentKeys", response);
        if (response) {
          setVdoFileName(response.videoFileName);
          setVdoFileStatus(response.vdoStatus);
          setVdoTrailerFileName(response.trailerFileName);
          setVdoTrailerFileStatus(response.vdoTrailerStatus);
        } else {
          setMessage2("No data Available");
        }
      });
    }
  }, [videoContentId, visible, isConfirmOperationDialogVisible]);

  {
    /*
  useEffect(() => {
    if (videoContentId) {
      getVdoContentData(videoContentId).then((response) => {
        //console.log("getVdoContentKeys", response);
        if (response) {
          //console.log("getVdoContentData.response:", response);
          setVdoFileName(response?.title);
          setVdoFileStatus(response?.status);
        } else {
          setMessage2("No data Available");
        }
      });
    }
  }, [videoContentId, visible]);
  */
  }

  const hideDialog = () => {
    setVdoOtpKey(null);
    setVdoPbInfo(null);
    setVdoTrailerOtpKey(null);
    setVdoTrailerPbInfo(null);
    setMessage(null);
    setVdoFileName(null);
    setVdoFileStatus(null);
    setVdoTrailerFileName(null);
    setVdoTrailerFileStatus(null);

    onHide();
  };

  function openAddVideoDialog(): void {
    //setIsTrailer(false);
    //setIsAddVideoDialogVisible(true);
  }

  function openAddTrailerDialog(): void {
    setIsTrailer(true);
    setIsAddVideoDialogVisible(true);
  }

  function hideAddVideoDialog(): void {
    setIsAddVideoDialogVisible(false);
  }

  function openDeleteConfirm(): void {
    setIsConfirmOperationDialogVisible(true);
  }

  function hideDeleteConfirm(): void {
    setIsConfirmOperationDialogVisible(false);
  }

  function handleDeleteOperation(): void {
    if (videoContentId) {
      deleteVideoContentOnVdo(videoContentId).then((response) => {
        console.log("deleteVideoContentOnVdo", response);
        if (response) {
        } else {
          setMessage2("No data Available");
        }
      });
    }
  }

  return (
    <>
      <div>
        <Dialog
          visible={visible}
          style={{ minWidth: "85vh", minHeight: "60vh" }}
          header={"Video Files of " + content.name}
          modal
          className="p-fluid"
          onHide={hideDialog}
          footer={
            <Button
              label={"Close"}
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideDialog}
            />
          }
        >
          {/** 
          <div>
            <Button
              label="Add Trailer File"
              className="p-button-secondary details-button m-1"
              onClick={() => openAddTrailerDialog()}
              style={{ width: "150px" }}
            />
            <Button
              label="Add Video File"
              className="p-button-primary details-button m-1"
              onClick={() => openAddVideoDialog()}
              style={{ width: "150px" }}
            />
          </div>
          */}
          <div>
            <InfoTable>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Type</th>
                  <th style={{ width: "40%" }}>File Name</th>
                  <th style={{ width: "20%" }}>File Status</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Chip
                      label="Video File"
                      icon="pi pi-video"
                      style={{ backgroundColor: "rgb(186 219 236 / 78%)" }}
                    />
                  </td>
                  <td>{vdoFileName}</td>
                  <td>{vdoFileStatus}</td>
                  <td style={{ textAlign: "right" }}>
                    {vdoFileName ? (
                      <Button
                        icon="pi pi-times p-button-rounded"
                        className="p-button-outlined p-button-rounded p-button-danger m-1"
                        onClick={() => openDeleteConfirm()}
                        style={{
                          width: "2rem",
                          height: "2rem",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {vdoFileStatus == "Downloading" ? (
                      ""
                    ) : (
                      <Button
                        icon="pi pi-plus p-button-rounded"
                        className="p-button-outlined p-button-rounded p-button-info m-1"
                        onClick={() => openAddVideoDialog()}
                        style={{ width: "2rem", height: "2rem" }}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Chip label="Trailer File" icon="pi pi-video" />
                  </td>
                  <td>{vdoTrailerFileName}</td>
                  <td>{vdoTrailerFileStatus}</td>
                  <td style={{ textAlign: "right" }}>
                    {vdoTrailerFileName ? (
                      <Button
                        icon="pi pi-times p-button-rounded"
                        className="p-button-outlined p-button-rounded p-button-danger m-1"
                        onClick={() => openDeleteConfirm()}
                        style={{
                          width: "2rem",
                          height: "2rem",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      icon="pi pi-plus p-button-rounded"
                      className="p-button-outlined p-button-rounded p-button-info m-1"
                      onClick={() => openAddTrailerDialog()}
                      style={{ width: "2rem", height: "2rem" }}
                    />
                  </td>
                </tr>
              </tbody>
            </InfoTable>
          </div>
          <div>
            {vdoOtpKey ? (
              <>
                <Divider></Divider>
                <h2>Main Video</h2>
                <VideoPlayer
                  otp={vdoOtpKey}
                  playbackInfo={vdoPbInfo}
                  setCloseButton={setCloseButton}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {vdoTrailerOtpKey ? (
              <>
                <Divider></Divider>
                <h2>Trailer Video</h2>
                <VideoPlayerTrailer
                  otp={vdoTrailerOtpKey}
                  playbackInfo={vdoTrailerPbInfo}
                  setCloseButton={setCloseButton}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </Dialog>
      </div>

      <AddVideoFileDailog
        visible={isAddVideoDialogVisible}
        content={content}
        onHide={hideAddVideoDialog}
        isTrailer={isTrailer}
      />

      <ConfirmOperationDialog
        visible={isConfirmOperationDialogVisible}
        text="Video file will be removed ?"
        confirm={handleDeleteOperation}
        onHide={hideDeleteConfirm}
      />
    </>
  );
};

export default PlayDialog;
