import * as Yup from "yup";

export const metadataSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Name is required"),
  title: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Title is required"),
  originalTitle: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Original Title is required"),
  duration: Yup.number()
    .min(1, "Please enter at least 1 character!")
    .max(3, "You can enter up to 3 characters!")
    .required("Duration is required"),
  year: Yup.number()
    .min(4)
    .max(4, "You can enter up to 4 characters!")
    .required("Year is required"),
  summaryShort: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(100, "You can enter up to 100 characters!")
    .required("Summary Short is required"),
  summaryLong: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(100, "You can enter up to 100 characters!")
    .required("Summary Long is required"),
  label: Yup.string().required("Label field is required").nullable(),
  episodeNo: Yup.number()
    .min(1, "Please enter at least 1 character!")
    .max(4, "You can enter up to 4 characters!")
    .required("Episode No is required"),
});
