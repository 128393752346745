import { ErrorField, InputField, DataTableDialogFooter } from "../../../index";
import { IProduct } from "../../../../models/index";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
} from "../../../../utils/index";
import { productSchema } from "../../../../validations/index";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";

interface ProductDialogProps {
  visible: boolean;
  product: IProduct;
  isLoading: boolean;
  onHide: () => void;
  addProduct: (product: IProduct) => void;
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  visible,
  product,
  isLoading,
  onHide,
  addProduct,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: product,
    enableReinitialize: true,
    validationSchema: productSchema,
    onSubmit: (data) => {
      addProduct(data);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={t("Product Details")}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={onHide}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Name"
          labelText={capitalizeFirstLetter("Name")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Name" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Name"),
          })}
        />
        <InputField
          id="type"
          value={formik.values.type ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Type"
          labelText={capitalizeFirstLetter("Type")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Type" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Type"),
          })}
        />
      </form>
    </Dialog>
  );
};

export default ProductDialog;
