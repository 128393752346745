import React, { useCallback, useEffect } from "react";

export const AppConfig = () => {
  useEffect(() => {
    document.documentElement.style.fontSize = 14 + "px";
  }, []);

  // const replaceLink = useCallback((linkElement: any, href: string) => {
  //   linkElement.setAttribute("href", href);
  // }, []);

  // useEffect(() => {
  //   const themeElement = document.getElementById("theme-link");
  //   const themeHref = `./assets/themes/lara-light-indigo/theme.css`;
  //   replaceLink(themeElement, themeHref);
  // }, [replaceLink]);

  return <></>;
};
