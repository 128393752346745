import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { ILicense, IReleaseDate } from "@/models";
import { ConfirmOperationDialog, DataTableDialogFooter } from "../../common";
import { InputField } from "../../../../components/FormFields";
import { initialRelease } from "../../../../constants";
import classNames from "classnames";
import { isFormFieldValid, toastError, toastSuccess } from "../../../../utils";
import {
  getLicensesOfContentV2,
  getSchdulesOfContent,
  publishContent,
} from "../../../../services";
import LicensesOfContent from "./LicensesOfContent";
import { Divider } from "primereact/divider";
import SchedulesOfContent from "./SchedulesOfContent";
import { Panel } from "primereact/panel";
import IVideoContentMinimize from "@/models/Entities/videoContent/IVideoContentMinimize";

interface PublishDialogProps {
  visible: boolean;
  content: IVideoContentMinimize | null;
  onHide: () => void;
  refreshTable: () => void;
}

const PublishDialog: React.FC<PublishDialogProps> = ({
  visible,
  content,
  onHide,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<
    ILicense | undefined
  >();
  const [licenses, setLicenses] = useState<ILicense[] | undefined>();
  const [schedules, setSchedules] = useState<IReleaseDate[] | undefined>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newReleaseDate, setnewReleaseDate] = useState<IReleaseDate>();

  useEffect(() => {
    const now = new Date();
    const releaseTimeStamp = new Date().getTime();
    formik.setFieldValue("releaseDate", releaseTimeStamp);
    //console.log("releaseDate");
  }, [visible, content]);

  useEffect(() => {
    //console.log("getLicensesOfContentV2 - content:", content);
    if (content != undefined) {
      getLicensesOfContentV2(content?.id).then((response) => {
        //console.log("getLicensesOfContentV2.respons:", response);
        if (response) {
          setLicenses(response);
        }
      });

      getSchdulesOfContent(content?.id).then((response) => {
        if (response) {
          setSchedules(response);
        }
      });
    }
  }, [content, visible]);

  const hideDialog = () => {
    formik.resetForm();
    onHide();
  };

  const refreshSchedules = () => {
    getSchdulesOfContent(content?.id).then((response) => {
      if (response) {
        setSchedules(response);
      }
    });
  };

  useEffect(() => {
    if (licenses && licenses?.length > 0) {
      setSelectedLicense(licenses[0]);
    }
  }, [licenses]);

  useEffect(() => {
    if (selectedLicense) {
      const outReleaseTimeStamp = selectedLicense?.endDate
        ? new Date(selectedLicense.endDate).getTime()
        : null;
      formik.setFieldValue("outReleaseDate", outReleaseTimeStamp);
    }
  }, [selectedLicense, content]);

  const formik = useFormik({
    initialValues: initialRelease,
    enableReinitialize: true,

    onSubmit: (data) => {
      //publishContentLocal(data);
      formikPublishSubmit(data);
      //formik.resetForm();
      //onHide();
    },
  });

  const formikPublishSubmit = (newRelease: IReleaseDate) => {
    newRelease.content.id = content?.id;
    newRelease.licenseId = selectedLicense?.id;
    setnewReleaseDate(newRelease);
    openConfirmation();
  };

  const publishContentLocal = () => {
    // newReleaseData: IReleaseDate
    setIsLoading(true);
    //newReleaseData.content.id = content?.id;
    //newReleaseData.licenseId = selectedLicense?.id;
    console.log("newReleaseDate:", newReleaseDate);
    //contman/contentreleasedate/create
    //contman/content/publish

    // release dates : /contman/contentreleasedate/getbycontent/8
    // delete release Date : /contman/contentreleasedate/delete/69

    publishContent(newReleaseDate).then((response) => {
      console.log("addLabel.response:", response);
      if (response.status === 200) {
        if (response.data.code === 200) {
          //console.log("toastSuccess:");
          toastSuccess(toast, "Content published. '" + content?.name + "'");
          refreshTable();
          formik.resetForm();
          onHide();
        } else {
          toastError(toast, "Content not published." + response.data.message);
        }
      } else {
        toastError(toast, "Bir Sorun oluştu." + response.status);
      }
    });

    setIsLoading(false);
  };

  function openConfirmation(): void {
    setShowConfirmation(true);
  }

  function hideConfirmation(): void {
    setShowConfirmation(false);
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "50%" }}
        header={"Publish Content : " + content?.name}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={hideDialog}
            handleSubmit={formik.handleSubmit}
          />
        }
        onHide={onHide}
      >
        <div style={{ marginBottom: "20px" }}>
          <LicensesOfContent licenses={licenses} />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <SchedulesOfContent
            schdules={schedules}
            refreshSchedules={refreshSchedules}
          />
        </div>
        <Divider />

        <div className="col-4" style={{ marginTop: "30px", padding: "0px" }}>
          <Panel header="Release Dates">
            <form onSubmit={formik.handleSubmit}>
              <InputField
                id="releaseDate"
                value={
                  formik.values.releaseDate
                    ? new Date(formik.values.releaseDate)
                        .toISOString()
                        .substring(0, 10)
                    : ""
                }
                onChange={formik.handleChange}
                type="date"
                required
                labelName="Start Date"
                labelText={"Start Date"}
                readOnly
                className={classNames({
                  "p-invalid": isFormFieldValid(formik, "Start Date"),
                })}
              />

              <InputField
                id="outReleaseDate"
                value={
                  formik.values.outReleaseDate
                    ? new Date(formik.values.outReleaseDate)
                        .toISOString()
                        .substring(0, 10)
                    : ""
                }
                onChange={formik.handleChange}
                type="date"
                required
                autoFocus={true}
                labelName="End Date"
                labelText={"End Date"}
                readOnly
                className={classNames({
                  "p-invalid": isFormFieldValid(formik, "End Date"),
                })}
              />
            </form>
          </Panel>
        </div>
      </Dialog>
      <ConfirmOperationDialog
        visible={showConfirmation}
        text={content?.name + " will be published. Pelase confirm !"}
        onHide={hideConfirmation}
        confirm={publishContentLocal}
      />
    </>
  );
};

export default PublishDialog;
