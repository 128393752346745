import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

interface DataTableSearchHeaderProps {
  title: string;
  filters: any;
  onChange: (filters: any) => void;
}

const DataTableSearchHeader: React.FC<DataTableSearchHeaderProps> = ({
  title,
  filters,
  onChange,
}) => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters.global.value = value;

    onChange(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <div className="table-header">
      <h5 className="mx-0 my-1">{title}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilterValue}
          placeholder={"Search..."}
          onInput={(e: any) => onGlobalFilterChange(e)}
        />
      </span>
    </div>
  );
};

export default DataTableSearchHeader;
