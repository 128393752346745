import { useEffect } from 'react'; // Import useEffect for side effects
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router

import { getBaseUrl } from '../utils/index';
import axios from 'axios';
import { useLogout } from '../hooks/useLogout';

export const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
});

const useAxiosInterceptor = () => {
  const navigate = useNavigate();
  const { logout: handleLogout } = useLogout();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log(error);
        if (error.response) {
          const status = error.response.status;
          console.log(status);
          if (status === 401 || status === 403) {
            handleLogout(); // Logout the user
            navigate('/login'); // Redirect to the login page
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Remove the interceptor when the component unmounts
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [navigate, handleLogout]);

  return null; // This is a custom hook, so it doesn't return JSX
};

export default useAxiosInterceptor;

{/*import { getBaseUrl } from "../utils/index";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
});*/}