import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface DataTableDeleteDialogProps {
  visible: boolean;
  data: any;
  onHide: () => void;
  onDelete: (id: number) => void;
}

const DataTableDeleteDialog: React.FC<DataTableDeleteDialogProps> = ({
  visible,
  data,
  onHide,
  onDelete,
}) => {
  const deleteDialogFooter = (
    <>
      <Button icon="pi pi-times" className="p-button-text" onClick={onHide} />
      <Button
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => onDelete(data.id)}
      />
    </>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header="Confirm"
      modal
      footer={deleteDialogFooter}
      onHide={onHide}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {data.name && (
          <span>
            {"Are you sure you want to delete"} <b>{data?.name}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};

export default DataTableDeleteDialog;
