import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ILabelDefinition } from "../../../../models";
import { initialLabel } from "../../../../constants";
import {
  ErrorField,
  InputField,
  DataTableDialogFooter,
  InputMaskField,
  DropdownField,
} from "../../../index";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
  toastError,
  toastSuccess,
} from "../../../../utils";
import classNames from "classnames";
import { addLabel } from "../../../../services";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";

interface LabelDialogProps {
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
}

const LabelDialog: React.FC<LabelDialogProps> = ({
  visible,
  onHide,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  //const [newLabel, setNewLabel] = useState<ILabelDefinition>();

  const labelTypes = ["DYNAMIC"];
  const labelViewType = ["NUMBER", "NUMBERW", "NUMBERM", "CONWATCH", "COMSOON"];

  const formik = useFormik({
    initialValues: initialLabel,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      addNewLabel(data);
      formik.resetForm();
    },
  });

  const addNewLabel = (newLabel: ILabelDefinition) => {
    setIsLoading(true);
    addLabel(newLabel).then((response) => {
      //console.log("addLabel.response:", response);
      if (response.data.code === "200") {
        //console.log("toastSuccess:");
        toastSuccess(toast, "Label Added");
      } else {
        //console.log("atoastError:");
        toastError(toast, "Label not Added." + response.data.message);
      }
    });
    setIsLoading(false);
    refreshTable();
    onHide();
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "450px" }}
        header={t("New Label")}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={formik.handleSubmit}
          />
        }
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <InputField
            id="name"
            value={formik.values.name ?? ""}
            type="text"
            required
            autoFocus={true}
            onChange={formik.handleChange}
            labelName="Name"
            labelText={capitalizeFirstLetter("Name")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="Name" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "Name"),
            })}
          />
          <DropdownField
            id="type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            options={labelTypes}
            labelName={"Type of Label"}
            labelText={capitalizeFirstLetter("Type of Label")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name={"Type of Label"} />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "Type of Label"),
            })}
          />
          <DropdownField
            id="viewType"
            name="viewType"
            value={formik.values.viewType}
            onChange={formik.handleChange}
            options={labelViewType}
            labelName={"View Type of Label"}
            labelText={capitalizeFirstLetter("View Type of Label")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name={"View Type of Label"} />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "View Type of Label"),
            })}
          />
        </form>
      </Dialog>
    </>
  );
};

export default LabelDialog;
