import { getAllMainboardItems } from "../../services/index";
import { useQuery } from "react-query";

export const useGetAllMainboardsItemsData = () => {
  const {
    data: allMainboardItems,
    isLoading: getAllMainboardItemsIsLoading,
    refetch: refetchMbiList,
  } = useQuery("MainboardItems", getAllMainboardItems);
  return { allMainboardItems, getAllMainboardItemsIsLoading, refetchMbiList };
};
