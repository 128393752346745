import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useState, useEffect } from "react";
import { metadataSchema } from "../../../../validations/index";
import { IContentP, ILabelDefinition } from "@/models";
import {
  genres,
  contentTypes,
  ratings,
  advisories,
  definitions,
} from "../../../../constants/index";
import {
  useGetSeasonsData,
  useGetAllDynamicData,
} from "../../../../hooks/index";
import {
  InputField,
  InputTextAreaField,
  DropdownField,
  ErrorField,
  DataTableDialogFooter,
  MultiSelectField,
  ChipsField,
} from "../../../index";
import {
  isFormFieldValid,
  capitalizeFirstLetter,
} from "../../../../utils/index";

interface MetadataDialogProps {
  visible: boolean;
  metadata: IContentP;
  isLoading: boolean;
  onHide: () => void;
  saveMetadata: (metadata: IContentP) => void;
}

const MetadataDialog: React.FC<MetadataDialogProps> = ({
  visible,
  metadata,
  isLoading,
  onHide,
  saveMetadata,
}) => {
  const formik = useFormik({
    initialValues: metadata,
    enableReinitialize: true,
    // validationSchema: metadataSchema,
    onSubmit: (data) => {
      saveMetadata(data);
      formik.resetForm();
      hideDialog();
    },
  });

  const { seasons } = useGetSeasonsData(false);
  const { allDynamic } = useGetAllDynamicData(false);

  const [labels, setLabels] = useState<ILabelDefinition[]>([]);

  const seasonOptions = seasons?.map((season) => ({
    label: season.name,
    value: season.id,
  }));

  const allDynamicOptions = allDynamic?.map((label) => ({
    label: label.name,
    value: label,
  }));

  useEffect(() => {
    formik.values.labels && setLabels([...formik.values.labels]);
  }, [formik.values.labels]);

  const hideDialog = () => {
    formik.resetForm();
    onHide();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "60%" }}
      header={"Content Metadata"}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={hideDialog}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={hideDialog}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="grid justify-content-between">
          <div style={{ width: "45%", padding: "0.5rem" }}>
            <InputField
              id="name"
              value={formik.values.name ?? ""}
              type="text"
              required
              autoFocus={true}
              onChange={formik.handleChange}
              labelName="Content Name"
              labelText={capitalizeFirstLetter("Content Name")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Content Name" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Content Name"),
              })}
            />
            <InputField
              id="title"
              name="metadata.title"
              value={formik.values.metadata?.title ?? ""}
              type="text"
              onChange={formik.handleChange}
              labelName="Title"
              labelText={capitalizeFirstLetter("Title")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Title" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Title"),
              })}
            />
            <InputField
              id="title_brief"
              name="metadata.title_brief"
              value={formik.values.metadata?.title_brief ?? ""}
              type="text"
              onChange={formik.handleChange}
              labelName="Title Brief"
              labelText={capitalizeFirstLetter("Title Brief")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Title Brief" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Title Brief"),
              })}
            />
            <InputField
              id="originalTitle"
              name="metadata.originalTitle"
              value={formik.values.metadata?.originalTitle ?? ""}
              type="text"
              onChange={formik.handleChange}
              labelName="Original Title"
              labelText={capitalizeFirstLetter("Original Title")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Original Title" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Original Title"),
              })}
            />
            <InputField
              id="duration"
              name="metadata.duration"
              value={formik.values.metadata?.duration ?? ""}
              type="number"
              onChange={formik.handleChange}
              labelName="Duration"
              labelText={capitalizeFirstLetter("Duration")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Duration" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Duration"),
              })}
            />
            <InputField
              id="year"
              name="metadata.year"
              value={formik.values.metadata?.year ?? ""}
              type="number"
              onChange={formik.handleChange}
              labelName="Year"
              labelText={capitalizeFirstLetter("Year")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Year" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Year"),
              })}
            />

            {/*
            <InputField
              id="provider"
              name="metadata.provider"
              value={formik.values.metadata?.provider ?? ""}
              type="text"
              onChange={formik.handleChange}
              disabled
              labelName="Provider"
              labelText={capitalizeFirstLetter("Provider")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Provider" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Provider"),
              })}
            />
            */}

            <InputTextAreaField
              id="summary_short"
              name="metadata.summary_short"
              value={formik.values.metadata?.summary_short ?? ""}
              required
              autoResize
              onChange={formik.handleChange}
              labelName={"Summary Short"}
              labelText={capitalizeFirstLetter("Summary Short")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Summary Short"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Summary Short"),
              })}
            />
            <InputTextAreaField
              id="summary_long"
              name="metadata.summary_long"
              value={formik.values.metadata?.summary_long ?? ""}
              required
              autoResize
              onChange={formik.handleChange}
              labelName={"Summary Long"}
              labelText={capitalizeFirstLetter("Summary Long")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Summary Long"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Summary Long"),
              })}
            />
          </div>
          <div style={{ width: "45%", padding: "0.5rem" }}>
            <ChipsField
              inputId="directors"
              name="metadata.directors"
              value={formik.values.metadata?.directors}
              onChange={(e: any) => {
                formik.setFieldValue("metadata.directors", e.value);
              }}
              placeholder="Add Directors"
              style={{ marginBottom: "1rem" }}
              labelName="Directors"
              labelText={capitalizeFirstLetter("Directors")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Directors" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Directors"),
              })}
            />
            <ChipsField
              inputId="actors"
              name="metadata.actors"
              value={formik.values.metadata?.actors}
              onChange={(e: any) => {
                formik.setFieldValue("metadata.actors", e.value);
              }}
              placeholder="Add Actors"
              style={{ marginBottom: "1rem" }}
              labelName="Actors"
              labelText={capitalizeFirstLetter("Actors")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Actors" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Actors"),
              })}
            />
            {/*
            <MultiSelectField
              id="labels"
              name="labels"
              value={labels}
              onChange={formik.handleChange}
              options={allDynamicOptions ?? []}
              placeholder="Select Labels"
              style={{ marginBottom: "1rem" }}
              labelName="Label"
              labelText={capitalizeFirstLetter("Label")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Label" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Label"),
              })}
            />
            */}

            {formik.values.type === "SEASON" ? (
              <InputField
                id="seasonNo"
                value={formik.values.seasonNo ?? ""}
                type="number"
                onChange={formik.handleChange}
                labelName="Season No"
                labelText={capitalizeFirstLetter("Season No")}
                getFormErrorMessage={() => (
                  <ErrorField formik={formik} name="Season No" />
                )}
                className={classNames({
                  "p-invalid": isFormFieldValid(formik, "Season No"),
                })}
              />
            ) : (
              ""
            )}

            {formik.values.type === "EPISODE" ? (
              <InputField
                id="episodeNo"
                value={formik.values.episodeNo ?? ""}
                type="number"
                onChange={formik.handleChange}
                labelName="Episode No"
                labelText={capitalizeFirstLetter("Episode No")}
                getFormErrorMessage={() => (
                  <ErrorField formik={formik} name="Episode No" />
                )}
                className={classNames({
                  "p-invalid": isFormFieldValid(formik, "Episode No"),
                })}
              />
            ) : (
              ""
            )}

            <DropdownField
              id="type"
              value={formik.values.type ?? ""}
              disabled
              onChange={formik.handleChange}
              options={contentTypes}
              labelName={"Type of Content"}
              labelText={capitalizeFirstLetter("Type of Content")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Type of Content"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Type of Content"),
              })}
            />
            <DropdownField
              id="parentId"
              value={formik.values.parentId ?? ""}
              disabled
              onChange={formik.handleChange}
              options={seasonOptions}
              labelName={"Parent"}
              labelText={capitalizeFirstLetter("Parent")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Parent"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Parent"),
              })}
            />

            {/*
            <InputField
              id="typeTitle"
              value={formik.values.typeTitle ?? ""}
              type="text"
              onChange={formik.handleChange}
              labelName="Type Title"
              labelText={capitalizeFirstLetter("Type Title")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Type Title" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Type Title"),
              })}
            />
            */}
            <InputField
              id="introDuration"
              value={formik.values.introDuration ?? ""}
              type="number"
              onChange={formik.handleChange}
              labelName="Intro Duration"
              labelText={capitalizeFirstLetter("Intro Duration")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Intro Duration" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Intro Duration"),
              })}
            />
            <DropdownField
              id="rating"
              name="metadata.rating"
              value={formik.values.metadata?.rating ?? ""}
              onChange={formik.handleChange}
              options={ratings}
              labelName={"Rating"}
              labelText={capitalizeFirstLetter("Rating")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Rating"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Rating"),
              })}
            />
            <DropdownField
              id="advisories"
              name="metadata.advisories"
              value={formik.values.metadata?.advisories ?? ""}
              onChange={formik.handleChange}
              options={advisories}
              labelName={"Parental Advisory"}
              labelText={capitalizeFirstLetter("Parental Advisory")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Parental Advisory"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Parental Advisory"),
              })}
            />
            <DropdownField
              id="definitions"
              name="metadata.definitions"
              value={formik.values.metadata?.definitions ?? ""}
              onChange={formik.handleChange}
              options={definitions}
              labelName={"Content Definition"}
              labelText={capitalizeFirstLetter("Content Definition")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Content Definition"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Content Definition"),
              })}
            />
            <DropdownField
              id="genres"
              name="metadata.genres"
              value={formik.values.metadata?.genres ?? ""}
              onChange={formik.handleChange}
              options={genres}
              labelName={"Genre"}
              labelText={capitalizeFirstLetter("Genre")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name={"Genre"} />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Genre"),
              })}
            />
            <InputField
              id="videoEndTime"
              name="videoEndTime"
              value={formik.values.videoEndTime ?? ""}
              type="text"
              onChange={formik.handleChange}
              labelName="End Time (00:00)"
              labelText={capitalizeFirstLetter("End Time Code")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="EndTime" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "EndTime"),
              })}
            />
            <ChipsField
              inputId="tags"
              name="metadata.tags"
              value={formik.values.metadata?.tags}
              onChange={(e: any) => {
                formik.setFieldValue("metadata.tags", e.value);
              }}
              placeholder="Add Tags"
              style={{ marginBottom: "1rem" }}
              labelName="Tags"
              labelText={capitalizeFirstLetter("Tags")}
              getFormErrorMessage={() => (
                <ErrorField formik={formik} name="Tags" />
              )}
              className={classNames({
                "p-invalid": isFormFieldValid(formik, "Tags"),
              })}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default MetadataDialog;
