import { deleteContract } from "../../services/index";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../utils/index";

export const useDeleteContractData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: deleteContractIsLoading } = useMutation(
    async () => await deleteContract(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contracts");
        toastSuccess(toast, "Contract Deleted");
      },
      onError: () => {
        toastError(toast, "Contract not Deleted");
      },
    }
  );
  return {
    mutate,
    deleteContractIsLoading,
  };
};
