import { IContentGroupOc, IVideoContent } from "../../models/index";
import { addContentGroupToContent } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddContentGroupToContent = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addContentGroupToContentIsLoading } = useMutation(
    async (content: IContentGroupOc) => await addContentGroupToContent(content),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contentGroups");
        queryClient.invalidateQueries("content");
        toastSuccess(toast, "Content Added to ContentGroup");
      },
      onError: () => {
        toastError(toast, "Content can not Added to ContentGroup");
      },
    }
  );
  return { mutate, addContentGroupToContent };
};
