import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ILabelDefinition } from "../../../../models";
import { ErrorField, InputField, DataTableDialogFooter } from "../../../index";
import { capitalizeFirstLetter, isFormFieldValid } from "../../../../utils";
import classNames from "classnames";
import { updateLabel } from "../../../../services";
import { Toast } from "primereact/toast";
import { ToggleButton } from "primereact/togglebutton";

interface EditLabelDialogProps {
  visible: boolean;
  onHide: () => void;
  editLabel: ILabelDefinition;
  refreshTable: () => void;
  onSuccess: () => void;
  onFail: () => void;
}

const EditLabelDialog: React.FC<EditLabelDialogProps> = ({
  visible,
  onHide,
  editLabel,
  refreshTable,
  onSuccess,
  onFail,
}) => {
  const toast = useRef<Toast>(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  //const [newLabel, setNewLabel] = useState<ILabelDefinition>();

  const labelTypes = ["DYNAMIC", "STATIC"];
  const labelViewType = ["NUMBER", "NUMBERW", "NUMBERM", "CONWATCH", "COMSOON"];

  const formik = useFormik({
    initialValues: editLabel,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      saveEditLabel(data);
      formik.resetForm();
    },
  });

  const saveEditLabel = (editLabelData: ILabelDefinition) => {
    setIsLoading(true);
    updateLabel(editLabelData).then((response) => {
      //console.log("saveEditLabel.response:", response);
      if (response.data.code === 200) {
        //console.log("toastSuccess:");
        //toastSuccess(toast, "Label Edited.");
        onSuccess();
      } else {
        //console.log("atoastError:");
        //toastError(toast, "Label not Edited." + response.data.message);
        onFail();
      }
    });
    setIsLoading(false);
    refreshTable();
    onHide();
  };

  const handleHomeScreenVisibilityChange = (e: any) => {
    formik.values.homeScreenVisibility = !formik.values.homeScreenVisibility;
    formik.handleChange(e);
    refreshTable();
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "450px" }}
        header={t("Edit Label")}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={formik.handleSubmit}
          />
        }
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <InputField
            id="name"
            value={formik.values.name ?? ""}
            type="text"
            required
            autoFocus={true}
            onChange={formik.handleChange}
            labelName="Name"
            labelText={capitalizeFirstLetter("Name")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="Name" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "Name"),
            })}
          />
          <ToggleButton
            id="homeScreenVisibility"
            onLabel="Home Screen Visibility"
            offLabel="HomeScreen Visibility"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={formik.values.homeScreenVisibility}
            onChange={handleHomeScreenVisibilityChange}
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
          />
        </form>
      </Dialog>
    </>
  );
};

export default EditLabelDialog;
