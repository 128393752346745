import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import {
  DataTableSearchHeader,
  DataTableCrud,
  DataTableActionTemplate,
  DataTableToolbar,
  DataTableDeleteDialog,
  ProductDialog,
} from "../../../index";
import { IProduct } from "../../../../models/index";
import { initialProduct, productFilters } from "../../../../constants/index";
import {
  useAddProductData,
  useDeleteProductData,
  useGetProductsData,
  useUpdateProductData,
} from "../../../../hooks/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ProductDataTable = () => {
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [filters, setFilters] = useState(productFilters);
  const [product, setProduct] = useState<IProduct>(initialProduct);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [productDialog, setProductDialog] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);

  const navigate = useNavigate();

  const toast = useRef<Toast>(null);

  const { mutate: addProductMutate, addProductIsLoading } =
    useAddProductData(toast);
  const { mutate: updateProductMutate } = useUpdateProductData(toast);
  const { mutate: deleteProductMutate } = useDeleteProductData(toast);
  const { products, getProductsIsLoading } = useGetProductsData();

  const openDeleteProductDialog = (product?: IProduct) => {
    product && setProduct(product);
    setDeleteProductDialog(true);
  };

  const openAddProductDialog = () => {
    setProduct(initialProduct);
    setIsAddItem(true);
    setProductDialog(true);
  };

  const openEditProductDialog = (rowData: IProduct) => {
    setProduct({ ...rowData });
    setIsAddItem(false);
    setProductDialog(true);
  };

  const hideProductDialog = () => {
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const saveProduct = (newProduct: IProduct) => {
    isAddItem ? addProductMutate(newProduct) : updateProductMutate(newProduct);
    !isAddItem && hideProductDialog();
  };

  const deleteProduct = () => {
    product.id && deleteProductMutate(product.id);
    hideDeleteProductDialog();
  };

  return (
    <>
      <Toast ref={toast} />
      <DataTableToolbar
        disableDeleteButton={!selectedProduct}
        openAddDialog={openAddProductDialog}
        openDeleteDialog={openDeleteProductDialog}
        showDeleteButton={true}
      />

      <DataTableCrud
        value={products}
        selection={selectedProduct}
        onSelectionChange={(e) => setSelectedProduct(e.value)}
        header={
          <DataTableSearchHeader
            title={"Manage Products"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        loading={getProductsIsLoading}
        title="products"
      >
        <Column
          selectionMode="single"
          headerStyle={{ width: "3rem" }}
          exportable={false}
        />
        <Column
          field="name"
          header={"Name"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="type"
          header={"Type"}
          sortable
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="productType"
          header={"Product Type"}
          sortable
          style={{ minWidth: "11rem" }}
        />

        <Column
          field="visibility"
          header={"Visibility"}
          sortable
          style={{ minWidth: "4rem" }}
        />
        <Column
          field="price"
          header={"Price"}
          sortable
          style={{ minWidth: "3rem" }}
        />
        <Column
          field="status"
          header={"Status"}
          sortable
          style={{ minWidth: "5rem" }}
        />
        <Column
          field="periodType"
          header={"Period(month)"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="guest"
          header={"Is Guest"}
          sortable
          style={{ minWidth: "8rem" }}
        />
        <Column
          body={(rowData: IProduct) => (
            <DataTableActionTemplate
              openDeleteDialog={() => openDeleteProductDialog(rowData)}
              openEditDialog={() => openEditProductDialog(rowData)}
              openAddContentGroup={() =>
                navigate(`/AddContentGroup/${rowData.id}`)
              }
            />
          )}
          header={"Action"}
          exportable={false}
          style={{ minWidth: "12rem" }}
        />
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteProductDialog}
        data={selectedProduct ?? product}
        onHide={hideDeleteProductDialog}
        onDelete={deleteProduct}
      />

      <ProductDialog
        visible={productDialog}
        product={product}
        isLoading={addProductIsLoading}
        onHide={hideProductDialog}
        addProduct={saveProduct}
      />
    </>
  );
};

export default ProductDataTable;
