import { IVideoContent } from "../../models/index";
import {
  updateVideoContent,
  updateVideoContentNameAndVdoId,
} from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateVideoContentData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: updateVideoContentIsLoading } = useMutation(
    async (videoContent: IVideoContent) =>
      await updateVideoContentNameAndVdoId(videoContent),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("videoContents");
        toastSuccess(toast, "VideoContent Updated");
      },
      onError: () => {
        toastError(toast, "VideoContent not Updated");
      },
    }
  );
  return {
    mutate,
    updateVideoContentIsLoading,
  };
};
