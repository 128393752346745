import IPayment from "@/models/Entities/payments/IPayment";

import { getPayments, getSubscriptionInfo } from "../../../services";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTableSearchHeader } from "../common";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import SubscriptionInfoDialog from "../../../components/Dialog/payment/SubscriptionInfoDialog";

const PaymentDataTable = () => {
  const [selectedPayment, setSelectedPayment] = useState<IPayment | undefined>(
    undefined
  );
  const [filters, setFilters] = useState(null);
  const [subscriptionInfoDialogShow, setSubscriptionInfoDialogShow] =
    useState(false);
  const [payments, setPayments] = useState<IPayment[]>();

  const hideopenSubscriptionInfoDialog = () => {
    setSubscriptionInfoDialogShow(false);
    setSelectedPayment(undefined);
  };

  const openSubscriptionInfoDialog = (rowData?: IPayment) => {
    console.log("openSubscriptionInfoDialog");
    rowData && setSelectedPayment(rowData);

    setSubscriptionInfoDialogShow(true);
  };

  useEffect(() => {
    getPayments()
      .then((res) => {
        //console.log("payments:", res);
        setPayments(res);
      })
      .catch((err) => {
        //console.log("Error Text:", err);
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
  }, []); // İzleme listesi (dependency array)

  useEffect(() => {
    console.log("selectedPaymnet.useEffect", selectedPayment);
  }, [selectedPayment]); // İzleme listesi (dependency array)

  return (
    <>
      <DataTable
        value={payments}
        paginator
        rows={30}
        dataKey="id"
        selection={null}
        onSelectionChange={(e) => setSelectedPayment(e.value)}
        //filters={filters}
        //loading={getProductsIsLoading}
        title="payments"
        header={
          <DataTableSearchHeader
            title={"Payment List"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
      >
        <Column
          field="id"
          header={"ID"}
          sortable
          style={{ minWidth: "4rem", cursor: "pointer" }}
          body={(rowData) => (
            <a onClick={() => openSubscriptionInfoDialog(rowData)}>
              {rowData.id}
            </a>
          )}
        />

        <Column
          field="merchantOid"
          header={"Merchant Oid"}
          sortable
          style={{ minWidth: "10rem" }}
        />

        <Column
          field="paymentDate"
          header={"Date"}
          sortable
          style={{ minWidth: "5rem" }}
          body={(rowData) =>
            rowData.paymentDate && (
              <>{moment(rowData.paymentDate).format("DD/MM/YYYY, hh:mm a")}</>
            )
          }
        />
        <Column
          field="priceWithTax"
          header={"Price With Tax"}
          sortable
          style={{ minWidth: "5rem" }}
        />
        <Column
          field="status"
          header={"Status"}
          filter
          sortable
          style={{ minWidth: "5rem" }}
        />
        <Column
          field={"msg"}
          header={"Message"}
          sortable
          style={{ minWidth: "10rem" }}
        />
      </DataTable>
      <SubscriptionInfoDialog
        subscriptionId={selectedPayment?.subscriptionId}
        merchantOid={selectedPayment?.merchantOid}
        visible={subscriptionInfoDialogShow}
        onHide={hideopenSubscriptionInfoDialog}
      />
    </>
  );
};

export default PaymentDataTable;
