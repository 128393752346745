import { QueryFunctionContext } from "react-query";
import { axiosInstance } from "../../api/axios.instance";
import { ICustomer } from "../../models/index";
import IPayment from "../../models/Entities/payments/IPayment";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/index";
import IPaymentInfo from "../../models/Entities/payments/IPaymentInfo";
import IInvoice from "../../models/Entities/payments/IInvoice";
import { IMessageDefinition } from "../../models/Entities/messages";
import ISubscription from "../../models/Entities/customer/ISubscription";

export const getCustomers = async (): Promise<ICustomer[]> =>
  await axiosInstance
    .get(`/crm-core/customer/getall`)
    .then((res) => res.data.data);

export const getCustomerDetails = async ({
  queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<ICustomer> => {
  const [_, id] = queryKey;

  return await axiosInstance
    .get(`/crm-core/customer/getbyid/${id}`)
    .then((res) => {
      //console.log("res3", res);
      //console.log("customer/getbyid", res);
      return res.data.data;
    })
    .catch((res) => {
      const navigate = useNavigate();
      const { logout: handleLogout } = useLogout();
      console.log("error catch:", res.response);
      if (res.response.status === "401") {
        //handleLogout();
        //navigate("/");
        window.location.href = "/";
      }
    });
};

export const getCustomerDetailsV2 = async (
  customerId: number
): Promise<ICustomer> => {
  return await axiosInstance
    .get(`/crm-core/customer/${customerId}`)
    .then((res) => {
      //console.log("res3", res);
      //console.log("customer/getbyid", res);
      return res.data.data;
    })
    .catch((res) => {
      //const navigate = useNavigate();
      //const { logout: handleLogout } = useLogout();
      console.log("error catch:", res.response);
      if (res.response.status === "401") {
        //handleLogout();
        //navigate("/");
        window.location.href = "/";
      }
    });
};

export const getPaymentInfo = async ({
  queryKey,
}: QueryFunctionContext<[string, string | undefined]>): Promise<any> => {
  const [_, guid] = queryKey;
  await axiosInstance
    .get(`/crm-core/payment/status/${guid}`)
    .then((res) => res);
};

export const getPaymentInfo2 = async (guid: string): Promise<any> => {
  await axiosInstance.get(`/crm-core/payment/status/${guid}`).then((res) => {
    console.log("res2", res);
    return JSON.parse(JSON.stringify(res));
  });
};

export const paymentsBySubscription = async (
  subscriptionId: number
): Promise<IPayment[]> => {
  return await axiosInstance
    .get(`/crm-core/payment/${subscriptionId}`)
    .then((res) => {
      console.log("paymentsBySubscription", res.data);
      return res.data;
    });
};

export const addCustomer = async (customer: ICustomer) =>
  await axiosInstance.post(`/customer`, customer);

export const updateCustomer = async (customer: ICustomer) =>
  await axiosInstance.put(`/customer`, customer);

export const deleteCustomer = async (customerId: number) =>
  await axiosInstance.delete(`/customer/${customerId}`);

export const paymentExtendedInfo = async (
  paymentId: number
): Promise<IPaymentInfo> => {
  return await axiosInstance
    .get(`/crm-core/payment/paymentinfo/${paymentId}`)
    .then((res) => {
      console.log("paymentInfo", res.data);
      return res.data;
    });
};

export const getPayments = async (): Promise<IPayment[]> =>
  await axiosInstance.get(`/crm-core/payment`).then((res) => {
    //console.log("res:", res);
    return res.data;
  });

export const getInvoices = async (): Promise<IInvoice[]> =>
  await axiosInstance
    .get(`/crm-core/payment/invoices`)
    .then((res) => res.data.data);

/* export const getDailyPayments = async (): Promise<IPayment[]> =>
  await axiosInstance
    .get(`/crm-core/payment/dailyPayments`)
    .then((res) => res.data.data); */

export const getDailyPayments = async () => {
  const response = await axiosInstance.get("/crm-core/payment/dailyPayments");
  return response.data;
};

export const getDailyEndedSubscriptions = async () => {
  const response = await axiosInstance.get(
    "/crm-subscription/subscription/daily/ended"
  );
  return response.data;
};

export const getDailyStartedSubscriptions = async () => {
  const response = await axiosInstance.get(
    "/crm-subscription/subscription/daily/started"
  );
  return response.data;
};

export const getMessages = async (): Promise<IMessageDefinition[]> =>
  await axiosInstance.get(`/crm-core/messages`).then((res) => {
    //console.log("res:", res);
    return res.data;
  });

export const getMessagesByStatus = async (): Promise<IMessageDefinition[]> =>
  await axiosInstance.get(`/crm-core/messages/bystatus`).then((res) => {
    //console.log("res:", res);
    return res.data;
  });

export const getMessagesByCustomer = async (
  customerId: number
): Promise<IMessageDefinition[]> =>
  await axiosInstance
    .get(`/crm-core/messages/bycustomer`, {
      params: {
        customerId: customerId,
      },
    })
    .then((res) => {
      //console.log("res:", res);
      return res.data;
    })
    .catch((error) => {
      console.error("Error fetching messages:", error);
    });

export const addResponse = async (
  messageId: number | null,
  responseMessage: string
) =>
  await axiosInstance.post(`/crm-core/messages`, {
    response: responseMessage,
    id: messageId,
  });

export const getSubscriptionInfo = async (
  subscriptionId: number | null | undefined
): Promise<ISubscription> =>
  await axiosInstance
    .get(`/crm-core/customer/bysubsid/${subscriptionId}`)
    .then((res) => res.data.data);

export const getAllSubscriptions = async (status: string | null) => {
  const response = await axiosInstance.get("/crm-subscription/subscription", {
    params: {
      status: status,
    },
  });
  return response.data;
};

export const getDailyRenew = async () => {
  const response = await axiosInstance.get(
    "/crm-subscription/subscription/daily/renew"
  );
  return response.data;
};

export const getExpiredRenew = async () => {
  const response = await axiosInstance.get(
    "/crm-subscription/subscription/erba"
  );
  return response.data;
};

export const getExpiredEnded = async () => {
  const response = await axiosInstance.get(
    "/crm-subscription/subscription/eba"
  );
  return response.data;
};

export const getPaymentsOfSubscription = async (
  subscriptionId: number | undefined | null
): Promise<IPayment[]> =>
  await axiosInstance
    .get(`/crm-subscription/subscription/payments`, {
      params: {
        subscriptionId: subscriptionId,
      },
    })
    .then((res) => {
      //console.log("res:", res);
      return res.data.data;
    });
