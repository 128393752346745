import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PickList } from "primereact/picklist";
import { Toast } from "primereact/toast";
import { IContentGroup, IContentGroupOc } from "@/models";
import { initialContentGroupOc,  } from "../../constants/index";
import {
  useGetContentGroups,
  useGetVideoContents,
  useAddContentGroupToContent,
  useRemoveContentGroupFromContent,
} from "../../hooks/index";


const AddVideoContentGroup = () => {
  const [contentGroup, setContentGroup] = useState<IContentGroupOc>(
    initialContentGroupOc
  );

  let { id } = useParams();
  const toast = useRef<Toast>(null);
  const {videoContents} = useGetVideoContents();
  const { contentGroups, getContentGroupsIsLoading } = useGetContentGroups();
  const { mutate: addContentGroupToContent } = useAddContentGroupToContent(toast);
  const { mutate: removeContentGroupFromContent } = useRemoveContentGroupFromContent(toast);

  const [source, setSource] = useState<IContentGroup[]>([]);
  const [target, setTarget] = useState<IContentGroup[]>([]);

  useEffect(() => {
    id && setContentGroup({ ...contentGroup, cntId: +id });
    const newSource: IContentGroup[] = [];
    const newTarget: IContentGroup[] = [];
    contentGroups?.map((content) => {
      !videoContents?.some((video) => video.id === content.id)
        ? newSource.push(content)
        : newTarget.push(content);
    });

    setSource([...newSource]);
    setTarget([...newTarget]);

  }, [id, getContentGroupsIsLoading]);

  const onChange = (event: any) => {
    setSource(event.source);
    setTarget(event.target);
  };

  const onMove = (event: any, mutate: Function) => {
    const newArray = event.value.map((item: any) => {
      return item.id;
    });

    if (id) {
      const contentGroupObject: IContentGroupOc = {
        cntId: +id,
        cgIds: [...newArray],
        username: "admin",
      };
      mutate(contentGroupObject);
    }
  };

  const onMoveToSource = (event: any) => {
    onMove(event, removeContentGroupFromContent);
  };

  const onMoveToTarget = (event: any) => {
    onMove(event, addContentGroupToContent);
  };

  const onMoveAllToSource = (event: any) => {
    onMove(event, removeContentGroupFromContent);
  };

  const onMoveAllToTarget = (event: any) => {
    onMove(event, addContentGroupToContent);
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.name}</h5>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <PickList
          source={source ?? videoContents}
          target={target ?? contentGroups}
          itemTemplate={itemTemplate}
          sourceHeader="Available"
          targetHeader="Selected"
          sourceStyle={{ height: "342px" }}
          targetStyle={{ height: "342px" }}
          onChange={onChange}
          onMoveToSource={onMoveToSource}
          onMoveToTarget={onMoveToTarget}
          onMoveAllToSource={onMoveAllToSource}
          onMoveAllToTarget={onMoveAllToTarget}
          filterBy="name"
          sourceFilterPlaceholder="Search by name"
          targetFilterPlaceholder="Search by name"
        />
      </div>
    </div>
  );
};

export default AddVideoContentGroup;
