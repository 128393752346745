import { getContentMetadata } from "../../services/index";
import { useQuery } from "react-query";

export const useGetContentMetadataData = (
  id: number | null,
  enabled?: boolean
) => {
  const {
    data: contentMetadata,
    isLoading: getContentMetadataIsLoading,
    refetch: refetchGetContentMetadataData,
  } = useQuery(["contentMetadata", id], getContentMetadata, { enabled: false });
  return {
    contentMetadata,
    getContentMetadataIsLoading,
    refetchGetContentMetadataData,
  };
};
