import { getMessages } from "../../../../services/index";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { DataTableCrud, DataTableSearchHeader } from "../../common";
import { dataFilters, initialMessage } from "../../../../constants";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { toastError, toastSuccess } from "../../../../utils";
import { IMessageDefinition } from "@/models/Entities/messages";
import { MessageDialog } from "../../../Dialog/message";

const MessagesDataTable = () => {
  const toast = useRef<Toast>(null);
  const [triggerRefresh, setTriggerRefresh] = useState(true);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [showMessageResponseDialog, setShowMessageResponseDialog] =
    useState(false);

  const [messages, setMessages] = useState<IMessageDefinition[]>([]);

  const [selectedMessage, setSelectedMessage] =
    useState<IMessageDefinition>(initialMessage);

  const [customerId, setCustomerId] = useState(0);

  const [filters, setFilters] = useState(dataFilters);

  useEffect(() => {
    try {
      getMessages().then((response) => {
        //console.log("getLabels.response:", response);
        if (response && response.length > 0) {
          setMessages(response);
          setMessagesLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, [triggerRefresh]);

  const openMessageResponseDialog = () => {
    setShowMessageResponseDialog(true);
  };

  const hideMessageResponseDialog = () => {
    setShowMessageResponseDialog(false);
  };

  const openMessageDetailsDialog = (rowData?: IMessageDefinition) => {
    rowData && setSelectedMessage(rowData);
    rowData && setCustomerId(rowData.customerId);
    setShowMessageResponseDialog(true);
  };

  const updateRefreshTrigger = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  const showFail = () => {
    toastError(toast, "Operatin failed.");
  };

  return (
    <>
      <Toast ref={toast} />
      <Row style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button
          label={"New"}
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openMessageResponseDialog}
        />
      </Row>
      <Row>
        <DataTableCrud
          value={messages}
          selection={selectedMessage}
          onSelectionChange={(e) => setSelectedMessage(e.value)}
          header={
            <DataTableSearchHeader
              title="Message List"
              filters={filters}
              onChange={(e: any) => setFilters(e)}
            />
          }
          filters={filters}
          loading={messagesLoading}
          title="messages"
        >
          <Column
            field="id"
            header="ID"
            body={(rowData) => <>{rowData.id}</>}
            sortable
          />
          <Column
            field="subject"
            header="Subject"
            body={(rowData) => <>{rowData.subject}</>}
            sortable
          />
          <Column
            field="message"
            header="Message"
            body={(rowData) => <>{rowData.message}</>}
            sortable
          />

          <Column
            field="status"
            header="Status"
            body={(rowData) => (
              <>
                {rowData.status == "NEW" ? (
                  <Badge value="NEW" severity="warning" />
                ) : (
                  <Badge value="RESPONDED" severity="success" />
                )}
              </>
            )}
            sortable
          />

          <Column
            header="Action"
            body={(rowData: IMessageDefinition) => (
              <>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-info m-1"
                  onClick={() => openMessageDetailsDialog(rowData)}
                ></Button>
              </>
            )}
          />
        </DataTableCrud>
      </Row>
      <MessageDialog
        customerId={customerId}
        visible={showMessageResponseDialog}
        onHide={hideMessageResponseDialog}
        refreshTable={updateRefreshTrigger}
      />
    </>
  );
};

export default MessagesDataTable;
