import { deleteProvider } from "../../services/index";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../utils/index";

export const useDeleteProviderData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: deleteProviderIsLoading } = useMutation(
    async () => await deleteProvider(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("providers");
        toastSuccess(toast, "Provider Deleted");
      },
      onError: () => {
        toastError(toast, "Provider not Deleted");
      },
    }
  );
  return {
    mutate,
    deleteProviderIsLoading,
  };
};
