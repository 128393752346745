import { MainBoardItemDatatable } from "../../components/index";

const MainBoardItems = () => {
  return (
    <div className="datatable-crud">
      <div className="card">
        <MainBoardItemDatatable />
      </div>
    </div>
  );
};

export default MainBoardItems;
