import { ProductDataTable } from "../../components/index";
import React from "react";

const Products = () => {
  return (
    <div className="datatable-crud">
      <div className="card">
        <ProductDataTable />
      </div>
    </div>
  );
};

export default Products;
