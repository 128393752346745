import { Row } from "react-bootstrap";
import { getExpiredEnded, getExpiredRenew } from "../../../../services";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import ISubscription from "../../../../models/Entities/customer/ISubscription";
import moment from "moment";
import SubscriptionInfoDialog from "../../../../components/Dialog/payment/SubscriptionInfoDialog";
import PaymentList from "../../../../components/Dialog/payment/PaymentList";

const ExpiredEnded = () => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshList, setRefreshList] = useState(true);

  const [subscription, setSubscription] = useState<ISubscription>();
  const [subDetailDialog, setSubDetailDialog] = useState(false);
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);

  useEffect(() => {
    try {
      getExpiredEnded().then((response) => {
        //console.log("getAllSubscriptions.response.data:", response.data);
        if (response && response.data) {
          setSubscriptions(response.data);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  const triggerRefresh = () => {
    setRefreshList(!refreshList);
  };

  const openSubsDetailsDialog = (subscription?: ISubscription) => {
    subscription && setSubscription(subscription);
    setSubDetailDialog(true);
  };

  const openPaymentsDialog = (subscription?: ISubscription) => {
    subscription && setSubscription(subscription);
    console.log("subscription", subscription);
    setShowPaymentsDialog(true);
  };

  const hideSubsDetailsDialog = () => {
    setSubDetailDialog(false);
  };

  const hidePaymentListDialog = () => {
    setShowPaymentsDialog(false);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">
        Expired Ended Subscriptions
      </span>
      <Button icon="pi pi-refresh" onClick={triggerRefresh} />
    </div>
  );
  const footer = `In total there are ${
    subscriptions ? subscriptions.length : 0
  } record.`;

  return (
    <>
      <Row>
        <DataTable
          value={subscriptions}
          tableStyle={{ width: "100%" }}
          header={header}
          footer={footer}
        >
          <Column
            field="subId"
            header="ID"
            body={(rowData) => <>{rowData?.id}</>}
            sortable
            style={{ width: "5%" }}
          />
          <Column
            field="endDate"
            header="End Date"
            body={(rowData) => (
              <>
                {rowData.endDate &&
                  moment(rowData.endDate).format("DD/MM/YYYY")}
              </>
            )}
            sortable
            style={{ width: "20%" }}
          />

          <Column
            field="custEmail"
            header="Email"
            body={(rowData) => <>{rowData?.customer.email}</>}
            sortable
            style={{ width: "30%" }}
          />
          <Column
            field="productName"
            header="Product"
            body={(rowData) => <>{rowData?.productP.name}</>}
            sortable
            style={{ width: "25%" }}
          />
          <Column
            field="channel"
            header="Channel"
            body={(rowData) => <>{rowData?.channel}</>}
            sortable
          />
          <Column
            body={(rowData: ISubscription) => (
              <>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-outlined m-1"
                  onClick={() => openSubsDetailsDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
                <Button
                  icon="pi pi-paypal"
                  className="p-button-secondary p-button-rounded p-button-outlined m-1"
                  onClick={() => openPaymentsDialog(rowData)}
                  style={{ width: "2rem", height: "2rem" }}
                />
              </>
            )}
            exportable={false}
            style={{ width: "10%" }}
          />
        </DataTable>
      </Row>
      <SubscriptionInfoDialog
        subscriptionId={subscription?.id}
        merchantOid={"" + subscription?.id}
        visible={subDetailDialog}
        onHide={hideSubsDetailsDialog}
      />
      <PaymentList
        onHide={hidePaymentListDialog}
        subscriptionId={subscription?.id}
        visible={showPaymentsDialog}
      />
    </>
  );
};

export default ExpiredEnded;
