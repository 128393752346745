import { IVideoContent } from "../../models/index";
import { addVideoContent } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddVideoContentData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addVideoContentIsLoading } = useMutation(
    async (videoContent: IVideoContent) => await addVideoContent(videoContent),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("videoContents");
        toastSuccess(toast, "VideoContent Added");
      },
      onError: () => {
        toastError(toast, "VideoContent not Added");
      },
    }
  );
  return {
    mutate,
    addVideoContentIsLoading,
  };
};
