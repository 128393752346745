import React, { useState, useRef, useEffect, SyntheticEvent } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { IContentPosterP, IPosterDefinition } from "../../../../models/index";
import { initialPosterDefinition } from "../../../../constants/index";
import { useAddPosterImagesData } from "../../../../hooks/index";
import { ImageDialog } from "../../../index";
import NoImage from "../../../../assets/images/no-image.png";
import { toastError, toastSuccess } from "../../../../utils/index";
import { addPosterImages } from "../../../../services";

interface PostersDialogProps {
  posterDefinitions: IPosterDefinition[] | undefined;
  contentPosters: IContentPosterP[] | undefined;
  visible: boolean;
  isLoading: boolean;
  videoContentId: number;
  onHide: () => void;
}

const PostersDialog: React.FC<PostersDialogProps> = ({
  posterDefinitions,
  contentPosters,
  isLoading,
  videoContentId,
  visible,
  onHide,
}) => {
  const [posterDefinition, setPosterDefinition] = useState<IPosterDefinition>(
    initialPosterDefinition
  );
  const [imageDialog, setImageDialog] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);

  const [newPosterDefinitions, setNewPosterDefinitions] = useState<
    IPosterDefinition[]
  >([]);

  const toast = useRef<Toast>(null);
  const formData = new FormData();

  const { mutate: addPosterImagesMutate } = useAddPosterImagesData(toast);

  const [refreshTable, setRefreshTable] = useState(false);

  const openImageDialog = (rowData: IPosterDefinition) => {
    setPosterDefinition({ ...rowData });
    setImageDialog(true);
  };

  const hideImageDialog = () => {
    setImageDialog(false);
  };

  const saveImage = () => {
    formData.append("pid", posterDefinition.id?.toString() ?? "");
    formData.append("cid", videoContentId?.toString() ?? "");
    formData.append(
      "userid",
      JSON.parse(localStorage.getItem("user") ?? "")?.id ?? ""
    );
    formData.append(
      "username",
      JSON.parse(localStorage.getItem("user") ?? "")?.username ?? ""
    );
    formData.append("file", fileList[0]);
    //addPosterImagesMutate(formData);

    addPosterImages(formData)
      .then((response) => {
        // İşlem başarıyla tamamlandı
        console.log("Response:", response);
        if (response.data.code === 200) {
          toastSuccess(toast, "Poster Images Added");
        } else {
          console.log(response.data.message);
          toastError(toast, "Failed. " + response.data.message);
        }
      })
      .catch((error) => {
        // İşlem sırasında bir hata oluştu
        console.error("Error:", error);
        // Hata durumunda yapılacak işlemleri burada gerçekleştirebilirsiniz
      });

    setFileList([]);
  };

  const refreshtContentTable = () => {
    setRefreshTable(!refreshTable);
  };

  const showFail = (message: string) => {
    toastError(toast, message ? message : "Operation failed.");
  };

  const getPosterDefinitionsWithUrl = async () => {
    return await posterDefinitions?.map((item) => {
      item.url = NoImage;
      contentPosters?.map((contentPoster) => {
        if (item.id === contentPoster.posterDefinition?.id) {
          item.url = contentPoster.cfPosterUrl;
          //console.log("contentPoster.cfPosterUrl:", contentPoster.cfPosterUrl);
          //console.log("item.url:", item.url);
        }
        return item;
      });
      return item;
    });
  };

  useEffect(() => {
    if (!isLoading) {
      getPosterDefinitionsWithUrl().then((data) => {
        data && setNewPosterDefinitions([...data]);
      });
    }
  }, [contentPosters, isLoading, posterDefinitions, imageDialog, refreshTable]);

  const imageBodyTemplate = (rowData: IContentPosterP) => {
    //console.log("rowData:", rowData);
    return (
      <Image
        src={`${rowData.url}`}
        alt="contentPoster"
        height="80"
        width="auto"
        preview
        className="contentPoster-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = NoImage;
        }}
      />
    );
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Posters</span>
      <Button icon="pi pi-refresh" onClick={refreshtContentTable} />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      style={{ width: "60%" }}
      className="p-fluid"
      onHide={onHide}
      draggable={false}
      resizable={false}
    >
      <Toast ref={toast} />
      <div className="">
        <DataTable
          value={newPosterDefinitions}
          showGridlines
          responsiveLayout="scroll"
          loading={isLoading}
          header={header}
        >
          <Column field="url" header="Poster" body={imageBodyTemplate} />
          <Column field="name" header="Name" />
          <Column field="fileSize" header="Max File Size" />
          <Column field="width" header="Width" />
          <Column field="height" header="Height" />
          <Column
            header="Upload/View"
            body={(rowData: IPosterDefinition) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  icon="pi pi-images"
                  className="p-button-rounded"
                  onClick={() => openImageDialog(rowData)}
                />
              </div>
            )}
            exportable={false}
            style={{
              maxWidth: "8rem",
            }}
          />
        </DataTable>

        <ImageDialog
          visible={imageDialog}
          onHide={hideImageDialog}
          addImage={saveImage}
          fileList={fileList}
          setFileList={setFileList}
          multiple={false}
          posterDefinition={posterDefinition}
        />
      </div>
    </Dialog>
  );
};

export default PostersDialog;
