import { IFieldProps } from "@/models";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import React from "react";
import { useTranslation } from "react-i18next";

interface DropdownFieldProps extends DropdownProps, IFieldProps {}

const DropdownField: React.FC<DropdownFieldProps> = ({
  getFormErrorMessage,
  labelName,
  labelText,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className="field">
      <label htmlFor={labelName}>{t(labelText)}</label>
      <Dropdown {...rest} />
      {getFormErrorMessage != null && getFormErrorMessage()}
    </div>
  );
};

export default DropdownField;
