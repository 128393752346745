import React, { useState, useEffect, useRef } from "react";
import { PickList } from "primereact/picklist";
import { Toast } from "primereact/toast";
import { IContentGroup, IContentGroupOc, IContentGroupOp } from "@/models";
import { initialContentGroupOp } from "../../../../constants/index";
import {
  useGetCGroupsOfContentData,
  useAddContentGroupToContent,
  useRemoveContentGroupFromContent,
} from "../../../../hooks/index";
import { Dialog } from "primereact/dialog";

interface AddContentGroupDialogProps {
  contentName: string;
  visible: boolean;
  isLoading: boolean;
  videoContentId: number;
  contentGroups: IContentGroup[] | undefined;
  onHide: () => void;
}

const AddContentGroupDialog: React.FC<AddContentGroupDialogProps> = ({
  contentName,
  isLoading,
  videoContentId,
  contentGroups,
  visible,
  onHide,
}) => {
  const [contentGroup, setContentGroup] = useState<IContentGroupOp>(
    initialContentGroupOp
  );

  //console.log("videoContentId:", videoContentId);
  //console.log("contentGroups:", contentGroups);

  //let { id } = useParams();
  const toast = useRef<Toast>(null);

  //const { productGroups } = useGetProductGroups(id);
  //const { contentGroups, getContentGroupsIsLoading } = useGetContentGroups();
  const { mutate: addContentGroupMutate } = useAddContentGroupToContent(toast);
  const { mutate: deleteContentGroupMutate } =
    useRemoveContentGroupFromContent(toast);

  const { cGroupsOfContentData, getCgroupsOfContentIsLoading } =
    useGetCGroupsOfContentData(videoContentId);

  const [source, setSource] = useState<IContentGroup[]>([]);
  const [target, setTarget] = useState<IContentGroup[]>([]);

  useEffect(() => {
    videoContentId &&
      setContentGroup({ ...contentGroup, pId: +videoContentId });
    const newSource: IContentGroup[] = [];
    const newTarget: IContentGroup[] = [];
    // eslint-disable-next-line array-callback-return
    contentGroups?.map((content) => {
      !cGroupsOfContentData?.some((cGroups) => cGroups.id === content.id)
        ? newSource.push(content)
        : newTarget.push(content);
    });

    setSource([...newSource]);
    setTarget([...newTarget]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoContentId, getCgroupsOfContentIsLoading, visible]);

  const onChange = (event: any) => {
    setSource(event.source);
    setTarget(event.target);
  };

  const onMove = (event: any, mutate: Function) => {
    const newArray = event.value.map((item: any) => {
      return item.id;
    });

    if (videoContentId) {
      const contentGroupObject: IContentGroupOc = {
        cntId: +videoContentId,
        cgIds: [...newArray],
        username: "admin",
      };
      mutate(contentGroupObject);
    }
  };

  const onMoveToSource = (event: any) => {
    onMove(event, deleteContentGroupMutate);
  };

  const onMoveToTarget = (event: any) => {
    onMove(event, addContentGroupMutate);
  };

  const onMoveAllToSource = (event: any) => {
    onMove(event, deleteContentGroupMutate);
  };

  const onMoveAllToTarget = (event: any) => {
    onMove(event, addContentGroupMutate);
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="mb-2">{item.name}</h5>
        </div>
      </div>
    );
  };

  const hideDialog = () => {
    onHide();
  };

  return (
    <Dialog
      visible={visible}
      style={{ width: "60%" }}
      header={"ContentGroups of '" + contentName + "'"}
      modal
      className="p-fluid"
      onHide={hideDialog}
    >
      <Toast ref={toast} />
      <div className="">
        <PickList
          source={source ?? contentGroups}
          target={target ?? null}
          itemTemplate={itemTemplate}
          sourceHeader="Available"
          targetHeader="Selected"
          sourceStyle={{ height: "342px" }}
          targetStyle={{ height: "342px" }}
          onChange={onChange}
          onMoveToSource={onMoveToSource}
          onMoveToTarget={onMoveToTarget}
          onMoveAllToSource={onMoveAllToSource}
          onMoveAllToTarget={onMoveAllToTarget}
          filterBy="name"
          sourceFilterPlaceholder="Search by name"
          targetFilterPlaceholder="Search by name"
        />
      </div>
    </Dialog>
  );
};

export default AddContentGroupDialog;
