import { VideoContentDataTable } from "../../components/index";

const VideoContents = () => {
  return (
    <div className="datatable-crud">
      <div className="card">
        <VideoContentDataTable />
      </div>
    </div>
  );
};

export default VideoContents;
