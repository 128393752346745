import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { getInvoicesCount } from "../../../services";

const InvoiceHistogram = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchFailedInvoiceCount();
  }, []);

  const fetchFailedInvoiceCount = async () => {
    try {
      const response = await getInvoicesCount();
      setData(response);

    } catch (error) {
      console.error('Failed to fetch failed invoice count:', error);
    }
  };

  const chartData = {
    labels: data.map(item => item[0]),
    datasets: [
      {
        data: data.map(item => item[1]),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#32CD32'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#32CD32'],
      },
    ],
  };


  const options = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };

  return (
    <div>
      <h4>Invoice Status</h4>
      <Chart type="doughnut" data={chartData} options={options} style={{ display: "initial" }} />
    </div>
  );
};

export default InvoiceHistogram;
