import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { AuthWrapper, DashboardWrapper } from "../layouts/index";
import { ProgressSpinner } from "primereact/progressspinner";
import { useAuth } from "../hooks/index";
import { axiosInstance } from "../api/axios.instance";

export const PrivateRoute = () => {
  const {
    loggedUser: { user, isLoading },
  } = useAuth();

  useEffect(() => {
    axiosInstance.defaults.headers.common["Authorization"] = user?.token;
    //console.log("user?.role:", user?.role);
  }, [user]);

  return isLoading ? (
    <AuthWrapper>
      <ProgressSpinner
        className="p-progress-spinner"
        style={{ width: "50px", height: "50px" }}
        strokeWidth="5"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </AuthWrapper>
  ) : user ? (
    <DashboardWrapper>
      <Outlet />
    </DashboardWrapper>
  ) : (
    <Navigate to="/login" />
  );
};
