import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginUser as userLogin } from "../../../services/index";
import { forceLoginUser as userForceLogin } from "../../../services/index";
import { logOutUser } from "../../../services/index";
import { IUser, ILoginResponse } from "../../../models/index";
import { loadStorage } from "../../../utils/index";
import { IAuthState } from "../../types/authTypes";
import { RootState } from "../../store";

const initialState: IAuthState = {
  user: loadStorage<IUser>("user") ?? null,
  isSuccess: loadStorage<boolean>("isSuccess") ?? false,
  errorMessage: null,
  isActiveSession: null,
  isLoading: loadStorage<boolean>("isLoading") ?? false,
};

export const login = createAsyncThunk(
  "auth/login",
  async (data: { username: string; password: string }, { rejectWithValue }) => {
    const { username, password } = data;
    try {
      const response = await userLogin(username, password);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const forceLogin = createAsyncThunk(
  "auth/forcelogin",
  async (data: { username: string; password: string }, { rejectWithValue }) => {
    const { username, password } = data;
    try {
      const response = await userForceLogin(username, password);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const logOut = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await logOutUser();
    return response;
  } catch (error: any) {
    return console.log(error.response.data.message);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<ILoginResponse | any>) => {
        state.isSuccess = true;
        state.errorMessage = null;
        state.isActiveSession = !action.payload.data && action.payload.message;
        state.user = action.payload.data;
        state.isLoading = false;
      }
    );
    builder.addCase(login.rejected, (state, action) => {
      state.errorMessage = action.payload as string;
      state.isSuccess = false;
      state.isLoading = false;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      forceLogin.fulfilled,
      (state, action: PayloadAction<ILoginResponse>) => {
        state.isSuccess = true;
        state.errorMessage = null;
        state.isActiveSession = null;
        state.user = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(forceLogin.rejected, (state, action) => {
      state.errorMessage = action.payload as string;
      state.isSuccess = false;
      state.isLoading = false;
    });
    builder.addCase(forceLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(logOut.fulfilled, (state) => {
      state.user = null;
      state.isSuccess = true;
      state.errorMessage = null;
      state.isLoading = false;
    });
    builder.addCase(logOut.rejected, (state, action) => {
      state.errorMessage = action.payload as string;
      state.isSuccess = false;
      state.isLoading = false;
    });
    builder.addCase(logOut.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectIsSuccess = (state: RootState) => state.auth.isSuccess;
export const selectErrorMessage = (state: RootState) => state.auth.errorMessage;
export const selectIsActiveSession = (state: RootState) =>
  state.auth.isActiveSession;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;

export default authSlice.reducer;
