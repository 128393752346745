import React, { useRef, useState } from 'react'
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { DataTableDeleteDialog, ProviderDialog  } from '../../../Dialog';
import { DataTableActionTemplate, DataTableCrud, DataTableSearchHeader, DataTableToolbar } from '../../common';
import { IProvider } from '../../../../models/index';
import {  initialProvider, providerFilters } from '../../../../constants';
import { useAddProviderData, useDeleteProviderData, useGetProvidersData, useUpdateProviderData } from '../../../../hooks/provider/index';

const ProviderDataTable = () => {
  const [selectedProvider, setSelectedProvider] = useState<IProvider | null>(null);
  const [provider, setProvider] = useState<IProvider>(initialProvider);
  const [deleteProviderDialog, setDeleteProviderDialog] = useState(false);
  const [providerDialog, setProviderDialog] = useState(false);
  const [filters, setFilters] = useState(providerFilters);
  const [isAddItem, setIsAddItem] = useState(false);
    

  const toast = useRef<Toast>(null);
  
  const { mutate: addProviderMutate, addProviderIsLoading } =
    useAddProviderData(toast);
  const { mutate: updateProviderMutate } = useUpdateProviderData(toast);
  const { mutate: deleteProviderMutate } = useDeleteProviderData(toast);
  const { providers, getProvidersIsLoading } = useGetProvidersData();


    const openAddProviderDialog = () => {
    setProvider(initialProvider);
    setIsAddItem(true);
    setProviderDialog(true);
    };
    
    const openDeleteProviderDialog = (provider?: IProvider) => {
    provider && setProvider(provider);
    setDeleteProviderDialog(true);
    };
    
    const openEditProviderDialog = (rowData: IProvider) => {
    setProvider({ ...rowData });
    setIsAddItem(false);
    setProviderDialog(true);
  };

    const hideProviderDialog = () => {
    setProviderDialog(false);
  };

  const hideDeleteProviderDialog = () => {
    setDeleteProviderDialog(false);
    };
    
    const deleteProvider = () => {
    deleteProviderMutate();
    hideDeleteProviderDialog();
  };

   const saveProvider = (newProvider: IProvider) => {
    isAddItem ? addProviderMutate(newProvider) : updateProviderMutate(newProvider);
    !isAddItem && hideProviderDialog();
  };


  return (
<>
      <Toast ref={toast} />
      <DataTableToolbar
        disableDeleteButton={!selectedProvider}
        openAddDialog={openAddProviderDialog}
        openDeleteDialog={openDeleteProviderDialog}
        showDeleteButton={true}
      />

      <DataTableCrud
        value={providers}
        selection={selectedProvider}
        onSelectionChange={(e) => setSelectedProvider(e.value)}
        header={
          <DataTableSearchHeader
            title={"Provider Management"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        loading={false}
        title="providers"
      >

        <Column
          selectionMode="single"
          headerStyle={{ width: "3rem" }}
          exportable={false}
        />
       
        <Column
          field="name"
          header={"Name"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="shortName"
          header={"Short Name"}
          sortable
          style={{ minWidth: "11rem" }}
        />
        <Column
          field="country"
          header={"Country"}
          sortable
          style={{ minWidth: "8rem" }}
        />
        <Column
          field="contact"
          header={"Contact"}
          sortable
          style={{ minWidth: "4rem" }}
        />
        <Column
          field="gsmno"
          header={"Gsm No"}
          sortable
          style={{ minWidth: "3rem" }}
              />
              <Column
          field="status"
          header={"Status"}
          sortable
          style={{ minWidth: "3rem" }}
        />
        
        <Column
          body={(rowData: IProvider) => (
            <DataTableActionTemplate
              openDeleteDialog={() => openDeleteProviderDialog(rowData)}
              openEditDialog={() => openEditProviderDialog(rowData)}
            />
          )}
          header={"Action"}
          exportable={false}
          style={{ minWidth: "12rem" }}
        />
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteProviderDialog}
        data={selectedProvider ?? provider}
        onHide={hideDeleteProviderDialog}
        onDelete={deleteProvider}
      />

      <ProviderDialog
        visible={providerDialog}
        provider={provider}
        isLoading={addProviderIsLoading}
        onHide={hideProviderDialog}
        addProvider={saveProvider}
      />
    </> 
  )
}

export default ProviderDataTable;