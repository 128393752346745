import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import React from "react";

interface DataTableToolbarProps {
  disableDeleteButton: boolean;
  openDeleteDialog: () => void;
  openAddDialog: () => void;
  showDeleteButton: boolean;
}

const DataTableToolbar: React.FC<DataTableToolbarProps> = ({
  disableDeleteButton,
  openAddDialog,
  openDeleteDialog,
  showDeleteButton,
}) => {
  const leftToolbarTemplate = () => {
    return (
      <>
        <Button
          label={"New"}
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openAddDialog}
        />
        {showDeleteButton && (
        <Button
          label={"Delete"}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={openDeleteDialog}
          disabled={disableDeleteButton}
        />)}
      </>
    );
  };

  return <Toolbar className="mb-4" left={leftToolbarTemplate} />;
};

export default DataTableToolbar;
