import { DropdownField } from "../../../FormFields";
import { ILabelDefinition, IVideoContent } from "../../../../models";
import IVideoContentMinimize from "../../../../models/Entities/videoContent/IVideoContentMinimize";
import {
  addContentToLabel,
  addContentToLabelSingle,
  getAllContentsNotOfLabel,
} from "../../../../services";
import React, { useEffect, useRef, useState } from "react";
import { DataTableDialogFooter } from "../../common";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { toastError, toastSuccess } from "../../../../utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataTableStatusTemplate } from "../../../../components/DataTable";
import { Button } from "primereact/button";

interface AddContentToLabelDialogProps {
  visible: boolean;
  onHide: () => void;
  seletedLabel: ILabelDefinition;
  refreshTable: () => void;
}

const AddContentToLabelDialog: React.FC<AddContentToLabelDialogProps> = ({
  visible,
  onHide,
  seletedLabel,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContentForAdd, setSelectedContentForAdd] =
    useState<IVideoContentMinimize | null>(null);

  const [contentList, setContentList] = useState<
    IVideoContentMinimize[] | undefined
  >([]);
  const [listChanged, setListChanged] = useState(false);

  useEffect(() => {
    //console.log("seletedLabel:", seletedLabel);
    try {
      seletedLabel.id &&
        getAllContentsNotOfLabel(seletedLabel.id, seletedLabel.urlName).then(
          (response) => {
            //console.log("getAllContentsNotOfLabel.response:", response);
            if (response && response.length > 0) {
              setContentList(response);
            }
          }
        );
    } catch (error) {}
  }, [seletedLabel, visible, listChanged]);

  const handleSubmit = () => {
    //console.log("selectedContentForAdd:", selectedContentForAdd);

    //const contentIdList: number[] = [];
    //selectedContentForAdd && contentIdList.push(selectedContentForAdd?.id);

    addContentToLabelSingle(seletedLabel.id, selectedContentForAdd?.id).then(
      (response) => {
        //addContentToLabel(seletedLabel.id, contentIdList).then((response) => {
        //console.log("addContentToLabel.response:", response);
        if (response && response.status === 200) {
          //console.log("addContentToLabel.response:", response);
          if (response.data.code === 500) {
            console.log("message:", response.data.message);
            showFail(response.data.message);
          } else {
            showSuccess();
          }
          refreshTable();
          setListChanged(!listChanged);
        } else {
        }
      }
    );

    setSelectedContentForAdd(null);
    //setContentList([]);
    refreshTable();
    //onHide();
  };

  const addToLabel = (rowData: IVideoContentMinimize) => {
    setSelectedContentForAdd(rowData);
    console.log("selected:", selectedContentForAdd);
  };

  useEffect(() => {
    if (selectedContentForAdd) {
      console.log("selected:", selectedContentForAdd);
      handleSubmit();
    }
  }, [selectedContentForAdd]);

  const onHideLocal = () => {
    setSelectedContentForAdd(null);
    setContentList([]);
    refreshTable();
    onHide();
  };

  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  const showFail = (message: string) => {
    toastError(toast, message ? message : "Operation failed.");
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "50%" }}
        header={"Add Content To " + seletedLabel.name}
        modal
        className="p-fluid"
        footer={
          <Button
            label={"Close"}
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHideLocal}
          />
        }
        onHide={onHide}
      >
        {/* dialog footer
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHideLocal}
            handleSubmit={handleSubmit}
          />
        }
        */}
        {/*
        <div>
          <DropdownField
            id="contentsForAdd"
            name="name"
            value={selectedContentForAdd}
            onChange={(e) => setSelectedContentForAdd(e.target.value)}
            options={contentList?.map((content) => ({
              value: content,
              label: content.name + " - " + content.id,
            }))}
            labelName={"Available Contents"}
            labelText={"Available Contents"}
          />
        </div>
        */}
        <div className="">
          <DataTable
            value={contentList}
            responsiveLayout="scroll"
            paginator
            rows={15}
            rowsPerPageOptions={[5, 10, 25, 50]}
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            filterDisplay="row"
          >
            <Column field="id" header="id"></Column>
            <Column
              field="name"
              header="Name"
              filter
              filterPlaceholder="Search by name"
            ></Column>

            <Column
              field="status"
              header={"Status"}
              style={{ maxWidth: "18rem", minWidth: "14.5rem" }}
              body={(rowData: IVideoContent) => (
                <DataTableStatusTemplate status={rowData.status} />
              )}
            />

            <Column
              body={(rowData: IVideoContentMinimize) => (
                <>
                  <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-outlined m-1"
                    onClick={() => addToLabel(rowData)}
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </>
              )}
              exportable={false}
              style={{ width: "10%" }}
            />
          </DataTable>
        </div>
      </Dialog>
    </>
  );
};

export default AddContentToLabelDialog;
