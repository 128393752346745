import * as Yup from "yup";

export const videoContentSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter at least 2 characters!")
    .max(50, "You can enter up to 50 characters!")
    .required("Name is required"),
  type: Yup.string()
    .oneOf(["MOVIE", "SERIES", "SEASON", "EPISODE"])
    .required("Type is required"),

  parentId: Yup.number().when("type", {
    is: (val: any) => val === "SEASON", // "SEASON" olduğunda zorunlu yap
    then: Yup.number()
      .required("Bu alanı doldurmalısınız.") // Özel hata mesajı
      .typeError("Bu alanı doldurmalısınız."), // Eğer tip hatası olursa gösterilecek mesaj,
    otherwise: Yup.number().nullable(), // Diğer durumlarda boş olabilir
  }),

  seasonNo: Yup.number().when("type", {
    is: (val: any) => val === "SEASON", // "SEASON" olduğunda zorunlu yap
    then: Yup.number()
      .required("Bu alanı doldurmalısınız.") // Özel hata mesajı
      .typeError("Bu alan sayısal bir değer olmalıdır.") // Eğer tip hatası olursa gösterilecek mesaj
      .positive("Sezon numarası pozitif olmalıdır.")
      .integer("Sezon numarası tam sayı olmalıdır."),
    otherwise: Yup.number().nullable(), // Diğer durumlarda boş olabilir
  }),

  episodeNo: Yup.number().when("type", {
    is: (val: any) => val === "EPISODE", // "SEASON" olduğunda zorunlu yap
    then: Yup.number()
      .required("Bu alanı doldurmalısınız.") // Özel hata mesajı
      .typeError("Bu alan sayısal bir değer olmalıdır.") // Eğer tip hatası olursa gösterilecek mesaj
      .positive("Sezon numarası pozitif olmalıdır.")
      .integer("Sezon numarası tam sayı olmalıdır."),
    otherwise: Yup.number().nullable(), // Diğer durumlarda boş olabilir
  }),
});
