import { QueryFunctionContext } from "react-query";
import { axiosInstance } from "../../api/axios.instance";
import {
  IContentP,
  IVideoContent,
  ILabelDefinition,
  IContentPosterP,
  IPosterDefinition,
  IVdoKeys,
  IReleaseDate,
  ILicense,
  VideoFile,
} from "../../models/index";
import IVideoContentMinimize from "@/models/Entities/videoContent/IVideoContentMinimize";
import VdoContentData from "@/models/Entities/videoContent/VdoContentData";
import IVideoContentOpt from "@/models/Entities/videoContent/IVideoContentOpt";

export const getVideoContents = async (): Promise<IVideoContent[]> =>
  await axiosInstance
    .get(`/contman/content/listscreen`, { params: { count: 500 } })
    .then((res) => res.data.data);

export const getVideoContentSingle = async (
  contentId: number
): Promise<IVideoContentOpt> =>
  await axiosInstance
    .get(`/contman/cntcrd/opt/${contentId}`)
    .then((res) => res.data.data);

export const getVideoContentMp4Files = async (contentId: number) =>
  await axiosInstance
    .get(`/contman/cntcrd/files/${contentId}`)
    .then((res) => res.data.data);

export const getSeries = async (): Promise<IContentP[]> =>
  await axiosInstance
    .get(`/contman/content/getseries`)
    .then((res) => res.data.data);

export const getSeasons = async (): Promise<IContentP[]> =>
  await axiosInstance
    .get(`/contman/content/getseasons`)
    .then((res) => res.data.data);

export const getAllDynamic = async (): Promise<ILabelDefinition[]> =>
  await axiosInstance
    .get(`/contman/label/getalldynamic`)
    .then((res) => res.data.data);

export const getAllDynamicWithoutContent = async (): Promise<
  ILabelDefinition[]
> =>
  await axiosInstance
    .get(`/contman/label/dynamics/woc`)
    .then((res) => res.data.data);

export const getPosterDefinitions = async (): Promise<IPosterDefinition[]> =>
  await axiosInstance
    .get(`/contman/posterdefinition/getall`)
    .then((res) => res.data.data);

export const getChilds = async ({
  queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<IContentP[]> => {
  const [_, contentId] = queryKey;
  return await axiosInstance
    .get(`/contman/content/getChilds/${contentId}`)
    .then((res) => res.data.data);
};

export const getContentMetadata = async ({
  queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<IContentP> => {
  const [_, contentId] = queryKey;
  return await axiosInstance
    .get(`/contman/content/getbyid/${contentId}`)
    .then((res) => res.data.data);
};

export const getContentPosters = async ({
  queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<
  IContentPosterP[]
> => {
  const [_, contentId] = queryKey;
  return await axiosInstance
    .get(`/contman/content/getposters/${contentId}`)
    .then((res) => res.data.data);
};

export const updateMetadata = async (metadata: IContentP) =>
  await axiosInstance.put(`/contman/cntcrd`, metadata);

export const addPosterImages = async (posterImages: FormData) =>
  await axiosInstance.post(`/contman/cpo/posterupload/v3`, posterImages, {
    //await axiosInstance.post(`/contman/posters/upload`, posterImages, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

//

// export const getVideoContentDetails = async ({
//   queryKey,
// }: QueryFunctionContext<
//   [string, number | null]
// >): Promise<IVideoContent> => {
//   const [_, id] = queryKey;
//   return await axiosInstance
//     .get(`/contman/content/getbyid/${id}`)
//     .then((res) => res.data.data);
// };

export const addVideoContent = async (videoContent: IVideoContent) =>
  await axiosInstance.post(`/contman/cntcrd`, videoContent);

export const updateVideoContent = async (videoContent: IVideoContent) =>
  await axiosInstance.put(`/videocontent`, videoContent);

export const deleteVideoContent = async (videoContentId: number) =>
  await axiosInstance.delete(`/contman/content/delete/${videoContentId}`);

export const getLicensesOfContent = async ({
  queryKey,
}: QueryFunctionContext<[string, number | undefined]>): Promise<
  IContentP[]
> => {
  const [_, contentId] = queryKey;

  return await axiosInstance
    .get(`/contman/contents/licenses/${contentId}`)
    .then((res) => res.data.data);
};

export const getLicensesOfContentV2 = async (
  contentId: number | undefined
): Promise<ILicense[]> => {
  return await axiosInstance
    .get(`/contman/contents/licenses/${contentId}`)
    .then((res) => res.data.data);
};

export const getSchdulesOfContent = async (
  contentId: number | undefined
): Promise<IReleaseDate[]> => {
  return await axiosInstance
    .get(`/contman/contentreleasedate/getbycontent/${contentId}`)
    .then((res) => res.data.data);
};

export const deleteSchdulesOfContent = async (contentId: number | undefined) =>
  await axiosInstance.delete(`/contman/contentreleasedate/delete/${contentId}`);

export const getCGsOfContent = async ({
  queryKey,
}: QueryFunctionContext<[string, number | undefined]>): Promise<
  IContentP[]
> => {
  const [_, contentId] = queryKey;

  //console.log("contentId?", contentId);
  return await axiosInstance
    .get(`/contman/cntgrp/ofcontent/${contentId}`)
    .then((res) => res.data.data);
};

export const updateVideoContentNameAndVdoId = async (
  videoContent: IVideoContent
) => {
  const { id, name, vdoId, vdoTrailerId } = videoContent;
  try {
    const response = await axiosInstance.put(`/contman/cntcrd/name`, null, {
      params: {
        contentId: id,
        name: name,
        vdoId: vdoId,
        vdoTrailerId: vdoTrailerId,
      },
    });

    // response ile ilgili işlemleri burada yapabilirsiniz (örneğin, başarılı yanıt kontrolü)
    //console.log("Başarılı yanıt:", response.data);
    return response.data;
  } catch (error) {
    // Hata durumunda yapılacak işlemleri burada yönetebilirsiniz
    console.error("Hata oluştu:", error);
    throw error;
  }
};

export const getVdoContentKeys = async (
  vdoId: number | string
): Promise<IVdoKeys> =>
  await axiosInstance
    .get(`/contman/vdo/keys/content?cid=${vdoId}`) //?cid=48
    .then((res) => res.data.data);

export const getVdoContentTrailerKeys = async (
  vdoId: number | string
): Promise<IVdoKeys> =>
  await axiosInstance
    .get(`/contman/vdo/keys/content?cid=${vdoId}&ist=1`) //?cid=48
    .then((res) => res.data.data);

export const getVdoContentData = async (
  contentId: number | string
): Promise<VdoContentData> =>
  await axiosInstance
    .get(`/contman/vdo/check/${contentId}`) //?cid=48
    .then((res) => res.data.data);

export const getContentsForPublish = async (
  status: string
): Promise<IVideoContentMinimize[]> =>
  await axiosInstance
    .get(`/contman/content/getalloptimized`, {
      params: { status: status, count: 300 },
    })
    .then((res) => res.data.data);

export const publishContent = async (
  contentReleaseDateP: IReleaseDate | undefined
) =>
  await axiosInstance.post(`/contman/cntpub/publish`, {
    contentReleaseDateP: contentReleaseDateP,
    userID: 1,
    username: "webuser",
  });

export const unPublishContent = async (
  content: IVideoContentMinimize | undefined
) =>
  await axiosInstance
    .post(`/contman/cntpub/unpublish`, {
      contentP: content,
      userID: 1,
      username: "webuser",
    })
    .then((res) => res.data);

export const getFilesFromG = async (
  id: string | undefined
): Promise<VideoFile[]> =>
  await axiosInstance
    .get(`/contman/gdr/all/${id}`)
    .then((res) => res.data.data);

export const addVideoFile = async (contentId: number, driveFileId: string) =>
  await axiosInstance
    .get(`/contman/cmf/add/${contentId}/${driveFileId}/0`)
    .then((res) => res.data);

export const addTrailerVideoFile = async (
  contentId: number,
  driveFileId: string
) =>
  await axiosInstance
    .get(`/contman/cmf/add/${contentId}/${driveFileId}/1`)
    .then((res) => res.data);

export const getSingleFileFromG = async (
  id: string | undefined
): Promise<VideoFile> =>
  await axiosInstance
    .get(`/contman/gdr/file/${id}`)
    .then((res) => res.data.data);

export const deleteVideoContentOnVdo = async (videoContentId: number) =>
  await axiosInstance.delete(`/contman/vdo/${videoContentId}`);

export const deleteTailerContentOnVdo = async (videoContentId: number) =>
  await axiosInstance.delete(`/contman/vdo/${videoContentId}?isTrailer=1`);
