import { getCustomers } from "../../services/index";
import { useQuery } from "react-query";

export const useGetCustomersData = () => {
  const { data: customers, isLoading: getCustomersIsLoading } = useQuery(
    "customers",
    getCustomers
  );
  return { customers, getCustomersIsLoading };
};
