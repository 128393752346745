import React, { useRef, useState } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { DataTableDeleteDialog, LicenseDialog } from "../../../Dialog";
import {
  DataTableActionTemplate,
  DataTableCrud,
  DataTableSearchHeader,
  DataTableToolbar,
} from "../../common";
import { ILicense } from "../../../../models/index";
import { licenseFilters, initialLicense } from "../../../../constants";
import {
  useAddLicenseData,
  useDeleteLicenseData,
  useGetLicensesData,
  useUpdateLicenseData,
} from "../../../../hooks";
import moment from "moment";

const LicenseDataTable = () => {
  const [selectedLicense, setSelectedLicense] = useState<ILicense | null>(null);
  const [license, setLicense] = useState<ILicense>(initialLicense);
  const [deleteLicenseDialog, setDeleteLicenseDialog] = useState(false);
  const [licenseDialog, setLicenseDialog] = useState(false);
  const [filters, setFilters] = useState(licenseFilters);
  const [isAddItem, setIsAddItem] = useState(false);

  const toast = useRef<Toast>(null);

  const { mutate: addLicenseMutate, addLicenseIsLoading } =
    useAddLicenseData(toast);
  const { mutate: updateLicenseMutate } = useUpdateLicenseData(toast);
  const { mutate: deleteLicenseMutate } = useDeleteLicenseData(toast);
  const { licenses, getLicensesIsLoading } = useGetLicensesData();

  const openAddLicenseDialog = () => {
    setLicense(initialLicense);
    setIsAddItem(true);
    setLicenseDialog(true);
  };

  const openDeleteLicenseDialog = (license?: ILicense) => {
    license && setLicense(license);
    setDeleteLicenseDialog(true);
  };

  const openEditLicenseDialog = (rowData: ILicense) => {
    setLicense({ ...rowData });
    setIsAddItem(false);
    setLicenseDialog(true);
  };

  const hideLicenseDialog = () => {
    setLicenseDialog(false);
  };

  const hideDeleteLicenseDialog = () => {
    setDeleteLicenseDialog(false);
  };

  const deleteLicense = () => {
    deleteLicenseMutate();
    hideDeleteLicenseDialog();
  };

  const saveLicense = (newLicense: ILicense) => {
    isAddItem ? addLicenseMutate(newLicense) : updateLicenseMutate(newLicense);
    !isAddItem && hideLicenseDialog();
  };

  return (
    <>
      <Toast ref={toast} />
      <DataTableToolbar
        disableDeleteButton={!selectedLicense}
        openAddDialog={openAddLicenseDialog}
        openDeleteDialog={openDeleteLicenseDialog}
        showDeleteButton={true}
      />

      <DataTableCrud
        value={licenses}
        selection={selectedLicense}
        onSelectionChange={(e) => setSelectedLicense(e.value)}
        header={
          <DataTableSearchHeader
            title={"License Management"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        loading={false}
        title="licenses"
      >
        <Column
          selectionMode="single"
          headerStyle={{ width: "3rem" }}
          exportable={false}
        />

        <Column
          field="name"
          header={"Name"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="contract.name"
          header={"Contract"}
          sortable
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="startDate"
          header={"Start Date"}
          sortable
          style={{ minWidth: "11rem" }}
          body={(rowData) =>
            rowData.startDate && (
              <>{moment(rowData.startDate).format("DD/MM/YYYY")}</>
            )
          }
        />
        <Column
          field="endDate"
          header={"End Date"}
          sortable
          style={{ minWidth: "8rem" }}
          body={(rowData) =>
            rowData.endDate && (
              <>{moment(rowData.endDate).format("DD/MM/YYYY")}</>
            )
          }
        />
        {/*
        <Column
          field="type"
          header={"Type"}
          sortable
          style={{ minWidth: "4rem" }}
        />
        <Column
          field="platforms"
          header={"Platforms"}
          sortable
          style={{ minWidth: "3rem" }}
        />
        <Column
          field="region"
          header={"Region"}
          sortable
          style={{ minWidth: "3rem" }}
        />
*/}
        <Column
          body={(rowData: ILicense) => (
            <DataTableActionTemplate
              openDeleteDialog={() => openDeleteLicenseDialog(rowData)}
              openEditDialog={() => openEditLicenseDialog(rowData)}
            />
          )}
          header={"Action"}
          exportable={false}
          style={{ minWidth: "12rem" }}
        />
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteLicenseDialog}
        data={selectedLicense ?? license}
        onHide={hideDeleteLicenseDialog}
        onDelete={deleteLicense}
      />

      <LicenseDialog
        visible={licenseDialog}
        license={license}
        isLoading={addLicenseIsLoading}
        onHide={hideLicenseDialog}
        addLicense={saveLicense}
      />
    </>
  );
};

export default LicenseDataTable;
