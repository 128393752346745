import { Dialog } from "primereact/dialog";
import React, { useState, useEffect, useRef } from "react";
import { IVideoContent, VideoFile } from "@/models";
import { toastError, toastSuccess } from "../../../../utils/index";
import { Toast } from "primereact/toast";
import {
  addTrailerVideoFile,
  addVideoFile,
  getFilesFromG,
  getSingleFileFromG,
} from "../../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface AddVideoFileDialogProps {
  visible: boolean;
  content: IVideoContent;
  onHide: () => void;
  isTrailer: boolean;
}

const AddVideoFileDailog: React.FC<AddVideoFileDialogProps> = ({
  visible,
  content,
  onHide,
  isTrailer,
}) => {
  const toast = useRef<Toast>(null);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [videoFileList, setVideoFileList] = useState<VideoFile[] | undefined>(
    []
  );
  const [selectedVideoFile, setSelectedVideoFile] = useState<VideoFile>();
  const [parentId, setParentId] = useState<string | undefined>();
  const [selectedParentVideoFile, setSelectedParentVideoFile] =
    useState<VideoFile>();

  const hideDialog = () => {
    setVideoFileList(undefined);
    setSelectedVideoFile(undefined);
    onHide();
  };

  useEffect(() => {
    //console.log("video list effect.");
    if (visible) {
      getFilesFromG("root").then((response) => {
        //console.log("useEffect.getFilesFromG.response:", response);
        setVideoFileList(response);
      });
    }
  }, [visible]);

  useEffect(() => {
    //console.log("videoFileList changed.");
    if (videoFileList && videoFileList.length > 0) {
      getSingleFileFromG(videoFileList[0].parents[0]).then((response) => {
        if (response) {
          //console.log("getSingleFileFromG:", response);
          if (response.parents && response.parents.length > 0) {
            setParentId(response.parents[0]);
          }
          setSelectedVideoFile(response);
        }
      });
    }
  }, [videoFileList]);

  function addFileToContent(rowData: VideoFile): void {
    console.log("isTrailer:", isTrailer);
    if (isTrailer) {
      addTrailerVideoFile(content?.id, rowData?.id).then((response) => {
        if (response?.code === 200) {
          toastSuccess(toast, "Trailer Video file upload started.");
          hideDialog();
        } else {
          toastError(toast, "Trailer Video file upload failed.");
        }
        //console.log("addVideoFile.response:", response);
      });
    } else {
      addVideoFile(content?.id, rowData?.id).then((response) => {
        if (response?.code === 200) {
          toastSuccess(toast, "Video file upload started.");
          hideDialog();
        } else {
          toastError(toast, "Video file upload failed.");
        }
        //console.log("addVideoFile.response:", response);
      });
    }
  }

  function reLoadFileList(rowData: VideoFile): void {
    //console.log("reLoadFileList.rowData.id:", rowData.id);
    setSelectedVideoFile(rowData);
    setParentId(rowData?.parents[0]);
    getFilesFromG(rowData?.id).then((response) => {
      setVideoFileList(response);
    });
  }

  function backFileList(): void {
    //console.log("backFileList.parentId:", parentId);
    //console.log("backFileList.selectedVideoFile:", selectedVideoFile);
    //setParentId(selectedVideoFile?.parents[0]);
    //setSelectedVideoFile();
    getFilesFromG(parentId).then((response) => {
      setVideoFileList(response);
    });
  }

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 className="mx-0 my-1">{""}</h5>
        <div>
          <i
            className="pi pi-arrow-circle-left"
            style={{
              color: "gray",
              paddingRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => backFileList()}
          ></i>
        </div>
        <div>
          <span
            style={{
              color: "gray",
              paddingRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => backFileList()}
          >
            {selectedVideoFile?.name}
          </span>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "40%" }}
        header={
          isTrailer
            ? "Add Trailer File for " + content.name
            : " Add Video File for " + content.name
        }
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <div className="col-12 xl:col-12">
          <div className="">
            <DataTable
              value={videoFileList}
              responsiveLayout="scroll"
              paginator
              rows={15}
              rowsPerPageOptions={[5, 10, 25, 50]}
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              filterDisplay="row"
            >
              <Column
                field="name"
                filter
                header={header}
                filterPlaceholder="Search by name"
                body={(rowData: VideoFile) => (
                  <>
                    {rowData.mimeType.includes("folder") ? (
                      <span
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#1d82e7",
                        }}
                        onClick={() => reLoadFileList(rowData)}
                      >
                        <i
                          className="pi pi-folder"
                          style={{
                            color: "gray",
                            paddingRight: "5px",
                          }}
                        ></i>
                        {rowData.name}
                      </span>
                    ) : (
                      <>
                        <i
                          className="pi pi-video"
                          style={{
                            color: "gray",
                            paddingRight: "5px",
                          }}
                        ></i>
                        {rowData.name}
                      </>
                    )}
                  </>
                )}
              ></Column>

              <Column
                body={(rowData: VideoFile) => (
                  <>
                    {rowData.mimeType.includes("folder") ? (
                      ""
                    ) : (
                      <i
                        className="pi pi-plus-circle"
                        style={{
                          color: "gray",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => addFileToContent(rowData)}
                      ></i>
                    )}
                  </>
                )}
                exportable={false}
                style={{ width: "10%" }}
              />
            </DataTable>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default AddVideoFileDailog;
