import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ICustomer } from "../../../../models";
import {
  dataFilters,
  initialCustomer,
  initialMessage,
} from "../../../../constants";
import { ErrorField, InputField, DataTableDialogFooter } from "../../../index";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
  toastError,
  toastSuccess,
} from "../../../../utils";
import classNames from "classnames";
import {
  addResponse,
  getCustomerDetailsV2,
  getMessagesByCustomer,
} from "../../../../services";
import { Toast } from "primereact/toast";
import { IMessageDefinition } from "@/models/Entities/messages";
import { Row, Table } from "react-bootstrap";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { Button } from "primereact/button";
import AddResponseDialog from "./AddResponseDialog";

interface MessageDialogProps {
  customerId: number;
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
}

const MessageDialog: React.FC<MessageDialogProps> = ({
  customerId,
  visible,
  onHide,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [messageId, setMessageId] = useState(0);
  const [responseText, setResponseText] = useState("");

  const [messages, setMessages] = useState<IMessageDefinition[]>([]);

  const [selectedMessage, setSelectedMessage] =
    useState<IMessageDefinition>(initialMessage);

  const [customerData, setCustomerData] = useState<ICustomer>(initialCustomer);
  const [showAddResponseDialog, setShowAddResponseDialog] = useState(false);

  const [triggerRefresh, setTriggerRefresh] = useState(true);

  useEffect(() => {
    //console.log("getMessagesByCustomer.response:");
    if (customerId !== 0) {
      try {
        getMessagesByCustomer(customerId).then((response) => {
          //console.log("getMessagesByCustomer.response:", response);
          if (response && response.length > 0) {
            setMessages(response);
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.log("Error:", error);
      }
    }
  }, [visible, showAddResponseDialog]);

  useEffect(() => {
    //console.log("getMessagesByCustomer.response:");
    if (customerId !== 0) {
      try {
        getCustomerDetailsV2(customerId).then((response) => {
          //console.log("getCustomerDetailsV2.customerId:", customerId);
          //console.log("getCustomerDetailsV2.response:", response);
          if (response) {
            setCustomerData(response);
            setIsLoading(false);
          } else {
            setCustomerData(initialCustomer);
          }
        });
      } catch (error) {
        console.log("Error:", error);
      }
    }
  }, [visible]);

  const formik = useFormik({
    initialValues: initialMessage,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      //handleAddResponse();
      //formik.resetForm();
    },
  });

  const handleAddResponse = () => {
    setIsLoading(true);
    addResponse(messageId, responseText).then((response) => {
      //console.log("addLabel.response:", response);
      if (response.data.code === "200") {
        //console.log("toastSuccess:");
        toastSuccess(toast, "Response Added");
      } else {
        //console.log("atoastError:");
        toastError(toast, "Response not Added." + response.data.message);
      }
    });
    setIsLoading(false);
    refreshTable();
    onHide();
  };

  const openAddResponseDialog = (message: IMessageDefinition) => {
    setSelectedMessage(message);
    setShowAddResponseDialog(true);
  };

  const hideAddResponseDialog = () => {
    setShowAddResponseDialog(false);
  };

  const updateRefreshTrigger = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "90%" }}
        header={t("Response Message")}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={formik.handleSubmit}
          />
        }
        onHide={onHide}
      >
        <Row style={{ marginBottom: "20px" }}>
          <Table
            style={{
              border: "1px solid #ced4da",
              borderRadius: "6px",
              width: "100%",
            }}
          >
            <tr>
              <th
                colSpan={2}
                style={{
                  textAlign: "start",
                  padding: "6px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Customer Info
              </th>
            </tr>
            <tr>
              <th style={{ width: "60px", textAlign: "start", padding: "6px" }}>
                Email
              </th>
              <td style={{ textAlign: "start", padding: "6px" }}>
                {customerData?.email}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: "start", padding: "6px" }}>Name</th>
              <td style={{ textAlign: "start", padding: "6px" }}>
                {customerData?.name}
              </td>
            </tr>
            <tr>
              <th style={{ textAlign: "start", padding: "6px" }}>Status</th>
              <td style={{ textAlign: "start", padding: "6px" }}>
                {customerData?.status}
              </td>
            </tr>
          </Table>
        </Row>

        <Row>
          <DataTable
            value={messages}
            selection={selectedMessage}
            onSelectionChange={(e) => setSelectedMessage(e.value)}
            loading={isLoading}
            title="messages"
          >
            <Column
              field="id"
              header="ID"
              body={(rowData) => <>{rowData.id}</>}
              sortable
            />
            <Column
              field="subject"
              header="Subject"
              body={(rowData) => <>{rowData.subject}</>}
              sortable
            />
            <Column
              field="message"
              header="Message"
              body={(rowData) => <>{rowData.message}</>}
              sortable
            />

            <Column
              field="response"
              header="Response"
              body={(rowData) => (
                <div style={{ whiteSpace: "pre-line" }}>{rowData.response}</div>
              )}
              sortable
            />

            <Column
              field="responseDate"
              header="Response Date"
              body={(rowData) => (
                <>
                  {rowData?.responseDate &&
                    moment(rowData?.responseDate).format("DD/MM/YYYY, h:mm a")}
                </>
              )}
              sortable
            />

            <Column
              field="status"
              header="Status"
              body={(rowData) => (
                <>
                  {rowData.status == "NEW" ? (
                    <Badge value="NEW" severity="warning" />
                  ) : (
                    <Badge value="RESPONDED" severity="success" />
                  )}
                </>
              )}
              sortable
            />

            <Column
              field="action"
              header="Atatus"
              body={(rowData) => (
                <>
                  {rowData.response ? (
                    ""
                  ) : (
                    <Button
                      icon="pi pi-pencil"
                      className="p-button-rounded p-button-info m-1"
                      onClick={() => openAddResponseDialog(rowData)}
                    ></Button>
                  )}
                </>
              )}
            />
          </DataTable>
        </Row>
      </Dialog>
      <AddResponseDialog
        messageId={selectedMessage?.id}
        visible={showAddResponseDialog}
        onHide={hideAddResponseDialog}
        refreshTable={updateRefreshTrigger}
      />
    </>
  );
};

export default MessageDialog;
