import { getProducts } from "../../services/index";
import { useQuery } from "react-query";

export const useGetProductsData = () => {
  const { data: products, isLoading: getProductsIsLoading } = useQuery(
    "products",
    getProducts
  );
  return { products, getProductsIsLoading };
};
