import React, { useRef, useState } from 'react'
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { DataTableDeleteDialog  } from '../../../Dialog';
import { DataTableActionTemplate, DataTableCrud, DataTableSearchHeader, DataTableToolbar } from '../../common';
import { IContract } from '../../../../models/index';
import { contractFilters,  initialContract } from '../../../../constants';
import { useAddContractData, useDeleteContractData, useGetContractsData, useUpdateContractData } from '../../../../hooks/contract/index';
import { ContractDialog } from '../../../../components/Dialog/contract';

const ContractDataTable = () => {
  const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
  const [contract, setContract] = useState<IContract>(initialContract);
  const [deleteContractDialog, setDeleteContractDialog] = useState(false);
  const [contractDialog, setContractDialog] = useState(false);
  const [filters, setFilters] = useState(contractFilters);
  const [isAddItem, setIsAddItem] = useState(false);
    

  const toast = useRef<Toast>(null);
  
  const { mutate: addContractMutate, addContractIsLoading } =
    useAddContractData(toast);
  const { mutate: updateContractMutate } = useUpdateContractData(toast);
  const { mutate: deleteContractMutate } = useDeleteContractData(toast);
  const { contracts, getContractsIsLoading } = useGetContractsData();


    const openAddContractDialog = () => {
    setContract(initialContract);
    setIsAddItem(true);
    setContractDialog(true);
    };
    
    const openDeleteContractDialog = (contract?: IContract) => {
    contract && setContract(contract);
    setDeleteContractDialog(true);
    };
    
    const openEditContractDialog = (rowData: IContract) => {
    setContract({ ...rowData });
    setIsAddItem(false);
    setContractDialog(true);
  };

    const hideContractDialog = () => {
    setContractDialog(false);
  };

  const hideDeleteContractDialog = () => {
    setDeleteContractDialog(false);
    };
    
    const deleteContract = () => {
    deleteContractMutate();
    hideDeleteContractDialog();
  };

   const saveContract = (newContract: IContract) => {
    isAddItem ? addContractMutate(newContract) : updateContractMutate(newContract);
    !isAddItem && hideContractDialog();
  };


  return (
<>
      <Toast ref={toast} />
      <DataTableToolbar
        disableDeleteButton={!selectedContract}
        openAddDialog={openAddContractDialog}
        openDeleteDialog={openDeleteContractDialog}
        showDeleteButton ={true}
      />

      <DataTableCrud
        value={contracts}
        selection={selectedContract}
        onSelectionChange={(e) => setSelectedContract(e.value)}
        header={
          <DataTableSearchHeader
            title={"Contract Management"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        loading={false}
        title="contracts"
      >

        <Column
          selectionMode="single"
          headerStyle={{ width: "3rem" }}
          exportable={false}
        />
       
        <Column
          field="name"
          header={"Name"}
          sortable
          style={{ minWidth: "10rem" }}
        />
        <Column
          field="startDate"
          header={"Start Date"}
          sortable
          style={{ minWidth: "11rem" }}
        />
        <Column
          field="endDate"
          header={"End Date"}
          sortable
          style={{ minWidth: "8rem" }}
              />
              
              <Column
          field="signDate"
          header={"Sign Date"}
          sortable
          style={{ minWidth: "3rem" }}
        />
        
        <Column
          field="type"
          header={"Type"}
          sortable
          style={{ minWidth: "4rem" }}
        />
        <Column
          field="providerName"
          header={"Provider Name"}
          sortable
          style={{ minWidth: "3rem" }}
              />
        
        
        <Column
          body={(rowData: IContract) => (
            <DataTableActionTemplate
              openDeleteDialog={() => openDeleteContractDialog(rowData)}
              openEditDialog={() => openEditContractDialog(rowData)}
            />
          )}
          header={"Action"}
          exportable={false}
          style={{ minWidth: "12rem" }}
        />
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteContractDialog}
        data={selectedContract ?? contract}
        onHide={hideDeleteContractDialog}
        onDelete={deleteContract}
      />

      <ContractDialog
        visible={contractDialog}
        contract={contract}
        isLoading={addContractIsLoading}
        onHide={hideContractDialog}
        addContract={saveContract}
      />
    </> 
  )
}

export default ContractDataTable;