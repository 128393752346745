import { LabelDataTable } from "../../components/index";

export default function LabelManagement() {
  return (
    <div className="datatable-crud">
      <div className="card">
        <LabelDataTable />
      </div>
    </div>
  );
}
