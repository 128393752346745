import { IFieldProps } from "@/models";
import { InputMask, InputMaskProps } from "primereact/inputmask";
import React from "react";

interface InputMaskFieldProps extends InputMaskProps, IFieldProps {}

const InputMaskField: React.FC<InputMaskFieldProps> = ({
  getFormErrorMessage,
  labelName,
  labelText,
  ...rest
}) => {
  return (
    <div className="field">
      <label htmlFor={labelName}>{labelText}</label>
      <InputMask {...rest} />
      {getFormErrorMessage != null && getFormErrorMessage()}
    </div>
  );
};

export default InputMaskField;
