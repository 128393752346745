import { IContentP } from "../../models/index";
import { updateMetadata } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateMetadataData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: updateMetadataIsLoading } = useMutation(
    async (metadata: IContentP) => await updateMetadata(metadata),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("metadata");
        toastSuccess(toast, "Metadata Updated");
      },
      onError: () => {
        toastError(toast, "Metadata not Updated");
      },
    }
  );
  return {
    mutate,
    updateMetadataIsLoading,
  };
};
