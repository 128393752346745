import IPayment from "@/models/Entities/payments/IPayment";

import { getUsers } from "../../../services";
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from "react";
import { DataTableSearchHeader } from "../common";
import { DataTable } from "primereact/datatable";

import { IUser } from "@/models";
import { Row } from "react-bootstrap";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import NewUserDialog from "../../Dialog/user/NewUserDialog";

const UsersDataTable = () => {
  const toast = useRef<Toast>(null);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(
    undefined
  );

  const [users, setUsers] = useState<IUser[]>();
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(true);

  useEffect(() => {
    getUsers()
      .then((res) => {
        //console.log("payments:", res);
        setUsers(res);
      })
      .catch((err) => {
        //console.log("Error Text:", err);
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
  }, [triggerRefresh]);

  useEffect(() => {
    console.log("selectedUser.useEffect", selectedUser);
  }, [selectedUser]);

  const openAddUserDialog = () => {
    setShowAddUserDialog(true);
  };

  const hideAddUserDialog = () => {
    setShowAddUserDialog(false);
  };

  const refreshTable = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const openAddRemoveContentlDialog = (rowdata: IUser) => {};

  return (
    <>
      <Toast ref={toast} />

      <div className="card">
        <Row style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button
            label={"New"}
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openAddUserDialog}
          />
        </Row>
        <DataTable
          value={users}
          paginator
          rows={30}
          dataKey="id"
          selection={null}
          onSelectionChange={(e) => setSelectedUser(e.value)}
          //filters={filters}
          //loading={getProductsIsLoading}
          title="users"
          header={"Users"}
        >
          <Column
            field="id"
            header={"ID"}
            sortable
            style={{ minWidth: "5%" }}
          />

          <Column
            field="name"
            header={"Name"}
            sortable
            style={{ minWidth: "15%" }}
          />
          <Column
            field="username"
            header={"Username"}
            sortable
            style={{ minWidth: "15%" }}
          />
          <Column
            field="role"
            header={"Role"}
            sortable
            style={{ minWidth: "20%" }}
          />
        </DataTable>
      </div>
      <NewUserDialog
        visible={showAddUserDialog}
        onHide={hideAddUserDialog}
        refreshTable={refreshTable}
      />
    </>
  );
};

export default UsersDataTable;
