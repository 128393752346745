export const getBaseUrl = () => {
  //let url = "http://75.119.146.85:5555";

  //STAGING
  //let url = "https://muudon.com/admapi"; //staging ayri mng gw

  //PROD
  let url = "https://hoox.tv/admapi"; //prod ayri mng gw

  return url;

  //let url = "https://hoox.tv/api"; //prod OLD
  //let url = "https://muudon.com/api"; //staging ortak gw  OLD
};
