import { IContract } from "../../models/index";
import { updateContract } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateContractData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: updateContractIsLoading } = useMutation(
    async (contract: IContract) => await updateContract(contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contracts");
        toastSuccess(toast, "Contract Updated");
      },
      onError: () => {
        toastError(toast, "Contract not Updated");
      },
    }
  );
  return { mutate, updateContractIsLoading };
};
