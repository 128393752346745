import { ILicense } from "../../models/Entities/license/index";
import { axiosInstance } from "../../api/axios.instance";
import { ILicensesAddOc, IVideoContent } from "../../models/index";
import ILicenseAddOc from "@/models/Entities/licenseAddOc/ILicensesAddOc";

export const getLicenses = async (): Promise<ILicense[]> =>
  await axiosInstance
    .get(`/contman/license/getall`)
    .then((res) => res.data.data);

export const getLicensesForList = async (): Promise<ILicense[]> =>
  await axiosInstance
    .get(`/contman/license/lists`)
    .then((res) => res.data.data);

export const addLicense = async (license: ILicense) =>
  await axiosInstance.post(`/contman/license/create`, { licenseP: license });

export const updateLicense = async (license: ILicense) =>
  await axiosInstance.put(`/contman/license/edit`, { licenseP: license });

export const deleteLicense = async () =>
  await axiosInstance.delete(`/contman/license/delete`);

export const addLicenseToContent = async (licenseAddContent: ILicensesAddOc) =>
  await axiosInstance.post(
    `/contman/content/addlicensetocontent`,
    licenseAddContent
  );

export const removeContentFromLicense = async (contentData: IVideoContent) =>
  await axiosInstance.post(
    `/contman/license/removecontentfromlicense`,
    contentData
  );
