import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import {
  DataTableActionTemplate,
  DataTableDeleteDialog,
  DataTableStatusTemplate,
  CustomerDialog,
  DataTableToolbar,
  CustomerDetailsDialog,
} from "../../../index";
import { ICustomer } from "../../../../models/index";
import { customerFilters, initialCustomer } from "../../../../constants/index";
import {
  useGetCustomersData,
  useAddCustomerData,
  useDeleteCustomerData,
  useUpdateCustomerData,
  useGetCustomerDetails,
} from "../../../../hooks/index";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { STATUS_TYPES } from "./../../../../constants/statues";
import { InputText } from "primereact/inputtext";
import moment from "moment";

const CustomerDataTable = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null
  );
  const [customer, setCustomer] = useState<ICustomer>(initialCustomer);
  const [filters, setFilters] = useState(customerFilters);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
  const [customerDialog, setCustomerDialog] = useState(false);
  const [customerDetailsDialog, setCustomerDetailsDialog] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const { customers, getCustomersIsLoading } = useGetCustomersData();
  const { customerd, getCustomerdIsLoading, refetchGetCustomerd } =
    useGetCustomerDetails(customer.id);

  const toast = useRef<Toast>(null);

  const { mutate: addCustomerMutate, addCustomerIsLoading } =
    useAddCustomerData(toast);
  const { mutate: updateCustomerMutate } = useUpdateCustomerData(toast);
  const { mutate: deleteCustomerMutate } = useDeleteCustomerData(toast);
  const statusOptions = [STATUS_TYPES.ACTIVE, STATUS_TYPES.INACTIVE];

  const typeOptions = ["TEST"];

  const openDeleteCustomerDialog = (customer?: ICustomer) => {
    customer && setCustomer(customer);
    setDeleteCustomerDialog(true);
  };
  const openAddCustomerDialog = () => {
    setCustomer(initialCustomer);
    setIsAddItem(true);
    setCustomerDialog(true);
  };
  const openEditCustomerDialog = (rowData: ICustomer) => {
    setCustomer({ ...rowData });
    setIsAddItem(false);
    setCustomerDialog(true);
  };

  const openCustomerDetailsDialog = async (rowData: ICustomer) => {
    await setCustomer({ ...rowData });
    await refetchGetCustomerd();
    setCustomerDetailsDialog(true);
  };

  const hideCustomerDialog = () => {
    setCustomerDialog(false);
  };

  const hideCustomerDetailsDialog = () => {
    setCustomerDetailsDialog(false);
  };

  const hideDeleteCustomerDialog = () => {
    setDeleteCustomerDialog(false);
  };

  const saveCustomer = (newCustomer: ICustomer) => {
    isAddItem
      ? addCustomerMutate(newCustomer)
      : updateCustomerMutate(newCustomer);
    !isAddItem && hideCustomerDialog();
  };
  const deleteCustomer = () => {
    customer.id && deleteCustomerMutate(customer.id);
    hideDeleteCustomerDialog();
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5 className="mx-0 my-1">{"Manage Customers"}</h5>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const statusItemTemplate = (option: any) => {
    return <span className={`badge status-${option}`}>{option}</span>;
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statusOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
      />
    );
  };

  const header = renderHeader();
  return (
    <>
      <Toast ref={toast} />
      {/* <DataTableToolbar
        openDeleteDialog={openDeleteCustomerDialog}
        openAddDialog={openAddCustomerDialog}
        disableDeleteButton={!selectedCustomer}
      /> */}
      <DataTable
        value={customers}
        paginator
        rows={30}
        dataKey="id"
        selection={selectedCustomer}
        onSelectionChange={(e: any) => setSelectedCustomer(e.value)}
        filters={filters}
        filterDisplay="row"
        loading={getCustomersIsLoading}
        responsiveLayout="scroll"
        globalFilterFields={["name", "city.name", "status"]}
        header={header}
        emptyMessage="No customers found."
      >
        {/* <Column
          selectionMode="single"
          headerStyle={{ width: "3rem" }}
          exportable={false}
        /> */}
        <Column
          field="email"
          header={"Email"}
          filter
          filterPlaceholder="Search by email"
          sortable
          style={{ maxWidth: "18rem", minWidth: "14.5rem" }}
        />
        <Column
          field="name"
          header={"Name"}
          filter
          filterPlaceholder="Search by name"
          sortable
          style={{ maxWidth: "14rem", minWidth: "12rem" }}
        />
        <Column
          field="surname"
          header={"Surname"}
          filter
          filterPlaceholder="Search by surname"
          sortable
          style={{ maxWidth: "14rem", minWidth: "12rem" }}
        />
        <Column
          field="status"
          header={"Status"}
          filter
          filterElement={statusRowFilterTemplate}
          showFilterMenu={false}
          sortable
          style={{ maxWidth: "12rem", minWidth: "8rem" }}
          body={(rowData: ICustomer) => (
            <DataTableStatusTemplate status={rowData.status} />
          )}
        />
        <Column
          field="type"
          header={"Type"}
          sortable
          style={{ maxWidth: "14rem", minWidth: "10rem" }}
        />

        <Column
          field="creationDate"
          header={"Creation Date"}
          sortable
          style={{ maxWidth: "16rem", minWidth: "12.5rem" }}
          body={(rowData) => (
            <>{moment(rowData.creationDate).format("DD/MM/YYYY")}</>
          )}
        />

        <Column
          body={(rowData: ICustomer) => (
            <DataTableActionTemplate
              openEditDialog={() => openEditCustomerDialog(rowData)}
              openDetails={() => openCustomerDetailsDialog(rowData)}
            />
          )}
          exportable={false}
          style={{ maxWidth: "12rem", minWidth: "12.5rem" }}
        />
      </DataTable>
      {/*
      <DataTableDeleteDialog
        visible={deleteCustomerDialog}
        data={selectedCustomer ?? customer}
        onHide={hideDeleteCustomerDialog}
        onDelete={deleteCustomer}
      />
      */}
      <CustomerDialog
        visible={customerDialog}
        customer={customer}
        isLoading={addCustomerIsLoading}
        onHide={hideCustomerDialog}
        addCustomer={saveCustomer}
      />

      <CustomerDetailsDialog
        visible={customerDetailsDialog}
        customerd={customerd}
        isLoading={getCustomerdIsLoading}
        onHide={hideCustomerDetailsDialog}
      />
    </>
  );
};

export default CustomerDataTable;
