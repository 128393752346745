import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/layout.scss";
import { AppWrapper } from "./layouts";
import { AppConfig, VideoPublishDataTable } from "./components";
import { PrivateRoute } from "./routes/PrivateRouter";
import { SpecialRoute } from "./routes/SpecialRouter";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import {
  Dashboard,
  Login,
  Customers,
  Products,
  AddContentGroup,
  Licenses,
  VideoContents,
  AddVideoContentGroup,
  AddToContent,
  AddLicenseToContent,
  MainBoardItems,
  CategoryManagement,
  Payments,
  LabelManagement,
  Subscriptions,
} from "./pages";
import Messages from "./pages/Customers/Messages";
import { useAuth } from "./hooks";
import UsersDataTable from "./components/DataTable/users/UsersDataTable";
import VideoContentsForPublish from "./pages/VideoContents/VideoContentsForPublish";
//import Payments from "./pages/Payments/Payments";
//import LabelManagement from "./pages/Labels/LabelManagement";

function App() {
  const {
    loggedUser: { user, isLoading },
  } = useAuth();

  return (
    <AppWrapper>
      <AppConfig />
      <Routes>
        <Route path="/login" element={<SpecialRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/customer" element={<Customers />} />
          {/*<Route path="/customer" element={<Customers />} /> */}
          <Route path="/payments" element={<Payments />} />
          <Route path="/product" element={<Products />} />
          {/* 
          <Route path="/addcontentgroup/:id" element={<AddContentGroup />} />
          */}
          <Route
            path="/addvideocontentgroup/:id"
            element={<AddVideoContentGroup />}
          />
          {/* <Route
            path="/AddLicenseToContent/:id"
            element={<AddLicenseToContent />}
          />
 */}
          <Route path="/license" element={<Licenses />} />
          <Route path="/videocontent" element={<VideoContents />} />
          <Route path="/AddToContent/:id" element={<AddToContent />} />
          <Route path="/mbi" element={<MainBoardItems />} />
          <Route path="/label" element={<LabelManagement />} />
          <Route path="/category" element={<CategoryManagement />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/users" element={<UsersDataTable />} />
          <Route path="/publishing" element={<VideoContentsForPublish />} />
          //Directly use the dialog-like component
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </AppWrapper>
  );
}

export default App;
