import { addResponse } from "../../../../services";
import { toastError, toastSuccess } from "../../../../utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { DataTableDialogFooter } from "../../common";

interface AddResponseDialogProps {
  messageId: number | null;
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
}

const AddResponseDialog: React.FC<AddResponseDialogProps> = ({
  messageId,
  visible,
  onHide,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);
  const [responseText, setResponseText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAddResponse = () => {
    if (responseText === "") {
      toastError(toast, "Response can nut be empty text.");
      return;
    }
    //console.log("respon.text:", responseText);
    const processedText = responseText.replace(/\n/g, "\n");
    console.log("processedText:", processedText);
    addResponse(messageId, processedText).then((response) => {
      //console.log("addResponse.response:", response);
      if (response.status === 200) {
        console.log("toastSuccess:");
        toastSuccess(toast, "Response Sended.");
        showSuccess();
      } else {
        //console.log("atoastError:");
        toastError(toast, "Response not Added." + response.data.message);
      }
    });
    refreshTable();
    setResponseText("");
    onHide();
  };

  const showSuccess = () => {
    toastSuccess(toast, "Operation successful.");
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        style={{ width: "40%" }}
        header={"Response Message"}
        modal
        className="p-fluid"
        onHide={onHide}
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={handleAddResponse}
          />
        }
      >
        {" "}
        <textarea
          id={"response"}
          style={{
            border: "1px solid #ced4da",
            borderRadius: "6px",
            width: "100%",
            minHeight: "8em",
          }}
          value={responseText}
          onChange={(e) => setResponseText(e.target.value)}
        />
      </Dialog>
    </>
  );
};

export default AddResponseDialog;
