import { ErrorField, InputField, DataTableDialogFooter } from "../../../index";
import { ILicense } from "../../../../models/index";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
} from "../../../../utils/index";
// import { productSchema } from "../../../../validations/index";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";

interface LicenseDialogProps {
  visible: boolean;
  license: ILicense;
  isLoading: boolean;
  onHide: () => void;
  addLicense: (license: ILicense) => void;
}

const LicenseDialog: React.FC<LicenseDialogProps> = ({
  visible,
  license,
  isLoading,
  onHide,
  addLicense,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: license,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      addLicense(data);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={t("License Details")}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          loading={isLoading}
          hideDialog={onHide}
          handleSubmit={formik.handleSubmit}
        />
      }
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          value={formik.values.name ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="Name"
          labelText={capitalizeFirstLetter("Name")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Name" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Name"),
          })}
        />
        <InputField
          id="contract"
          value={formik.values.contract ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Contract"
          labelText={capitalizeFirstLetter("Contract")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Contract" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Contract"),
          })}
        />
        <InputField
          id="startDate"
          value={formik.values.startDate?.toString() ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Start Date"
          labelText={capitalizeFirstLetter("Start Date")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Start Date" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Start Date"),
          })}
        />
        <InputField
          id="endDate"
          value={formik.values.endDate?.toString() ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="End Date"
          labelText={capitalizeFirstLetter("End Date")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="End Date" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "End Date"),
          })}
        />
        <InputField
          id="type"
          value={formik.values.type ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Type"
          labelText={capitalizeFirstLetter("Type")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="Type" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Type"),
          })}
        />
        <InputField
          id="platforms"
          value={formik.values.platforms ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Platforms"
          labelText={capitalizeFirstLetter("Platforms")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Platforms" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Platforms"),
          })}
        />
        <InputField
          id="regions"
          value={formik.values.regions ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="Regions"
          labelText={capitalizeFirstLetter("Regions")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name="Regions" />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "Regions"),
          })}
        />
      </form>
    </Dialog>
  );
};

export default LicenseDialog;
