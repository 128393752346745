import { Dialog } from "primereact/dialog";
import DataTableDialogFooter from "../DataTableDialogFooter/DataTableDialogFooter";
import { Button } from "primereact/button";

interface ConfirmOperationDialogProps {
  visible: boolean;
  text: string;
  onHide: () => void;
  confirm: () => void;
}

const ConfirmOperationDialog: React.FC<ConfirmOperationDialogProps> = ({
  visible,
  text,
  onHide,
  confirm,
}) => {
  function handleSubmit(): void {
    confirm();
    onHide();
  }

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "30%" }}
        header={"Confirm Operation !"}
        modal
        className="p-fluid"
        footer={
          <>
            <Button
              label={"Close"}
              icon="pi pi-times"
              className="p-button p-component p-button-outlined p-button-secondary"
              onClick={onHide}
            />
            <Button
              label={"Confirm"}
              type="submit"
              icon="pi pi-check"
              className="p-button"
              onClick={handleSubmit}
            />
          </>
        }
        onHide={onHide}
      >
        <h5 style={{ margin: "10px" }}>{text}</h5>
      </Dialog>
    </>
  );
};
export default ConfirmOperationDialog;
