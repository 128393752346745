import { axiosInstance } from "../../api/axios.instance";
import { IProduct } from "../../models/index";

export const getProducts = async (): Promise<IProduct[]> =>
  await axiosInstance
    .get(`/crm-product/product/search`, { params: { status: "ACTIVE" } })
    .then((res) => res.data.data);

export const addProduct = async (product: IProduct) =>
  await axiosInstance.post(`/product`, product);

export const updateProduct = async (product: IProduct) =>
  await axiosInstance.put(`/product`, product);

export const deleteProduct = async (productId: bigint) =>
  await axiosInstance.put(`/product/${productId}`);
