import { getSeasons } from "../../services/index";
import { useQuery } from "react-query";

export const useGetSeasonsData = (enabled?: boolean) => {
  const {
    data: seasons,
    isLoading: getSeasonsIsLoading,
    refetch: refetchGetSeasons,
  } = useQuery("seasons", getSeasons, {
    enabled: false,
  });
  return { seasons, getSeasonsIsLoading, refetchGetSeasons };
};
