import React from "react";

interface MenuContainerProps {
  children: React.ReactNode;
}

const MenuContainer: React.FC<MenuContainerProps> = ({ children }) => {
  return <div className="layout-menu-container">{children}</div>;
};

export default MenuContainer;
