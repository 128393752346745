import { ICategory } from "@/models/Entities/category";
import { axiosInstance } from "../../api/axios.instance";
import {
  IAllMainboardItems,
  IContentP,
  ILabelDefinition,
  IMainboardItems,
} from "@/models";

export const getCategories = async (): Promise<ICategory[]> =>
  await axiosInstance.get(`/contman/cat`).then((res) => res.data.data);

export const getCategory = async (id: number | null): Promise<ICategory> =>
  await axiosInstance.get(`/contman/cat/` + id).then((res) => res.data.data);

export const addCategory = async (newCategory: ICategory) =>
  await axiosInstance.post(`/contman/cat`, { categoryP: newCategory });

export const updateCategory = async (editCategory: ICategory) =>
  await axiosInstance.put(`/contman/cat`, { categoryP: editCategory });

export const removeLabelFromCategory = async (
  categoryId: number | null,
  labelId: number | null | undefined
) =>
  await axiosInstance.get(
    `/contman/cat/` + categoryId + `/labels/remove/` + labelId
  );

export const addLabelToCategory = async (
  categoryId: number | null,
  labelId: number | null | undefined
) =>
  await axiosInstance.get(
    `/contman/cat/` + categoryId + `/labels/add/` + labelId
  );

export const getMbisOfCategory = async (
  categoryId: number | null
): Promise<IAllMainboardItems[]> =>
  await axiosInstance
    .get(`/contman/cat/` + categoryId + `/mbis`)
    .then((res) => res.data.data);

export const getLabelsOfCategory = async (
  categoryId: number | null
): Promise<ILabelDefinition[]> =>
  await axiosInstance
    .get(`/contman/cat/` + categoryId + `/labels`)
    .then((res) => res.data.data);

export const getLabelsOfNotCategory = async (
  categoryId: number | null
): Promise<ILabelDefinition[]> =>
  await axiosInstance
    .get(`/contman/cat/` + categoryId + `/labels/foradd`)
    .then((res) => res.data.data);
