import * as Yup from "yup";

export const productSchema = Yup.object().shape({
 name: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("name is field required"),
 type: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("type is field required"),
});
