import { ILicense, IVideoContent } from "@/models";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

interface LicensesOfContentProps {
  licenses: ILicense[] | undefined;
}

const LicensesOfContent: React.FC<LicensesOfContentProps> = ({ licenses }) => {
  return (
    <>
      <DataTable
        value={licenses}
        dataKey="id"
        emptyMessage="No licenses found."
        header={"Licenses of Content"}
      >
        <Column
          field="id"
          header={"ID"}
          style={{ maxWidth: "10rem", minWidth: "1.5rem" }}
        />
        <Column
          field="name"
          header={"Name"}
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
        />
        <Column
          field="startDate"
          header={"Start Date"}
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
          body={(rowData) =>
            rowData.startDate && (
              <>{moment(rowData.startDate).format("DD/MM/YYYY")}</>
            )
          }
        />
        <Column
          field="endDate"
          header={"End Date"}
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
          body={(rowData) =>
            rowData.endDate && (
              <>{moment(rowData.endDate).format("DD/MM/YYYY")}</>
            )
          }
        />
      </DataTable>
    </>
  );
};

export default LicensesOfContent;
