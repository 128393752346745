import IPaymentInfo from "@/models/Entities/payments/IPaymentInfo";
import { paymentExtendedInfo } from "../../../services";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { InputText } from "primereact/inputtext";

import { InputNumber } from "primereact/inputnumber";

interface Props {
  data: any;
  visible: boolean;
  onHide: () => void;
  // Bileşenin özelliklerini burada tanımlayabilirsiniz
}

const PaymentInfo: React.FC<Props> = ({ data, visible, onHide }) => {
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>();
  const [displayPosition, setDisplayPosition] = useState(false);
  const [position, setPosition] = useState<any>("center");

  useEffect(() => {
    fetchPaymentInfo();
  }, []);

  const fetchPaymentInfo = async () => {
    try {
      paymentExtendedInfo(data.id)
        .then((res) => {
          console.log("payments:", res);
          setPaymentInfo(res);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error("Failed to fetch paymentInfo:", error);
    }
  };

  return (
    <>
      data && (
      <Dialog
        header={"Payment Information"}
        visible={visible}
        position={position}
        modal
        style={{ width: "50vw" }}
        onHide={onHide}
        draggable={false}
        resizable={false}
      >
        <div className="flex flex-column gap-2">
          <label htmlFor="customerName">Customer Name2</label>
          <InputText
            id="customerName"
            value={paymentInfo?.customerName}
            readOnly
          />

          <label htmlFor="customerName">Email</label>
          <InputText value={paymentInfo?.customerEmail} readOnly />

          <label htmlFor="customerName">Product</label>
          <InputText value={paymentInfo?.productName} readOnly />

          <label htmlFor="customerName">Price</label>
          <InputNumber value={paymentInfo?.price} readOnly />

          <label htmlFor="customerName">Date</label>
          <InputText
            value={moment(paymentInfo?.paymentDate).format(
              "DD/MM/YYYY, h:mm a"
            )}
            readOnly
          />

          <label htmlFor="customerName">Message</label>
          <InputText value={paymentInfo?.message} readOnly />
        </div>
      </Dialog>
      )
    </>
  );
};

export default PaymentInfo;
