import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { initialUser } from "../../../constants";
import { IUser } from "@/models";
import { addUser } from "../../../services";
import {
  capitalizeFirstLetter,
  isFormFieldValid,
  toastError,
  toastSuccess,
} from "../../..//utils";
import { DataTableDialogFooter } from "../common";
import {
  DropdownField,
  ErrorField,
  InputField,
} from "../../../components/FormFields";
import classNames from "classnames";

interface NewUserDialogProps {
  visible: boolean;
  onHide: () => void;
  refreshTable: () => void;
}

const NewUserDialog: React.FC<NewUserDialogProps> = ({
  visible,
  onHide,
  refreshTable,
}) => {
  const toast = useRef<Toast>(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const userRoles = ["ADMIN", "MANAGER", "OPERATOR", "CMS"];

  const formik = useFormik({
    initialValues: initialUser,
    enableReinitialize: true,
    // TODO: ADD SCHEMA
    validationSchema: null,
    onSubmit: (data) => {
      addNewUser(data);
      formik.resetForm();
    },
  });

  const addNewUser = (newUser: IUser) => {
    setIsLoading(true);
    addUser(newUser).then((response) => {
      //console.log("addLabel.response:", response);
      if (response.data.code === "200") {
        //console.log("toastSuccess:");
        toastSuccess(toast, "User Added. User:" + newUser?.username);
      } else {
        //console.log("atoastError:");
        toastError(toast, "User not Added." + response.data.message);
      }
    });
    setIsLoading(false);
    refreshTable();
    onHide();
  };

  return (
    <>
      <Dialog
        visible={visible}
        style={{ width: "30%" }}
        header={t("New User")}
        modal
        className="p-fluid"
        footer={
          <DataTableDialogFooter
            loading={isLoading}
            hideDialog={onHide}
            handleSubmit={formik.handleSubmit}
          />
        }
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <InputField
            id="name"
            value={formik.values.name ?? ""}
            type="text"
            required
            autoFocus={true}
            onChange={formik.handleChange}
            labelName="Name"
            labelText={capitalizeFirstLetter("Name")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="Name" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "Name"),
            })}
          />
          <InputField
            id="username"
            value={formik.values.username ?? ""}
            type="text"
            required
            autoFocus={true}
            onChange={formik.handleChange}
            labelName="username"
            labelText={capitalizeFirstLetter("username")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="username" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "username"),
            })}
          />
          <InputField
            id="password"
            value={formik.values.password ?? ""}
            type="password"
            required
            autoFocus={true}
            onChange={formik.handleChange}
            labelName="password"
            labelText={capitalizeFirstLetter("password")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name="password" />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "password"),
            })}
          />
          <DropdownField
            id="role"
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            options={userRoles}
            labelName={"User Role"}
            labelText={capitalizeFirstLetter("User Role")}
            getFormErrorMessage={() => (
              <ErrorField formik={formik} name={"User Role"} />
            )}
            className={classNames({
              "p-invalid": isFormFieldValid(formik, "User Role"),
            })}
          />
        </form>
      </Dialog>
    </>
  );
};

export default NewUserDialog;
