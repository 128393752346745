import {
  IContentGroupOc,
  IContentGroup,
  IContentGroupOp,
  IProductGroup,
  ICustomer,
  ILoginFields,
  IProduct,
  IUser,
  ILicense,
  IContract,
  IProvider,
  ISelectedProductP,
  IVideoContent,
  IContentP,
  IMetadata,
  IContentPosterP,
  IPosterDefinition,
  ILicensesDeleteOc,
  ILicensesAddOc,
  IAllMainboardItems,
  IMainboardItems,
  ILabelDefinition,
  IReleaseDate,
} from "@/models";
import { ICategory } from "@/models/Entities/category";
import { IMessageDefinition } from "@/models/Entities/messages";

export const initialCustomer: ICustomer = {
  id: null,
  guid: "",
  username: "",
  name: "",
  surname: "",
  email: "",
  emailConfirm: false,
  emailConfirmCode: "",
  password: "",
  gsmno: "",
  address: "",
  birthDate: null,
  gender: "",
  city: "",
  status: "",
  type: "",
  lastlogindate: null,
  subscriptions: [
    {
      actualEndDate: null,
      addOnProductIds: null,
      campaign: null,
      channel: "",
      createdById: null,
      createdByName: null,
      creationDate: null,
      customer: null,
      duration: 0,
      endDate: null,
      guid: "",
      id: 0,
      lastUpdateById: null,
      lastUpdateByName: null,
      lastUpdateDate: null,
      price: 0,
      startDate: null,
      status: "INACTIVE",
      subscriptionType: "",
      productP: {
        createdById: null,
        createdByName: null,
        creationDate: new Date(),
        duration: null,
        estDuration: null,
        estPrice: null,
        externalId: "",
        guest: null,
        guid: "",
        id: 0,
        imageUrl: null,
        lastUpdateById: null,
        lastUpdateByName: null,
        lastUpdateDate: null,
        name: "",
        periodType: "",
        price: 0,
        productType: "",
        ptype: null,
        quality: null,
        rentDuration: null,
        rentPrice: null,
        status: "",
        type: "",
        visibility: false,
      },
    },
  ],
};

export const initialSelectedProductP: ISelectedProductP = {
  createdById: null,
  createdByName: null,
  creationDate: "",
  description: null,
  duration: 0,
  estDuration: null,
  estPrice: null,
  externalId: "",
  guest: null,
  guid: "",
  id: 0,
  imageUrl: null,
  lastUpdateById: null,
  lastUpdateByName: null,
  lastUpdateDate: null,
  name: "",
  periodType: "",
  price: 0,
  productType: "",
  ptype: null,
  quality: null,
  rentDuration: null,
  rentPrice: null,
  status: "",
  type: "",
  visibility: true,
};

export const initialLogin: ILoginFields = {
  username: "",
  password: "",
};

export const initialContentPosterP: IContentPosterP = {
  id: 0,
  contentId: 0,
  posterDefinition: null,
  url: "",
  cfPosterUrl: "",
};

export const initialPosterDefinition: IPosterDefinition = {
  id: 0,
  height: 0,
  maxFileSize: 0,
  fileSize: 0,
  name: "",
  width: 0,
  url: "",
  mainboardPoster: false,
  mandatoryPoster: false,
  code: 0,
};

export const initialVideoContent: IVideoContent = {
  catalogPosterUrl: "",
  episodeNo: null,
  external: false,
  guid: "",
  id: 0,
  licenseEndDate: null,
  licenseStartDate: null,
  name: "",
  parentId: null,
  providers: "",
  seasonNo: null,
  status: "",
  type: "",
  year: null,
  vdoId: "",
  vdoStatus: "",
  seriesId: 0,
  licenses: null,
  vdoTrailerId: "",
};

export const initialContentP: IContentP = {
  boxOfficeCategory: "",
  contentGroups: [],
  contentReleaseDates: null,
  encodedMediaUrl: "",
  episodeNo: "",
  external: false,
  guid: "",
  id: 0,
  imdbId: "",
  introDuration: 0,
  labels: null,
  licenses: null,
  meUrl: "",
  metadata: null,
  name: "",
  parentId: 0,
  posters: [],
  proxyMediaUrl: "",
  rawMediaUrl: "",
  scriptUrl: "",
  seasonNo: "",
  status: "",
  trailerUrl: "",
  transcodedMediaUrl: "",
  turkish: false,
  type: "",
  typeTitle: "",
  vdoId: "",
  vdoStatus: "",
  price: 0,
  videoEndTimeInSecond: 0,
  videoEndTime: "",
};

export const initialMetadata: IMetadata = {
  external_id: "",
  title: "",
  title_brief: "",
  originalTitle: "",
  media_url: "",
  media_file_path: "",
  media_file_size: "",
  poster_catalog: "",
  poster_upcoming: "",
  poster_featured: "",
  poster_single: "",
  poster_mini: undefined,
  year: "",
  provider: "",
  actual_release_date: "",
  platform_release_date: "",
  duration: 0,
  summary_short: "",
  summary_long: "",
  licence_start_date: 0,
  licence_finish_date: 0,
  isSeries: "",
  episode_name: "",
  episode_no: "",
  type: "",
  actors: [],
  studio: "",
  genres: [],
  audio_type: "",
  encryption: "",
  codec: "",
  screen_format: "",
  isHd: "",
  cloud_synch_status: "",
  vod_type: "",
  original_language: "",
  country: "",
  subtitle: "",
  rating: "",
  directors: [],
  resolution: "",
  frame_rate: "",
  season_no: "",
  definitions: [],
  hasSubtitle: false,
  hasDubbing: false,
  hasOriginalSound: false,
  advisories: [],
  parentId: 0,
  tags: [],
};

export const initialProduct: IProduct = {
  id: null,
  name: "",
  status: "",
  type: "",
};

export const initialContentGroupOp: IContentGroupOp = {
  pId: 0,
  gIds: [],
  username: "",
};
export const initialContentGroupOc: IContentGroupOc = {
  cntId: 0,
  cgIds: [],
  username: "",
};
export const initialLicensesAddOc: ILicensesAddOc = {
  cId: 0,
  lcIds: [],
  username: "",
  userID: 0,
};
export const initialLicensesDeleteOc: ILicensesDeleteOc = {
  lcId: 0,
  cIds: [],
  username: "",
};
export const initialContentGroup: IContentGroup = {
  contents: null,
  createdById: null,
  createdByName: null,
  //creationDate: Date,
  description: "",
  guid: null,
  id: null,
  lastUpdateById: null,
  lastUpdateByName: null,
  lastUpdateDate: null,
  name: "",
  status: "",
  type: "",
};
export const initialProductGroup: IProductGroup = {
  groupId: null,
  id: null,
  productId: null,
  type: "",
};

export const initialUser: IUser = {
  id: null,
  username: "",
  password: "",
  name: "",
  token: "",
  type: "",
  opuiUrls: [],
  role: "",
};

export const initialLicense: ILicense = {
  id: null,
  name: "",
  contract: "",
  startDate: null,
  endDate: null,
  type: "",
  platforms: "",
  regions: "",
};

export const initialContract: IContract = {
  id: null,
  name: "",
  startDate: null,
  endDate: null,
  signDate: null,
  type: "",
  providerName: "",
};

export const initialProvider: IProvider = {
  id: null,
  name: "",
  shortName: "",
  country: "",
  gsmno: null,
  status: "",
  externalId: "",
  contactPerson: "",
};

export const initialAllMainBoardItems: IAllMainboardItems = {
  id: null,
  title: "",
  posterUrl: null,
  description: null,
  content: null,
  type: null,
  clickUrl: null,
  priority: 0,
  platform: null,
  name: "default",
};

export const initialMainBoardItems: IMainboardItems = {
  id: null,
  name: "",
  posterUrl: "",
};

export const initialLabel: ILabelDefinition = {
  id: null,
  name: "",
  clientName: null,
  priority: 999,
  visibility: false,
  clientMenuVisibility: false,
  homeScreenVisibility: false,
  type: "",
  viewType: "",
  urlName: "",
};

export const initialCategory: ICategory = {
  id: null,
  name: "",
  urlName: "",
  iconUrl: "",
  backgroundColorCode: "",
  description: "",
  main: false,
  sequence: 99,
  labels: null,
  menuVisibility: false,
  mbis: null,
};

export const initialMessage: IMessageDefinition = {
  id: null,
  message: "string",
  customerId: 0,
  date: "string",
  subject: "string",
  response: "string",
  responseDate: "string",
  status: "string",
};

export const initialRelease: IReleaseDate = {
  id: 0,
  content: { id: 0, name: "", status: "" },
  outReleaseDate: null,
  releaseDate: null,
  contentTypes: ["TVOD", "SVOD", "EST"],
  licenseId: 0,
  platforms: ["WEBTV", "SMARTTV", "MOBILTV"],
  regions: [],
};
