import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../../assets/images/logo-white.svg";
import { Menu } from "primereact/menu";
import { TopbarMenuItems, TopbarMenu } from "./index";
import { useAuth, useLogout, useMenuToggle } from "../../hooks/index";

const AppTopbar = () => {
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const profileMenuRef = useRef<Menu>(null);
  const { setMenuIsActive } = useMenuToggle();

  const navigate = useNavigate();

  const { logout: handleLogout } = useLogout();

  const {
    loggedUser: { user, isLoading },
  } = useAuth();

  const logout = () => {
    handleLogout();
    navigate("/login");
  };

  //   const onMobileTopbarMenuClick = (event: React.MouseEvent) => {
  //     event.preventDefault();
  //     setMobileTopbarMenuActive((prevState) => !prevState);
  //   };

  const onSideBarMenuClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuIsActive((prevState) => !prevState);
  };

  //   const closeDialog = () => {
  //     setOpenDialog(false);
  //   };

  //   const changeTheme = () => {
  //     setLayoutColorMode((prevState) =>
  //       prevState === CONFIG_VARS.LIGHT ? CONFIG_VARS.DARK : CONFIG_VARS.LIGHT
  //     );
  //   };

  const profileItems = [
    {
      label: user == null ? "Profile" : user?.username,
      icon: "pi pi-user",
      url: "profile",
    },
    {
      separator: true,
    },
    {
      label: "Log Out",
      icon: "pi pi-sign-out",

      command: async () => await logout(),
    },
  ];

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img src={logoLight} alt="logo" />
        <span>{"Video Platform Management System"}</span>
      </Link>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={(e) => onSideBarMenuClick(e)}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={(e) => {}}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <TopbarMenu active={mobileTopbarMenuActive}>
        <TopbarMenuItems
          title="Events"
          iconClassName="pi pi-sun"
          onClick={() => {}}
        />
        <TopbarMenuItems
          title="Language"
          iconClassName="pi pi-language"
          onClick={() => setOpenDialog(true)}
        />
        <TopbarMenuItems title="Settings" iconClassName="pi pi-cog" />
        <TopbarMenuItems
          title={"GOkhan"}
          iconClassName="pi pi-user"
          onClick={(e) => profileMenuRef.current?.toggle(e)}
        />
      </TopbarMenu>

      <Menu ref={profileMenuRef} model={profileItems} popup />
    </div>
  );
};

export default AppTopbar;
