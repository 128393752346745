import PaymentDataTable from "../../components/DataTable/payment/PaymentDataTable";

const Payments = () => {
  return (
    <>
      <div className="datatable-crud">
        <div className="card">
          <PaymentDataTable></PaymentDataTable>
        </div>
      </div>
    </>
  );
};

export default Payments;
