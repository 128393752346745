import { getPosterDefinitions } from "../../services/index";
import { useQuery } from "react-query";

export const useGetPosterDefinitionsData = (enabled?: boolean) => {
  const {
    data: posterDefinitions,
    isLoading: getPosterDefinitionsIsLoading,
    refetch: refetchGetPosterDefinitions,
  } = useQuery("posterDefinitions", getPosterDefinitions, {
    enabled: false,
  });
  return {
    posterDefinitions,
    getPosterDefinitionsIsLoading,
    refetchGetPosterDefinitions,
  };
};
