import { videoContentFilters } from "../../../../constants";
import { IVideoContent } from "@/models";
import { getContentsForPublish, unPublishContent } from "../../../../services";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { ConfirmOperationDialog } from "../../../../components/Dialog";
import IVideoContentMinimize from "@/models/Entities/videoContent/IVideoContentMinimize";
import { toastError, toastSuccess } from "../../../../utils";

const VideoUNPublishDataTable = () => {
  const toast = useRef<Toast>(null);
  const [selectedVideoContent, setSelectedVideoContent] =
    useState<IVideoContentMinimize>();
  const [filters, setFilters] = useState(videoContentFilters);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [videoContents, setVideoContents] = useState<IVideoContentMinimize[]>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    //console.log("useEffect.refreshTable:", refreshTable);
    getContentsForPublish("PUBLISHED")
      .then((res) => {
        setVideoContents(res);
      })
      .catch((err) => {
        //console.log("Error Text:", err);
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
  }, [refreshTable]);

  useEffect(() => {
    //console.log("selectedVideoContent:", selectedVideoContent);
    if (selectedVideoContent) {
      setShowConfirmation(true);
    }
  }, [selectedVideoContent]);

  function openConfirmationDialog(rowData: IVideoContentMinimize): void {
    setSelectedVideoContent(rowData);
  }

  const hideConfirmation = () => {
    //console.log("hideConfirmation.refreshTable:", refreshTable);
    setShowConfirmation(false);
  };

  const unPublishContentLocal = () => {
    unPublishContent(selectedVideoContent)
      .then((res) => {
        console.log("unPublishContent.res:", res);
        if (res.code === 200) {
          toastSuccess(
            toast,
            "Conten unpublished succesfully. '" +
              selectedVideoContent?.name +
              "'"
          );
        } else {
          toastError(toast, "Operation Failed.");
        }
        setRefreshTable((prev) => !prev);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //handleLogout();
          //navigate("/");
          //window.location.href = "/logout";
          console.log("err.response.status:401");
        }
      });
    hideConfirmation();
  };

  return (
    <>
      <Toast ref={toast} />

      <DataTable
        value={videoContents}
        paginator
        rows={50}
        rowsPerPageOptions={[10, 50, 100]}
        dataKey="id"
        selection={selectedVideoContent}
        onSelectionChange={(e: any) => setSelectedVideoContent(e.value)}
        filters={filters}
        filterDisplay="row"
        responsiveLayout="scroll"
        globalFilterFields={["name"]}
        emptyMessage="No video contents found."
      >
        <Column
          field="id"
          header={"ID"}
          sortable
          style={{ maxWidth: "10rem", minWidth: "1.5rem" }}
        />
        <Column
          field="name"
          header={"Name"}
          filter
          filterPlaceholder="Search by name"
          sortable
          style={{ maxWidth: "18rem", minWidth: "13.5rem" }}
        />
        <Column
          body={(rowData: IVideoContentMinimize) => (
            <>
              <Button
                icon="pi pi-sign-out"
                className="p-button p-component p-button-rounded p-button-warning p-button-outlined p-button-icon-only"
                onClick={() => openConfirmationDialog(rowData)}
                style={{ width: "2rem", height: "2rem" }}
              />
            </>
          )}
          exportable={false}
          style={{ width: "10%" }}
        />
      </DataTable>
      <ConfirmOperationDialog
        visible={showConfirmation}
        text={
          selectedVideoContent?.name + " will be UNpublished. Pelase confirm !"
        }
        onHide={hideConfirmation}
        confirm={unPublishContentLocal}
      />
    </>
  );
};

export default VideoUNPublishDataTable;
