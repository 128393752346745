import React, { useRef, useState, useEffect } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { DataTableCrud, DataTableSearchHeader } from "../../common";
import { IAllMainboardItems, IMainboardItems } from "../../../../models/index";
import {
  mainboardItemFilters,
  initialAllMainBoardItems,
  initialMainBoardItems,
} from "../../../../constants";
import { useGetAllMainboardsItemsData } from "../../../../hooks/mainboarditems";
import {
  deleteMainBoardItem,
  getMainBoardsItems,
  updateMainBoardItem,
} from "../../../../services/index";
import { DropdownField } from "../../../FormFields/index";
import { toastError, toastSuccess } from "../../../../utils";
import Row from "react-bootstrap/Row";
import { Button } from "primereact/button";

const dragHandleContainerStyle = {
  display: "inline-block",
  padding: "5px 10px",
  marginRight: "10px",
  cursor: "move",
};

const dragHandleLineStyle = {
  width: "20px",
  height: "3px",
  backgroundColor: "#000",
  margin: "2px 0",
};

const MainBoardItemDatatable = () => {
  const [mainboardItemSingle, setMainboardItemSingle] =
    useState<IMainboardItems>(initialMainBoardItems);

  const [selectedMainboardItem, setSelectedMainboardItem] =
    useState<IMainboardItems | null>(null);
  const [mainboardItem, setMainboardItem] = useState<IAllMainboardItems>(
    initialAllMainBoardItems
  );
  const [deleteMainboardItemDialog, setDeleteMainboardItemDialog] =
    useState(false);
  const [filters, setFilters] = useState(mainboardItemFilters);
  const [mainboardItems, setMainboardItems] = useState<IAllMainboardItems[]>(
    []
  ); // Düzeltildi, dizi olmalı

  const [mainboardItemsForAdd, setMainboardItemsForAdd] = useState<
    IMainboardItems[] | undefined
  >([]);

  const [showAddMbiDailog, setShowAddMbiDailog] = useState(false);

  const toast = useRef<Toast>(null);

  const {
    allMainboardItems: fetchedMainboardItems,
    getAllMainboardItemsIsLoading,
    refetchMbiList,
  } = useGetAllMainboardsItemsData();

  const [updateTrigger, setUpdateTrigger] = useState(false);

  /*
  const { mainboardItemsToAdd: fetchedMbiForAdd, getMainboardItemsIsLoading } =
    useGetMainboardsItemsData();
*/

  useEffect(() => {
    if (fetchedMainboardItems && fetchedMainboardItems.length > 0) {
      setMainboardItems(fetchedMainboardItems);
      //console.log("MBI.fetchedMainboardItems:", fetchedMainboardItems);
    }
  }, [fetchedMainboardItems]);

  useEffect(() => {
    console.log("selected:", selectedMainboardItem);
  }, [selectedMainboardItem]);

  useEffect(() => {
    getMainBoardsItems().then((response) => {
      console.log("getMainBoardsItems.response:", response);
      if (response && response.length > 0) {
        setMainboardItemsForAdd(response);
      }
    });
  }, [updateTrigger]);

  const onDragStart = (
    event: React.DragEvent,
    mainboardItem: IAllMainboardItems
  ) => {
    event.dataTransfer.setData("text/plain", mainboardItem.id!.toString());
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = async (
    event: React.DragEvent,
    dropTargetItem: IAllMainboardItems
  ) => {
    event.preventDefault(); // Önceki koddan eksikti, önemli bir düzeltme
    const draggedItemId = event.dataTransfer.getData("text/plain");
    const draggedItem = mainboardItems.find(
      (item) => item.id!.toString() === draggedItemId
    );

    if (draggedItem && dropTargetItem) {
      const dropTargetIndex = mainboardItems.findIndex(
        (item) => item.id === dropTargetItem.id
      );
      const draggedItemIndex = mainboardItems.findIndex(
        (item) => item.id === draggedItem.id
      );
      if (dropTargetIndex !== draggedItemIndex) {
        const newMainboardItems = [...mainboardItems];
        newMainboardItems.splice(draggedItemIndex, 1);
        newMainboardItems.splice(dropTargetIndex, 0, draggedItem);
        setMainboardItems(newMainboardItems);

        // Call the update service with reordered list
        await updateMainBoardItem(newMainboardItems);
      }
    }
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const openAddMainboardItemDialog = () => {
    //setMainboardItem(initialAllMainBoardItems);
    //setIsAddItem(true);
    //setMainboardItemDialog(true);
    setShowAddMbiDailog(true);
  };

  const openDeleteMainboardItemDialog = (rowData?: IAllMainboardItems) => {
    rowData && setMainboardItem(rowData);
    //setDeleteMainboardItemDialog(true);

    deleteMainBoardItem(rowData?.id).then((res) => {
      //console.log("delete res", res);
      if (res.status === 200) {
        if (res.data.code === 200) {
          toastSuccess(toast, "MBI Removed." + rowData?.title);
          refetchMbiList();
          setUpdateTrigger(!updateTrigger);
          //setUpdateTrigger((prev) => !prev);
        } else {
          console.log("delete res.data.data", res.data.data);
          toastError(toast, "MBI Delete Failed." + res.data.message);
        }
      } else {
        toastError(toast, "MBI Delete Failed. Status:" + res.data.status);
      }
    });
    setSelectedMainboardItem(null);
  };

  const openDeleteMbiDialog = (mainboardItemSingle?: IMainboardItems) => {
    mainboardItemSingle && setMainboardItemSingle(mainboardItemSingle);
    setDeleteMainboardItemDialog(true);
  };

  const hideDeleteMbiDialog = () => {
    setDeleteMainboardItemDialog(false);
  };

  const deleteMbi = () => {
    console.log("Delete MBİ:", selectedMainboardItem);

    hideDeleteMbiDialog();
  };

  const hideAddMbiDialog = () => {
    setShowAddMbiDailog(false);
  };

  return (
    <>
      <Toast ref={toast} />

      <Row>
        <h3>Main Board Contents</h3>
      </Row>

      <DataTableCrud
        value={mainboardItems}
        selection={selectedMainboardItem}
        onSelectionChange={(e) => setSelectedMainboardItem(e.value)}
        header={
          <DataTableSearchHeader
            title="Mainboard Item List"
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        loading={getAllMainboardItemsIsLoading}
        title="mainboardItems"
      >
        <Column
          header="Order"
          body={(rowData: IAllMainboardItems) => (
            <div
              draggable
              onDragStart={(e) => onDragStart(e, rowData)}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, rowData)}
            >
              <div style={dragHandleContainerStyle}>
                <div style={dragHandleLineStyle}></div>
                <div style={dragHandleLineStyle}></div>
                <div style={dragHandleLineStyle}></div>
              </div>
            </div>
          )}
        />
        <Column
          field="name"
          header="Name"
          body={(rowData) => <>{rowData.content.name}</>}
          sortable
        />
      </DataTableCrud>
    </>
  );
};

export default MainBoardItemDatatable;
