import { IContract } from "../../models/index";
import { addContract } from "../../services/index";
import { toastError, toastSuccess } from "../../utils/index";
import { Toast } from "primereact/toast";
import { useMutation, useQueryClient } from "react-query";

export const useAddContractData = (toast: React.RefObject<Toast>) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: addContractIsLoading } = useMutation(
    async (contract: IContract) => await addContract(contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contract");
        toastSuccess(toast, "Contract Added");
      },
      onError: () => {
        toastError(toast, "Contract not Added");
      },
    }
  );
  return { mutate, addContractIsLoading };
};
