import MobileMenuContext from "../contexts/MobileMenuContext";
import { useState } from "react";

interface MobileMenuProviderProps {
 children: React.ReactNode;
}

export const MobileMenuProvider: React.FC<MobileMenuProviderProps> = ({
 children,
}) => {
 const [mobileMenuIsActive, setMobileMenuIsActive] = useState(false);
 return (
  <MobileMenuContext.Provider
   value={{ mobileMenuIsActive, setMobileMenuIsActive }}
  >
   {children}
  </MobileMenuContext.Provider>
 );
};
