import React from "react";
import { MenuProvider } from "./MenuProvider";
import { MobileMenuProvider } from "./MobileMenuProvider";
import { ThemeProvider } from "./ThemeProvider";

interface AppProviderProps {
 children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
 return (
  <>
   <ThemeProvider>
    <MenuProvider>
     <MobileMenuProvider>{children}</MobileMenuProvider>
    </MenuProvider>
   </ThemeProvider>
  </>
 );
};
