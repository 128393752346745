import { IContract } from "../../models/Entities/contract/index";
import { axiosInstance } from "../../api/axios.instance";

export const getContracts = async (): Promise<IContract[]> =>
  await axiosInstance
    .get(`/contman/contract/getall`)
    .then((res) => res.data.data);

export const addContract = async (contract: IContract) =>
  await axiosInstance.post(`/contman/contract/contract`, {
    contractP: contract,
  });

export const updateContract = async (contract: IContract) =>
  await axiosInstance.put(`/contman/contract/edit`, { contractP: contract });

export const deleteContract = async () =>
  await axiosInstance.delete(`/contman/contract/delete`);
