import { DataTable } from "primereact/datatable";
import React from "react";
import { IComponentProps, ICustomer, IProduct } from "@/models";

interface DataTableCrudProps extends IComponentProps {
  value: any;
  title: string;
  selection: any;
  loading: boolean;
  header: React.ReactNode;
  filters: any;
  onSelectionChange: (e: any) => void;
}

const DataTableCrud: React.FC<DataTableCrudProps> = ({
  children,
  title,
  ...rest
}) => {
  return (
    <DataTable
      {...rest}
      dataKey="id"
      paginator
      rows={100}
      rowsPerPageOptions={[10, 50, 100]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      responsiveLayout="scroll"
    >
      {children}
    </DataTable>
  );
};

export default DataTableCrud;
